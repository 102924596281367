import React from "react";
import Icon from "@mdi/react";
import { mdiParachute } from "@mdi/js";

const ListEmptyComponent = () => {
	return (
		<div
			className={ "item-empty position-relative text-center justify-content-center d-flex" }>
			<div className={ "" }>
				<span><Icon path={ mdiParachute } size={ "70" } color={ "#b1b5b8" }/></span>
				<h5 className={ "mt-3" }>Nothing to Display</h5>
			</div>
		</div>
	);
};

export default ListEmptyComponent;