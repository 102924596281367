import { SET_SEARCH_TEXT } from "../actions/searchActions";

const initialState = {
	searchText: "",
};

export function searchReducer(state = initialState, action) {
	switch (action.type) {
		case SET_SEARCH_TEXT:
			return {
				...state,
				searchText: action.payload,
			};
		default:
			return state;
	}
}