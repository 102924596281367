import React, {useState, useEffect} from "react";
import { useHistory } from "react-router-dom";
import logo from "../assets/svg/parachute-logo.svg";
import "../styles/loginStyles.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import * as yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { hidePopup } from "../redux/actions/popupActions";
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import {
	forgetPassword,
	changeForgetPassword
} from "../redux/actions/authActions";

const ForgetPassword = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const { popupVisible, popupText, popupError } = useSelector(
		state => state.popupReducer);

	const [passwordVisible, setPasswordVisible] = useState(false);
	const [requestSubmitted, setRequestSubmitted] = useState(false);

	const togglePasswordVisibleClick = () => setPasswordVisible(!passwordVisible);

	const validationSchema = yup.object().shape({
		email: yup.string().email().required(),
		token: requestSubmitted ? yup.string().required() : yup.string(),
		password: requestSubmitted ? yup.string().required() : yup.string()
	});

	const formik = useFormik({
		initialValues: {
			email: "",
			token: "",
			password: ""
		},
		validationSchema: validationSchema,
		validateOnMount: true,
	});

	const handleEmailChange = (e) => {
		formik.setFieldValue("email", e.target.value, true);
	};

	const handleTokenChange = (e) => {
		formik.setFieldValue("token", e.target.value, true);
	};
	
	const handlePasswordChange = (e) => {
		formik.setFieldValue("password", e.target.value, true);
	};

	useEffect(() => {
		if (popupVisible && popupError) toast.error(popupText, {
			position: "top-right",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			onClose: dispatch(hidePopup()),
		});
		if (popupVisible && !popupError) toast.success(popupText, {
			position: "top-right",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			onClose: dispatch(hidePopup()),
		});
	}, [popupVisible, popupError, popupText, dispatch]);

	const handleSubmit = () => {
		if(!requestSubmitted){
			dispatch(forgetPassword({
				email: formik.values.email,
				requestSubmitted,
				history
			}));
			setRequestSubmitted(true);
		}else{
			dispatch(changeForgetPassword({
				token: formik.values.token,
				newPassword: formik.values.password,
				history
			}));
		}
	};

	return (
		<div className={ "container" }>
			<ToastContainer/>
			<div className="leftContainer">
				<img src={ logo } alt={ "rig-log-logo" }/>
				<div className="welcomeText">
					<div className="welcome mb-2">
						Welcome!
					</div>
					<div className="subMessage">
						Forget your password?
					</div>
				</div>
			</div>
			<div className="rightContainer">
				<div className="rightContents">
					<div className="hello">
						Hello!
					</div>
					<div className="helloSubText">
						Please enter your valid email.
					</div>
					<div className="form-floating mt-82px">
						<input type="email" autoComplete={ "off" }
						       className={ `form-control border-top-0 border-start-0 border-end-0 p-0 rounded-0 inputBorder ${ !formik.errors.email &&
						       "is-valid" }` }
						       placeholder={ "yourname@domain.com" }
						       onChange={ handleEmailChange }/>
						<label className={ "ps-0" }>Email ID</label>
					</div>
					<div className="form-floating mt-57px">
							<input 
								disabled={!requestSubmitted}
							    type="text"
								className={ "form-control border-top-0 border-start-0 border-end-0 p-0 rounded-0 inputBorder " }
								placeholder={ "xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx" }
								onChange={ handleTokenChange }/>
							<label className={ "ps-0" }>Token</label>
					</div>
					<div className="form-inline">
						<div className="form-floating mt-57px">
							<input
								disabled={!requestSubmitted} 
								type={ passwordVisible ? "" : "password" }
								className={ "form-control border-top-0 border-start-0 border-end-0 p-0 rounded-0 inputBorder " }
								placeholder={ "yourname@domain.com" }
								onChange={ handlePasswordChange }/>
							<label className={ "ps-0" }>New Password</label>
						</div>
						<div className="form-floating mt-57px" style={{zindex: 1000}}>
							<span onClick={ togglePasswordVisibleClick }
								className={ "eye" }>
								{ passwordVisible && <IoMdEyeOff
									size={ "30" }
									color={ "#bfbfbf" }/> }
								{ !passwordVisible && <IoMdEye
									size={ "30" }
									color={ "#bfbfbf" }/> }
							</span>
						</div>
					</div>
					
					<div className={ "clearfix" }></div>
					<div className={ "mt-4 mt-xxl-5" }>
						<button type="button" onClick={ handleSubmit }
						        disabled={ !formik.isValid }
						        className="btn btn-danger text-uppercase w-100 py-3">
									Submit
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ForgetPassword;