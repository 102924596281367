import React from 'react';
import Switch from "react-switch";
import { GoPencil } from 'react-icons/go';
import logo from "../assets/svg/parachute-logo.svg";
import { convertToReadableDate } from '../helpers/util';
import "./styles/companyCardStyles.scss";

const CompanyCard = ({
    company,
    onChange,
    onEdit
}) => {
    const { identifier, name, imageUrl, phone, audit: {lastModifiedAt}, isEnabled, admin : {fullName} } = company;

    const handleOnChangeEnable = () => {
        onChange(identifier, isEnabled)
    }

    return (
        <div className="company-card card">
            <div className="row card-body">
                <div className="col-md-2 card-cell-check-box-wrapper">
                    <input className="checkbox" type="checkbox" />
                </div>
                <div className="col-md-2 card-cell-image-box-wrapper">
                    <img className="image" src={imageUrl ? imageUrl : logo} alt="Err" />
                    <div className="right-text-area">
                        <p className="name">{name}</p>
                        <p className="phone">{phone}</p>
                    </div>
                </div>
                <div className="col-md-2 card-cell-admin-fullName-wrapper">
                    <p className="admin-fullName">{fullName}</p>
                </div>
                <div className="col-md-2 card-cell-update-date-wrapper">
                    <p className="update-date">{lastModifiedAt ? convertToReadableDate(lastModifiedAt) : 'N/A'}</p>
                </div>
                <div className="col-md-2 card-cell-enable-button-wrapper">
                    <Switch
                        handleDiameter={20}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        checked={isEnabled}
                        onChange={handleOnChangeEnable}
                        onColor="#F44059"
                    />
                </div>
                <div className="col-md-2 card-cell-enable-button-wrapper">
                    <button type="button" className="btn btn-danger" onClick={() => onEdit(company)}>
                        <span className={ "me-3" }><GoPencil/></span>
                    </button>
                    {/* <button type="button" className="btn btn-danger">
                        <span className={ "me-3" }><GoTrashcan/></span>
                    </button> */}
                </div>
            </div>
        </div>
    )
}

export default CompanyCard;