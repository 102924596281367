import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import { Store } from "./redux/store";
import Login from "./pages/Login";
import ForgetPassword from "./pages/ForgetPassword";
import RigRack from "./pages/RigRack";
import Main from "./pages/Main";
import ParachuteType from "./pages/ParachuteType";
import SpareParts from "./pages/SpareParts";
import Bin from "./pages/Bin";
import Users from "./pages/Users";
import UserHistory from "./pages/UserHistory";
import ParachuteDetail from "./pages/ParachuteDetail";
import CompositeParachuteDetail from "./pages/CompositeComponentDetail";
import { SparePartsDetailContainer, CompositeSparePartsDetailContainer } from "./pages/SparePartsDetailContainer";
import BinDetail from "./pages/BinDetail";
import Splash from "./pages/Splash";
import Companies from './pages/Companies';
import Settings from "./pages/Settings";
import Reports from "./pages/Reports";
import ReportsNumberOfPacks from "./pages/ReportsNumberOfPacks";
import ReportsReserveParachuteExpiryDates from "./pages/ReportsReserveParachuteExpiryDates";

ReactDOM.render(
	<React.StrictMode>
		<Provider store={ Store }>
			<Router>
				<Switch>
					<Route exact path={ "/" }>
						<Splash/>
					</Route>
					<Route exact path={ "/login" }>
						<Login/>
					</Route>
					<Route exact path={ "/forgetPasssword" }>
						<ForgetPassword/>
					</Route>
					<Main>
						<Route path={ "/companies" }>
							<Companies/>
						</Route>
						<Route path={ "/rigRack" }>
							<RigRack/>
						</Route>
						<Route path={ "/paraType" }>
							<ParachuteType/>
						</Route>
						<Route path={ "/paraDetail" }>
							<ParachuteDetail/>
						</Route>
						<Route exact path={ "/Composite" }>
							<CompositeParachuteDetail/>
						</Route>
						<Route path={ "/spareParts" }>
							<SpareParts/>
						</Route>
						<Route path={ "/bin" }>
							<Bin/>
						</Route>
						<Route path={ "/users" }>
							<Users/>
						</Route>
						<Route path={ "/sparePartDetail" }>
							<SparePartsDetailContainer/>
						</Route>
						<Route path={ "/compositeSparePartDetail" }>
							<CompositeSparePartsDetailContainer/>
						</Route>
						<Route path={ "/binDetail" }>
							<BinDetail/>
						</Route>
						<Route path={"/user-history"}>
							<UserHistory/>
						</Route>
						<Route path={"/settings"}>
							<Settings/>
						</Route>
						<Route path={"/reports"} exact>
							<Reports/>
						</Route>
						<Route path={"/reports/number-of-packs"} exact>
							<ReportsNumberOfPacks/>
						</Route>
						<Route path={"/reports/reserve-parachute-expiry-dates"} exact>
							<ReportsReserveParachuteExpiryDates/>
						</Route>
					</Main>
				</Switch>
			</Router>
		</Provider>
	</React.StrictMode>
	,
	document.getElementById("root"),
);