import {
	SET_BIN_TYPES,
	SET_ACTIVE,
	SET_BIN_COMPONENTS,
	SET_COMPONENT_TYPE,
	SET_CURRENT_PAGE,
	SET_TOTAL_PAGES,
} from "../actions/binActions";

const initialState = {
	active: false,
	binTypes: null,
	binComponents: [],
	componentType: "",
	totalPages: 1,
	currentPage: 0,
};

export default function binReducer(state = initialState, action) {
	switch (action.type) {
		case SET_BIN_TYPES:
			return {
				...state,
				binTypes: action.payload,
			};
		case SET_ACTIVE:
			return {
				...state,
				active: action.payload,
			};
		case SET_BIN_COMPONENTS:
			return {
				...state,
				binComponents: action.payload,
			};
		case SET_COMPONENT_TYPE:
			return {
				...state,
				componentType: action.payload,
			};
		case SET_TOTAL_PAGES:
			return {
				...state,
				totalPages: action.payload,
			};
		case SET_CURRENT_PAGE:
			return {
				...state,
				currentPage: action.payload,
			};
		default:
			return state;
	}
}