export const SET_TEXT = "SET_TEXT";
export const SET_VISIBILITY = "SET_VISIBILITY";
export const SET_POPUP_ERROR = "SET_POPUP_ERROR";

const setPopupText = (text) => (dispatch) => {
	dispatch({
		type: SET_TEXT,
		payload: text,
	});
};

const showPopup = () => (dispatch) => {
	dispatch({
		type: SET_VISIBILITY,
		payload: true,
	});
};

const popupIsError = () => (dispatch) => {
	dispatch({
		type: SET_POPUP_ERROR,
		payload: true,
	});
};

const popupIsNotError = () => (dispatch) => {
	dispatch({
		type: SET_POPUP_ERROR,
		payload: false,
	});
};

const hidePopup = () => (dispatch) => {
	dispatch({
		type: SET_VISIBILITY,
		payload: false,
	});
};

export
{
	setPopupText, showPopup, hidePopup, popupIsError, popupIsNotError,
};