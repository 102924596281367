import React, { useEffect, useState } from "react";
// import logo from "../assets/svg/parachute-logo.svg";
// import { FiSearch } from "react-icons/fi";
import { map } from "lodash/collection";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { loadAnalytics } from "../redux/actions/analyticsActions";
import ListEmptyComponent from "../components/ListEmptyComponent";
import LoadingComponent from "../components/LoadingComponent";
import moment from "moment";
import { appSettings } from "../config";

export default function UserHistory() {
	const dispatch = useDispatch();
	const location = useLocation();
	const [pagination, setPagination] = useState([]);
	const [display, setDisplay] = useState([]);

	const { userID } = location.state;

	const { analytics, active, totalPages, currentPage } = useSelector(
		state => state.analyticsReducer);

	useEffect(() => {
		if (userID) {
			dispatch(loadAnalytics({ userId: userID }));
		}
	}, [dispatch, userID]);

	useEffect(() => {
		setDisplay(analytics);
	}, [analytics]);

	useEffect(() => {
		let arrInner = [];
		if (totalPages > 3) {
			if (currentPage + 1 === totalPages) {
				arrInner.push(currentPage + 1);
			} else {
				let count = 3;
				if ((totalPages - currentPage) <= 3) {
					count = totalPages - currentPage;
				}
				for (let i = 0; i < count; i++) {
					arrInner.push(currentPage + i + 1);
				}
			}
		} else {
			for (let i = 0; i < totalPages; i++) {
				arrInner.push(i + 1);
			}
		}
		setPagination(arrInner);
	}, [totalPages, currentPage]);

	const renderItem = (item, key) => {
		const momItem = moment(new Date(item.createdAt));
		const dom = momItem.format(appSettings.momentFormat);
		return (
			<div key={ key } className={ "row mx-0 spare-parts-list" }>
				<div className={ "category" }>
					<h4>{ item.category }</h4>
				</div>
				<div className={ "operation" }>
					<h4>{ item.operation }</h4>
				</div>
				<div className={ "description" }>
					<h4>{ item.description }</h4>
				</div>
				<div className={ "created-at" }>
					<h4>{ dom }</h4>
				</div>
			</div>
		);
	};

	function handleNext() {
		if (currentPage + 1 !== totalPages) {
			dispatch(loadAnalytics({ userId: userID, page: currentPage + 1 }));
		}
	}

	function handlePrev() {
		if (currentPage > 0) {
			dispatch(loadAnalytics({ userId: userID, page: currentPage - 1 }));
		}
	}

	// function handleSearchTextChange(e) {
	// 	const searchKey = e.target.value;
	//
	// 	const searchResult = analytics.filter((item) => {
	// 		if (item.description.toLowerCase().includes(searchKey.toLowerCase()) ||
	// 			item.category.toLowerCase().includes(searchKey.toLowerCase()) ||
	// 			item.operation.toLowerCase().includes(searchKey.toLowerCase()))
	// 			return true;
	// 		else return false;
	// 	});
	// 	if (searchResult) setDisplay(searchResult);
	// 	else setDisplay([]);
	// }

	if (!active && analytics.length <= 0) return <ListEmptyComponent/>;
	if (active) return <LoadingComponent/>;
	return (
		<div className={ "spare-parts-serviceable-page" }>
			<div className={ "d-none" }>
				{ "User level analytics" }
			</div>
			<div>
				<div className={ "spare-parts-table" }>
					<div className={ "table-header-top" }>
						<div className={ "float-start" }>
							<h2>{ `User Level Analytics` }</h2>
						</div>
						<div className="float-end search-main position-relative">
							{/*<input type="search" className="form-control" placeholder="Search..."*/}
							{/*       aria-label="Search..." onChange={ handleSearchTextChange }/>*/}
							{/*<span><FiSearch color={ "#BDC9D3" }
							                size={ "15" }/></span>*/ }
						</div>
						<div className={ "float-end ms-4 ps-3" }><h4>Items<span
							className={ "ms-1" }>{ analytics.length }</span>
						</h4></div>
						<div className={ "float-end" }><h4>Page <span
							className={ "ms-1" }>{ currentPage + 1 }</span></h4>
						</div>
					</div>
					<div className={ "table-header row mx-0" }>
						<div className={ "category" }><h4>Category</h4></div>
						<div className={ "operation" }><h4>Operation</h4></div>
						<div className={ "description" }><h4>Description</h4></div>
						<div className={ "created-at" }><h4>Created At</h4></div>
					</div>
					<div className={ "table-body" }>
						{ map(display, renderItem) }
					</div>
					<div className={ "table-pagination" }>
						<div className={ "float-start" } onClick={ handlePrev }>
							{ currentPage + 1 > 1 && <>
								<span>
									<BsArrowLeft color={ "#687C97" } size={ 16 }/>
								</span>
								<label>Prev</label>
							</> }
						</div>
						<div className={ "float-end" } onClick={ handleNext }>
							{ totalPages > 1 && (currentPage + 1 !== totalPages) && <>
								<label>Next</label>
								<span>
									<BsArrowRight color={ "#687C97" } size={ 16 }/>
								</span>
							</> }
						</div>
						<div className={ "clearfix" }/>
						<div className={ "d-flex justify-content-center" }>
							{
								map(pagination, (item, index) => (
									<span key={ index } className={ item === currentPage + 1 ?
										"active" :
										"" }>{ item }</span>
								))
							}
							{
								totalPages > 3 && !(totalPages === currentPage + 1) &&
								<span>{ "..." }</span>
							}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
