import React, { useEffect, useState } from "react";
import logo from "../assets/svg/parachute-logo.svg";
import "../styles/loginStyles.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import * as yup from "yup";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import {
	checkLogin,
	login,
	setAuthMessage,
} from "../redux/actions/authActions";
import { ToastContainer, toast } from "react-toastify";
import { colors } from "../config/colors";

const Login = () => {
	const dispatch = useDispatch();
	const { authenticating, authenticated, failed, message, isSuperUserLogged } = useSelector(
		state => state.authReducer);
	const [passwordVisible, setPasswordVisible] = useState(false);
	const [rememberChecked, setRememberChecked] = useState(true);

	const history = useHistory();
	const validationSchema = yup.object().shape({
		email: yup.string().email().required(),
		password: yup.string().required(),
	});

	useEffect(() => {
		dispatch(checkLogin());
	}, [dispatch]);

	useEffect(() => {
		failed && message && toast.error(message, {
			position: "top-right",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			onClose: () => {
				dispatch(setAuthMessage(""));
			},
		});
	}, [failed, message, dispatch]);

	const formik = useFormik({
		initialValues: {
			email: "",
			password: "",
		},
		validationSchema: validationSchema,
		validateOnMount: true,
	});

	const handleEmailChange = (e) => {
		formik.setFieldValue("email", e.target.value, true);
	};

	const handlePasswordChange = (e) => {
		formik.setFieldValue("password", e.target.value, true);
	};

	const handleSubmit = () => {
		dispatch(login({
			email: formik.values.email,
			password: formik.values.password,
			rememberChecked: rememberChecked,
		}));
	};

	const handleRememberChecked = (e) => {
		setRememberChecked(!rememberChecked);
	};

	useEffect(() => {
		if (authenticated) {
			history.push({
				pathname: isSuperUserLogged ? "/companies" : "/rigRack",
				state: {
					pageTitle: isSuperUserLogged ? "Companies" : "Rig Rack",
					activeTab: 1,
					infoPaneEnabled: true,
					searchEnabled: false,
				},
			});
		}
	}, [authenticated, isSuperUserLogged, history]);

	const handlePasswordVisibleClick = () => {
		setPasswordVisible(!passwordVisible);
	};

	const handleOnClickforgetPassword = () =>{
		history.push('./forgetPasssword')
	}

	return (
		<div className={ "container" }>
			<ToastContainer toastStyle={ {
				backgroundColor: colors.primary,
				color: colors.white,
			} }/>
			<div className="leftContainer">
				<img src={ logo } alt={ "rig-log-logo" }/>
				<div className="welcomeText">
					<div className="welcome mb-2">
						Welcome!
					</div>
					<div className="subMessage">
						To keep connected with us please login with your
						personal
						info
					</div>
				</div>
			</div>
			<div className="rightContainer">
				<div className="rightContents">
					<div className="hello">
						Hello!
					</div>
					<div className="helloSubText">
						Sign in to continue!
					</div>
					<div className="form-floating mt-82px">
						<input type="email" autoComplete={ "off" }
						       className={ `form-control border-top-0 border-start-0 border-end-0 p-0 rounded-0 inputBorder ${ !formik.errors.email &&
						       "is-valid" }` }
						       placeholder={ "yourname@domain.com" }
						       onChange={ handleEmailChange }/>
						<label className={ "ps-0" }>Email ID</label>
					</div>
					<div className="form-floating mt-57px">
						<input type={ passwordVisible ? "" : "password" }
						       className={ "form-control border-top-0 border-start-0 border-end-0 p-0 rounded-0 inputBorder " }
						       placeholder={ "yourname@domain.com" }
						       onChange={ handlePasswordChange }/>
						<label className={ "ps-0" }>Password</label>
					</div>
					<div className={ "mt-5" }>
						<div className={ "float-start w-50" }>
							<div className="form-check ps-0">
								<div className="form-check">
									<input className="form-check-input"
									       type="checkbox" value=""
									       id="flexCheckDefault"
									       onChange={ handleRememberChecked }
									       checked={ rememberChecked }/>
									<span onClick={ handlePasswordVisibleClick }
									      className={ "eye" }>
										{ passwordVisible && <IoMdEyeOff
											size={ "30" }
											color={ "#bfbfbf" }/> }
										{ !passwordVisible && <IoMdEye
											size={ "30" }
											color={ "#bfbfbf" }/> }
									</span>
									<label className="form-check-label"
									       htmlFor="flexCheckDefault">
										Remember me
									</label>
								</div>
							</div>
						</div>
						<div className={ "float-start w-50 text-end" }>
						<span className="forgot " onClick={handleOnClickforgetPassword}>
							Forgot Password?
						</span>
						</div>
					</div>
					<div className={ "clearfix" }></div>
					<div className={ "mt-4 mt-xxl-5" }>
						<button type="button" onClick={ handleSubmit }
						        disabled={ authenticating || !formik.isValid }
						        className="btn btn-danger text-uppercase w-100 py-3">Login
							to Continue
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Login;