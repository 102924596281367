import React from "react";
import "./styles.scss"
import {AiOutlineArrowDown, AiOutlineArrowUp} from 'react-icons/ai'

const DetailRow = ({name, expanded, onClick}) =>{

    return (
        <div className="detailRow-wrapper" onClick={onClick}>
            <div className="detailRow-name">
                {name}
            </div>
        </div>
    )
}

export default DetailRow;