const messages = {
	passwordSuccess: "Password Updated",
	passwordFailed: "Password change failed",
	scanSuccess: "Parachute Scanned",
	EmptyScanHistory: "History is Empty",
	EmptyList: "Nothing to Display",
	QRDiscarded: "Scan discarded",
	scanSubmitConfirm: "Are you sure you want to record this parachute?",
	scanSubmitConfirmTitle: "Submit Scan?",
	manualEntryTitle: "Manual Entry",
	manualEntryDescription: "Enter the code here",
	movingToServicedConfirm:
		"To move into the serviced section please select confirm",
	areYouSure: "Are you sure?",
	moveConfirmDescription: "To proceed, please select an option below",
	moveConfirmTitle: "Move",
	inspectionConfirmTitle: "Inspection Completed?",
	inspectionConfirmDescription:
		"I confirm this component is in serviceable condition. \n" +
		"Jumps before next inspection will reset to zero.\n",
	moveToSpareConfirmTitle: "Move to spare parts",
	moveToSpareConfirmDescription:
		"To proceed with a replacement, please select accordingly and confirm",
	parachuteAdded: "Parachute Added",
	componentAddedToParachute: "Component Added",
	sentToInspection: "Component successfully inspected",
	movedToBin: "Component moved to bin",
	movedToServiceable: "Component moved to serviceable",
	movedToUnserviceable: "Component moved to unserviceable",
	passwordForgotSuccess: "Forgot Password Request Success",
};

export { messages };
