import {
	SET_TEXT,
	SET_VISIBILITY,
	SET_POPUP_ERROR,
} from "../actions/popupActions";

const initialState = {
	popupVisible: false,
	popupText: "This is a popup",
	popupError: false,
};

function popupReducer(state = initialState, action) {
	switch (action.type) {
		case SET_TEXT:
			return {
				...state,
				popupText: action.payload,
			};
		case SET_VISIBILITY:
			return {
				...state,
				popupVisible: action.payload,
			};
		case SET_POPUP_ERROR:
			return {
				...state,
				popupError: action.payload,
			};
		default:
			return state;
	}
}

export default popupReducer;