import { SET_STATUS } from "../actions/statusActions";

const initialState = {
	statusText: "",
};

function statusReducer(state = initialState, action) {
	switch (action.type) {
		case SET_STATUS:
			return {
				...state,
				statusText: action.payload,
			};
		default:
			return state;
	}
}

export default statusReducer;