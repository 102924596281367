import { AiFillExclamationCircle } from "react-icons/ai";
import { BiBarcodeReader } from "react-icons/bi";
import { VscCalendar } from "react-icons/vsc";
import { useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Button, FormGroup, Input, Modal } from "reactstrap";
import { map } from "lodash/collection";
import { capitalize } from "lodash";
import { appSettings } from "../config";
import { messages } from "../config/messages";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import * as componentActions from "../redux/actions/componentActions";
import Icon from "@mdi/react";
import { mdiAlertCircleOutline, mdiDeleteCircle, mdiInformationOutline, mdiParachute } from "@mdi/js";
import QRCode from "qrcode.react";

const CompositeParachuteDetail = () => {
    const dispatch = useDispatch();
    const location = useLocation();

    const {
        selectedItem
    } = location.state;

    const [moveModal, setMoveModal] = useState(false);
    const [isCompositeComponentSelected, setCompositeComponentSelected] = useState(false);
    const [performInspectionModal, setPerformInspectionModal] = useState(false);
    const [moveToSparePartsModal, setMoveToSparePartsModal] = useState(false);
    const [selectComponentModal, setSelectComponentModal] = useState(false);
    const [radioServiceableSelected, setRadioServiceableSelected] = useState(true);
    const [selectedComponent, setSelectedComponent] = useState(null);
    const [selectedComponentDOM, setSelectedComponentDOM] = useState("");
    const [serialModal, setSerialModal] = useState(false);
    const [radioBinSelected, setRadioBinSelected] = useState(false);
    const [selectedDropdownItem, setSelectedDropdownItem] = useState("default");
    const [inspectionSuffix, setInspectionSuffix] = useState("days");
    const [usageSuffix, setUsageSuffix] = useState("days");
    const [eolSuffix, setEolSuffix] = useState("days");
    const [values, setValues] = useState({
        inspectionTop: null,
        inspectionBottom: null,
        eol: null,
        usageTop: null,
        usageBottom: null,
        defaultInspectionCount: null,
    });
    const [missingItemsList, setMissingItemsList] = useState({})
    const [itemsList, setItemsList] = useState({})

    const toggleMove = () => { setMoveModal(!moveModal); };
    const togglePerformInspectionModal = () => { setPerformInspectionModal(!performInspectionModal); };
    const toggleMoveToSparePartsModal = () => { setMoveToSparePartsModal(!moveToSparePartsModal); };
    const toggleSelectComponentModal = () => { setSelectComponentModal(!selectComponentModal); };
    const toggleSerialModal = () => { setSerialModal(!serialModal); };

    const {
        componentsAvailable,
        selectedComponentType,
        item,
    } = useSelector(state => state.componentReducer);

    useEffect(() => {
        if (item) dispatch(componentActions.setSelectedParachuteID(
            { selectedParachuteID: item.id }));
    }, [item, dispatch]);

    useEffect(() => {
        if (item?.missingComponents) {
            const missingItems = [];
            item.missingComponents.forEach(mi => {
                if (mi.name === selectedItem.componentType) {
                    mi.missingSubComponents.forEach(msc => {
                        missingItems.push({
                            ...msc,
                            noComponent: false,
                            subComponent: true,
                            label: mi.name,
                            isVisible: true,
                        });
                    })
                }
            })
            setMissingItemsList(missingItems);
        }
        console.log(item?.components)
        if (item?.components) {
            const tItemsList = [];
            item.components.forEach(mi => {
                if (mi.componentType === selectedItem.componentType) {
                    mi.subComponents.forEach(msc => {
                        tItemsList.push({
                            ...msc,
                            noComponent: false,
                            label: mi.name,
                            isVisible: true,
                        });
                    })
                }
            })
            setItemsList(tItemsList);
        }
    }, [item]);

    const GaugeCard = ({
        itemNumber,
        title,
        leftBase,
        leftValue,
        rightBase,
        rightValue,
        moveToBinAlert,
        error,
        moveToBinLevel,
        moveToInspectionLevel,
        components,
        id,
        hideMove,
        hideInspection,
    }) => {

        const [leftCount, setLeftCount] = useState(0);
        const [rightCount, setRightCount] = useState(0);
        useEffect(() => {
            switch (moveToBinLevel) {
                case "Replace": {
                    setLeftCount(6);
                    break;
                }
                case "Intermediary": {
                    setLeftCount(4);
                    break;
                }
                case "Safe": {
                    setLeftCount(2);
                    break;
                }
                default: {
                    setLeftCount(0);
                    break;
                }
            }

            switch (moveToInspectionLevel) {
                case "Replace": {
                    setRightCount(6);
                    break;
                }
                case "Intermediary": {
                    setRightCount(4);
                    break;
                }
                case "Safe": {
                    setRightCount(2);
                    break;
                }
                default: {
                    setRightCount(0);
                    break;
                }
            }
        }, [moveToBinLevel, moveToInspectionLevel]);

        let gaugeCountLeft = Array(leftCount).fill({ value: leftCount });
        let gaugeCountRight = Array(rightCount).fill({ value: rightCount });

        const setDisplayText = (moveToBinLevel) => {
            switch (moveToBinLevel) {
                case "Replace": {
                    return "Unserviceable";
                }
                case "Intermediary": {
                    return "Caution";
                }
                default: {
                    return "Serviceable";
                }
            }

        };

        const renderComponentBinLevelContent = (moveToBinLevel) => {
            if (moveToBinLevel === "Replace") {
                return (
                    <span onClick={toggleMoveToSparePartsModal}
                        className={"clickable"}>{setDisplayText(
                            moveToBinLevel)}</span>
                );
            } else if (moveToBinLevel === "Intermediary") {
                return (
                    <span className={"binIntermediary"}>{setDisplayText(
                        moveToBinLevel)}</span>
                );
            }
            return (
                <span className={"binSafe"}>{setDisplayText(
                    moveToBinLevel)}</span>
            );
        };

        if (components.subComponent && !components.isVisible) {
            return <></>
        }

        return (
            <div className={`accordion-item ${components.subComponent ? 'left-20' : ''}`}>
                <div className={`accordion-header ${id === selectedComponent?.id ?
                    "active" :
                    ""} ${components.length > 0 &&
                    "collapsed"} accordion-button`}
                    id={`panelsStayOpen${itemNumber}`}
                    data-bs-toggle="collapse"
                    data-bs-target={`#panelsStayOpen${itemNumber}`}
                    aria-expanded="true"
                    aria-controls={`panelsStayOpen${itemNumber}`}
                    onClick={() => {
                        handleCardClick(components);
                    }}>

                    <span
                        className={"exclamation"}>
                        {error && <AiFillExclamationCircle
                            color={"#FF1637"}
                            size={"28"} />}
                        {moveToBinAlert && <Icon path={mdiDeleteCircle}
                            color={"#FF1637"}
                            size={"30"} />}
                    </span>
                    <div className={""}>
                        {!(leftValue === undefined && leftBase === undefined) &&
                            <div className={"cell-card"}>
                                <div className={`cell ${moveToBinLevel.toLowerCase()}`}>
                                    {map(gaugeCountLeft,
                                        (value, index) => (<span key={index} />))}
                                </div>
                                <label>{leftValue}/{leftBase}</label>
                            </div>}
                        {!(rightValue === undefined && rightBase === undefined) &&
                            <div className={"cell-card"}>
                                <div className={`cell ${moveToInspectionLevel.toLowerCase()}`}>
                                    {map(gaugeCountRight,
                                        (value, index) => (<span key={index} />))}
                                </div>
                                <label>{rightValue}/{rightBase}</label>
                            </div>}
                    </div>
                    <div className={"item-text"}>
                        <h4>{title}</h4>
                        <h6>
                            {!hideMove && renderComponentBinLevelContent(moveToBinLevel)}
                            {!hideMove && <>&nbsp;&nbsp;&nbsp;&nbsp;</>}
                            {!hideInspection && <span onClick={togglePerformInspectionModal}
                                className={"inspection"}>Inspection</span>}
                        </h6>
                    </div>
                </div>
                {components.length > 0 &&
                    <div id={`panelsStayOpen${itemNumber}`}
                        className="accordion-collapse collapse show"
                        aria-labelledby={`panelsStayOpen${itemNumber}`}>
                        <div className={"accordion-body ps-2-2 pe-0 mt-3"}>{
                            map(missingItemsList, (item, index) => {
                                return renderComponentCard(item, index)
                            })
                        }
                            {map(components, (item, index) => {
                                let leftCountInner = 2;
                                let rightCountInner = 2;

                                const hideMoveInner = () => {
                                    const totalNumberOfDays = item.requiredNumberOfSubComponents > 0 ?
                                        item.subComponents[0].alertInfo.totalNumberOfDays >
                                            0 ?
                                            item.subComponents[0].alertInfo.totalNumberOfDays :
                                            undefined :
                                        item.alertInfo.totalNumberOfDays > 0 ?
                                            item.alertInfo.totalNumberOfDays :
                                            undefined;
                                    const totalNumberOfJumps = item.requiredNumberOfSubComponents > 0 ?
                                        item.subComponents[0].alertInfo.totalNumberOfJumps >
                                            0 ?
                                            item.subComponents[0].alertInfo.totalNumberOfJumps :
                                            undefined :
                                        item.alertInfo.totalNumberOfJumps > 0 ?
                                            item.alertInfo.totalNumberOfJumps :
                                            undefined;

                                    if (totalNumberOfDays || totalNumberOfJumps) return false;
                                    else return true;
                                };

                                const hideInspectionInner = () => {
                                    const jumpsBetweenServices = item.requiredNumberOfSubComponents > 0 ?
                                        item.subComponents[0].alertInfo.jumpsBetweenServices >
                                            0 ?
                                            item.subComponents[0].alertInfo.jumpsBetweenServices :
                                            undefined :
                                        item.alertInfo.jumpsBetweenServices > 0 ?
                                            item.alertInfo.jumpsBetweenServices :
                                            undefined;
                                    const daysBetweenServices = item.requiredNumberOfSubComponents > 0 ?
                                        item.subComponents[0].alertInfo.daysBetweenServices >
                                            0 ?
                                            item.subComponents[0].alertInfo.daysBetweenServices :
                                            undefined :
                                        item.alertInfo.daysBetweenServices > 0 ?
                                            item.alertInfo.daysBetweenServices :
                                            undefined;

                                    if (jumpsBetweenServices || daysBetweenServices) return false;
                                    else return true;
                                };

                                if (item.alertInfo?.moveToBinLevel !== null)
                                    switch (item.alertInfo?.moveToBinLevel.toLowerCase()) {
                                        case "replace": {
                                            leftCountInner = 6;
                                            break;
                                        }
                                        case "intermediary": {
                                            leftCountInner = 4;
                                            break;
                                        }
                                        case "safe": {
                                            leftCountInner = 2;
                                            break;
                                        }
                                        default: {
                                            leftCountInner = 2;
                                            break;
                                        }
                                    }

                                if (item.alertInfo?.moveToInspectionLevel !== null)
                                    switch (item.alertInfo?.moveToInspectionLevel.toLowerCase()) {
                                        case "replace": {
                                            rightCountInner = 6;
                                            break;
                                        }
                                        case "intermediary": {
                                            rightCountInner = 4;
                                            break;
                                        }
                                        case "safe": {
                                            rightCountInner = 2;
                                            break;
                                        }
                                        default: {
                                            rightCountInner = 2;
                                            break;
                                        }
                                    }

                                let gaugeCountLeftInner =
                                    Array(leftCountInner).fill("");
                                let gaugeCountRightInner =
                                    Array(rightCountInner).fill("");

                                const resolveMoveTobin = (item) => {
                                    return item.moveToBinLevel === "REPLACE";
                                };

                                const resolveError = (item) => {
                                    return item.moveToInspectionLevel === "REPLACE";
                                };

                                const resolveLeftValueInner = (item) => {
                                    if (item.totalNumberOfDays !== null) {
                                        return item.totalUtilizedDays;
                                    } else if (item.totalNumberOfJumps !== null) {
                                        return item.totalUtilizedJumps;
                                    } else {
                                        return undefined;
                                    }
                                };

                                const resolveLeftBaseInner = (item) => {
                                    if (item.totalNumberOfDays !== null) {
                                        return item.totalNumberOfDays;
                                    } else if (item.totalNumberOfJumps !== null) {
                                        return item.totalNumberOfJumps;
                                    } else {
                                        return undefined;
                                    }
                                };

                                const resolveRightValueInner = (item) => {
                                    if (item.daysBetweenServices !== null) {
                                        return item.daysAfterLastService;
                                    } else if (item.jumpsBetweenServices !== null) {
                                        return item.jumpsAfterLastService;
                                    } else {
                                        return undefined;
                                    }
                                };

                                const resolveRightBaseInner = (item) => {
                                    if (item.daysBetweenServices !== null) {
                                        return item.daysBetweenServices;
                                    } else if (item.jumpsBetweenServices !== null) {
                                        return item.jumpsBetweenServices;
                                    } else {
                                        return undefined;
                                    }
                                };

                                return (
                                    <div key={index}>
                                        {index !== 0 && <div
                                            className={`accordion-item ${item.alertInfo.moveToBinLevel ?
                                                item.alertInfo.moveToBinLevel.toLowerCase() :
                                                ""} `}
                                            onClick={() => handleCardClick(item)}>
                                            <div
                                                className={`accordion-header accordion-header-sub w-auto accordion-button ${item.id ===
                                                    selectedComponent?.id ? "active" : ""}`}>
                                                <div className={""}>
                                                    <span
                                                        className={"exclamation"}>
                                                        {resolveError(item.alertInfo) &&
                                                            <AiFillExclamationCircle
                                                                color={"#FF1637"}
                                                                size={"28"} />}
                                                        {resolveMoveTobin(item.alertInfo) &&
                                                            <Icon path={mdiDeleteCircle}
                                                                color={"#FF1637"}
                                                                size={"30"} />}
                                                    </span>
                                                    {!(resolveLeftValueInner(
                                                        item.alertInfo) === undefined &&
                                                        resolveLeftBaseInner(
                                                            item.alertInfo) === undefined) &&
                                                        <div className={`cell-card `}>
                                                            <div
                                                                className={`cell ${item.alertInfo?.moveToBinLevel ?
                                                                    item.alertInfo?.moveToBinLevel.toLowerCase() :
                                                                    ""}`}>
                                                                {map(
                                                                    gaugeCountLeftInner,
                                                                    (value, index) => (
                                                                        <span
                                                                            key={index} />))}
                                                            </div>
                                                            <label>{resolveLeftValueInner(
                                                                item.alertInfo)}/{resolveLeftBaseInner(
                                                                    item.alertInfo)}</label>
                                                        </div>}
                                                    {!(resolveRightValueInner(
                                                        item.alertInfo) === undefined &&
                                                        resolveRightBaseInner(
                                                            item.alertInfo) === undefined) && <div
                                                                className={`cell-card `}>
                                                            <div
                                                                className={`cell ${item.alertInfo?.moveToInspectionLevel.toLowerCase()}`}>
                                                                {map(
                                                                    gaugeCountRightInner,
                                                                    (value, index) => (
                                                                        <span
                                                                            key={index} />))}
                                                            </div>
                                                            <label>{resolveRightValueInner(
                                                                item.alertInfo)}/{resolveRightBaseInner(
                                                                    item.alertInfo)}</label>
                                                        </div>}
                                                </div>
                                                <div className={"item-text"}>
                                                    <h4>{item.displayName}</h4>
                                                    <h6>
                                                        {!hideMoveInner() &&
                                                            renderComponentBinLevelContent(
                                                                item.alertInfo?.moveToBinLevel)}
                                                        {!hideMoveInner() && <>&nbsp;&nbsp;&nbsp;&nbsp;</>}
                                                        {!hideInspectionInner() &&
                                                            <span onClick={togglePerformInspectionModal}
                                                                className={"inspection"}>Inspection</span>}
                                                    </h6>
                                                </div>
                                            </div>
                                        </div>}
                                    </div>
                                );
                            })
                            }</div>
                    </div>}
            </div>
        );
    };

    const handleCardClick = (item) => {
        setRadioServiceableSelected(true);
        setSelectedDropdownItem("default");
        setRadioBinSelected(false);
        setSelectedComponent(item);
        const momItem = moment(new Date(item.dateOfManufacture));
        const dom = momItem.format(appSettings.momentDateOnlyFormat);
        setSelectedComponentDOM(dom);
        let innerCompType = item.componentType;
        if (item?.componentType.substr(item.componentType.length - 4, 4) ===
            "_SUB") {
            innerCompType = item.componentType.substr(0, item.componentType.length - 4);
        }
        setCompositeComponentSelected(false)
        dispatch(componentActions.loadAvailableComponents({ componentType: innerCompType }));
    };

    const handleMissingItemClick = (item) => {
        dispatch(
            componentActions.setSelectedComponent({ selectedComponent: item.name }));
        dispatch(
            componentActions.loadAvailableComponents({ componentType: item.name }));
    };

    const handleSelectedPendingComponent = (e) => {
        if (e.target.value === "default") {
            dispatch(componentActions.setSelectedComponent(
                { selectedComponent: null }));
        } else {
            dispatch(componentActions.setSelectedComponent(
                { selectedComponent: componentsAvailable.find(c => c.id == e.target.value) }));
        }
        console.log(componentsAvailable.find(c => c.id == e.target.value))
    };

    const handleSubmit = () => {
        toggleSelectComponentModal();
        dispatch(componentActions.subscribeComponent());
    };

    function handleInspection() {
        togglePerformInspectionModal();
        dispatch(componentActions.performInspectionNormal({ componentID: selectedComponent.id, inspectionInterval: values.defaultInspectionCount }));
    }

    const handleMoveConfirm = () => {
        if (radioBinSelected) {
            if (selectedDropdownItem === "default") {
                dispatch(componentActions.moveToBinNormalWithoutReplacement(
                    { componentID: isCompositeComponentSelected ? selectedItem.assemblyId  :selectedComponent.id, isCompositeComponentSelected }));
            } else {
                dispatch(componentActions.moveToBinNormalWithReplacement({
                    componentID: isCompositeComponentSelected ? selectedItem.assemblyId  :selectedComponent.id, 
                    isCompositeComponentSelected,
                    replacementID: selectedDropdownItem,
                }));
            }
        } else if (radioServiceableSelected) {
            if (selectedDropdownItem === "default") {
                dispatch(componentActions.moveToServiceableNormalWithoutReplacement(
                    { componentID: isCompositeComponentSelected ? selectedItem.assemblyId  :selectedComponent.id, 
                        isCompositeComponentSelected }));
            } else {
                dispatch(componentActions.moveToServiceableNormalWithReplacement({
                    componentID: isCompositeComponentSelected ? selectedItem.assemblyId  :selectedComponent.id, 
                    isCompositeComponentSelected,
                    replacementID: selectedDropdownItem,
                }));
            }
        } else {
            if (selectedDropdownItem === "default") {
                dispatch(componentActions.moveToUnserviceableNormalWithoutReplacement(
                    { componentID: isCompositeComponentSelected ? selectedItem.assemblyId  :selectedComponent.id, 
                        isCompositeComponentSelected }));
            } else {
                dispatch(componentActions.moveToUnserviceableNormalWithReplacement({
                    componentID: isCompositeComponentSelected ? selectedItem.assemblyId  :selectedComponent.id, 
                    isCompositeComponentSelected,
                    replacementID: selectedDropdownItem,
                }));
            }
        }
        setSelectedDropdownItem("default");
        toggleMoveToSparePartsModal();
    };

    const hideMoveButton = (item) => {
        const totalNumberOfDays = item.alertInfo.totalNumberOfDays > 0 ?
            item.alertInfo.totalNumberOfDays :
            undefined;
        const totalNumberOfJumps = item.alertInfo.totalNumberOfJumps > 0 ?
            item.alertInfo.totalNumberOfJumps :
            undefined;

        if (totalNumberOfDays || totalNumberOfJumps) return false;
        else return true;
    };

    const hideInspectionButton = (item) => {
        const jumpsBetweenServices = item.alertInfo.jumpsBetweenServices > 0 ?
            item.alertInfo.jumpsBetweenServices :
            undefined;
        const daysBetweenServices = item.alertInfo.daysBetweenServices > 0 ?
            item.alertInfo.daysBetweenServices :
            undefined;

        if (jumpsBetweenServices || daysBetweenServices) return false;
        else return true;
    };

    useEffect(() => {
        let inspectionTopInner = null;
        let inspectionBottomInner = null;
        let eolInner = null;
        let usageTopInner = null;
        let usageBottomInner = null;
        if (selectedComponent && !isCompositeComponentSelected) {

            const currentItem = selectedComponent.requiredNumberOfSubComponents > 0 ?
                selectedComponent.subComponents[0].alertInfo : selectedComponent.alertInfo;

            if (currentItem.daysBetweenServices !== null) {
                setInspectionSuffix("days");
                inspectionTopInner = currentItem.daysAfterLastService;
                inspectionBottomInner = currentItem.daysBetweenServices;
            } else if (currentItem.jumpsBetweenServices !== null) {
                setInspectionSuffix("jumps");
                inspectionTopInner = currentItem.jumpsAfterLastService;
                inspectionBottomInner = currentItem.jumpsBetweenServices;
            } else {
                inspectionTopInner = "N/A";
                inspectionBottomInner = "N/A";
            }

            if (currentItem.totalNumberOfDays !== null) {
                setUsageSuffix("days");
                usageTopInner = currentItem.totalUtilizedDays;
                usageBottomInner = currentItem.totalNumberOfDays;
            } else if (currentItem.totalNumberOfJumps !== null) {
                setUsageSuffix("jumps");
                usageTopInner = currentItem.totalUtilizedJumps;
                usageBottomInner = currentItem.totalNumberOfJumps;
            } else {
                usageTopInner = "N/A";
                usageBottomInner = "N/A";
            }

            if (currentItem.totalNumberOfJumps === null) {
                if (currentItem.totalNumberOfDays === null) {
                    eolInner = "N/A";
                } else {
                    if (currentItem.totalNumberOfDays > 356) {
                        console.log(Math.floor(currentItem.totalNumberOfDays / 365), currentItem.totalNumberOfDays)
                        setEolSuffix("years");
                        const eolInnerU = Math.floor(currentItem.totalNumberOfDays / 365);
                        eolInner = eolInnerU ? eolInnerU : 0;
                    } else {
                        setEolSuffix("days");
                        eolInner = currentItem.totalNumberOfDays ?
                            currentItem.totalNumberOfDays :
                            0;
                    }
                }
            } else {
                setEolSuffix("jumps");
                eolInner = currentItem.totalNumberOfJumps;
            }

            setValues({
                inspectionTop: inspectionTopInner,
                inspectionBottom: inspectionBottomInner,
                eol: eolInner,
                usageTop: usageTopInner,
                usageBottom: usageBottomInner,
                defaultInspectionCount: inspectionBottomInner
            });
        }
    }, [selectedComponent]);

    const handleOnChangeDefaultInspectionCount = (value) => {
        setValues({
            ...values,
            defaultInspectionCount: value.target.value
        })
    }

    const renderComponentCard = (item, index) => {
        return <div key={index}
            className={"accordion-item missing-items"}
            onClick={() => {
                handleMissingItemClick(item);
            }}>
            <div
                className={"accordion-header border-bottom-0 accordion-button"}
                id={"flush-heading"}
                onClick={toggleSelectComponentModal}
                data-bs-toggle="modal"
                data-bs-target="#selectComponentModal">

                <div className={"item-text"}
                    onClick={toggleSelectComponentModal}
                    data-bs-toggle="modal"
                    data-bs-target="#selectComponentModal">
                    <h4>{item.displayName}</h4>
                </div>
            </div>
        </div>
    }

    const handleMovePress = () => {
        setCompositeComponentSelected(true)
        dispatch(componentActions.loadAvailableCompositeComponents({ componentType: selectedItem.componentType }));
        toggleMoveToSparePartsModal()
    }

    return (
        <div className={"parachute-detail-page mt-n3 p-5"}>
            <div
                className={"parachute-select text-center float-start w-48 d-flex"}>
                <div className="clearfix" />
                <div className={"select-card"}>
                    <div className="accordion accordion-flush "
                        id="accordionFlush">
                        {map(missingItemsList, (item, index) => {
                            return renderComponentCard(item, index)
                        })}
                        {map(itemsList, (item, index) => {
                            const hideMove = () => {
                                const totalNumberOfDays = item.alertInfo.totalNumberOfDays > 0 ?
                                    item.alertInfo.totalNumberOfDays :
                                    undefined;
                                const totalNumberOfJumps = item.alertInfo.totalNumberOfJumps > 0 ?
                                    item.alertInfo.totalNumberOfJumps :
                                    undefined;

                                if (totalNumberOfDays || totalNumberOfJumps) return false;
                                else return true;
                            };

                            const hideInspection = () => {
                                const jumpsBetweenServices = item.alertInfo.jumpsBetweenServices > 0 ?
                                    item.alertInfo.jumpsBetweenServices :
                                    undefined;
                                const daysBetweenServices = item.alertInfo.daysBetweenServices > 0 ?
                                    item.alertInfo.daysBetweenServices :
                                    undefined;

                                if (jumpsBetweenServices || daysBetweenServices) return false;
                                else return true;
                            };

                            const resolveLeftValue = (item) => {
                                if (item.totalNumberOfDays !== null) {
                                    return item.totalUtilizedDays;
                                } else if (item.totalNumberOfJumps !== null) {
                                    return item.totalUtilizedJumps;
                                } else {
                                    return undefined;
                                }
                            };

                            const resolveLeftBase = (item) => {
                                if (item.totalNumberOfDays !== null) {
                                    return item.totalNumberOfDays;
                                } else if (item.totalNumberOfJumps !== null) {
                                    return item.totalNumberOfJumps;
                                } else {
                                    return undefined;
                                }
                            };

                            const resolveRightValue = (item) => {
                                if (item.daysBetweenServices !== null) {
                                    return item.daysAfterLastService;
                                } else if (item.jumpsBetweenServices !== null) {
                                    return item.jumpsAfterLastService;
                                } else {
                                    return undefined;
                                }
                            };

                            const resolveRightBase = (item) => {
                                if (item.daysBetweenServices !== null) {
                                    return item.daysBetweenServices;
                                } else if (item.jumpsBetweenServices !== null) {
                                    return item.jumpsBetweenServices;
                                } else {
                                    return undefined;
                                }
                            };

                            return <GaugeCard title={item.displayName}
                                leftBase={resolveLeftBase(item.alertInfo)}
                                rightBase={resolveRightBase(item.alertInfo)}
                                leftValue={resolveLeftValue(item.alertInfo)}
                                rightValue={resolveRightValue(item.alertInfo)}
                                moveToBinLevel={capitalize(item.alertInfo.moveToBinLevel)}
                                moveToBinAlert={item.alertInfo.moveToBinLevel === "REPLACE"}
                                error={item.alertInfo.moveToInspectionLevel === "REPLACE"}
                                moveToInspectionLevel={capitalize(
                                    item.alertInfo.moveToInspectionLevel)}
                                components={item}
                                itemNumber={index}
                                id={item.id}
                                key={index}
                                hideInspection={hideInspection()}
                                hideMove={hideMove()}
                            />
                        })}

                        <div className={"d-inline-flex align-content-center mt-4 mb-4 "}>
                            <button type="button"
                                className={`btn btn-danger py-3 mw-180`}
                                style={{ minWidth: 180 }}
                                onClick={handleMovePress}>Move
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"separator float-start"} />

            {selectedComponent && <div className={"parachute-description float-start w-48 d-flex"}>
                <div className={"item-img text-center"}>
                    <img className={"text-center"} src={selectedComponent.imageUrl}
                        alt={"item-img"} />
                </div>
                <div className={"description-card"}>
                    <div className={"card-top"}>
                        <div className={"w-50 float-start"}>
                            <div className={"serial"}>
                                <span
                                    className={"float-start"}><BiBarcodeReader
                                        color={"#fff"}
                                        size={"22"} /></span>
                                <h6 className={"float-start"}>Serial
                                    No.</h6>
                                <label
                                    onClick={toggleSerialModal}
                                    className={"float-start"}
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    title={selectedComponent?.serialNumber}>{selectedComponent?.serialNumber}</label>
                            </div>
                        </div>
                        <div className={"w-50 float-start ps-2"}>
                            <div className={"dom"}>
                                <span
                                    className={"float-start"}><VscCalendar
                                        color={"#fff"}
                                        size={"22"} /></span>
                                <h6 className={"float-start"}>DOM</h6>
                                <label
                                    className={"float-start"}>{selectedComponentDOM}</label>
                            </div>
                        </div>
                    </div>
                    <div className={"card-bottom"}>
                        <div
                            className={"description-list w-185px float-start"}>
                            <span className={"float-start replace-bg"} />
                            <h6 className={"float-start"}>Inspection due
                                in</h6>
                            {values.inspectionTop !== "N/A" && <label className={"float-start"}><span
                                className={"replace-color"}>{
                                    `${values.inspectionTop}`
                                }</span>/{
                                    `${values.inspectionBottom} (${inspectionSuffix})`}
                            </label>}
                            {values.inspectionTop === "N/A" && <label className={"float-start"}>
                                <span>{"N/A"}</span>
                            </label>}
                        </div>
                        <div
                            className={"description-list w-120px float-start"}>
                            <span className={"float-start safe-bg"} />
                            <h6 className={"float-start"}>End of Life</h6>
                            <label
                                className={"float-start font-weight-bold"}>{
                                    `${values.eol} ${values.eol === "N/A" ?
                                        "" :
                                        eolSuffix}`}</label>
                        </div>
                        <div className={"clearfix"} />
                        <div
                            className={"description-list w-185px float-start"}>
                            <span
                                className={"float-start intermediary-bg"} />
                            <h6 className={"float-start"}>Usage
                                Counter</h6>
                            {values.usageTop !== "N/A" && <label
                                className={"float-start"}><span
                                    className={"intermediary-color"}>{
                                        `${values.usageTop}`}</span>/{
                                    `${values.usageBottom} (${usageSuffix})`}
                            </label>}
                            {values.usageTop === "N/A" && <label className={"float-start"}>
                                <span>{"N/A"}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                            </label>}
                        </div>
                    </div>
                </div>
                <div
                    className={"d-inline-flex align-content-center mt-4 mb-4 "}>
                    <button type="button"
                        className={`btn btn-outline-danger py-3 me-4 w-180px ${hideInspectionButton(
                            selectedComponent) ? "disabled" : ""}`}
                        onClick={togglePerformInspectionModal}
                        data-bs-toggle="modal"
                        data-bs-target="#performInspectionModal">Perform
                        Inspection
                    </button>
                    <button type="button"
                        className={`btn btn-danger py-3 w-180px ${hideMoveButton(
                            selectedComponent) ? "disabled" : ""}`}
                        onClick={toggleMoveToSparePartsModal}
                        data-bs-toggle="modal"
                        data-bs-target="#moveModal">Move
                    </button>
                </div>
            </div>}
            {!selectedComponent &&
                <div className={"parachute-detail-page mt-n3 p-5"}>
                    <div className={"item-img text-center"}>
                        <span><Icon path={mdiParachute} size={"100"} color={"#b1b5b8"} /></span>
                        <br />
                        <br />
                        <br />
                        <h5 className={"mt-3"}>A component is not selected</h5>
                    </div>
                </div>}
            <Modal isOpen={moveModal} toggle={toggleMove}
                centered={true}
                className={"mw-400"}>
                <div
                    className="modal-header text-center border-bottom-0 p-0 d-inline-block">
                    <h5 className="modal-title text-center"
                        id="moveModalLabel">Move</h5>
                </div>
                <div className="modal-body text-center ">
                    <p>To proceed, please select an option below</p>
                </div>
                <div className="modal-footer border-top-0 mx-auto p-0">
                    <button type="button"
                        className="btn btn-outline-danger me-3 py-3 px-4"
                        onClick={toggleMove}
                        data-bs-dismiss="modal">Move to Bin
                    </button>
                    <button type="button"
                        className="btn btn-danger py-3"
                        onClick={() => {
                            toggleMoveToSparePartsModal();
                            toggleMove();
                        }}
                        data-bs-toggle="modal"
                        data-bs-target="#movetoSparePartsModal">Move
                        to spare parts
                    </button>
                </div>
            </Modal>

            <Modal isOpen={moveToSparePartsModal}
                toggle={toggleMoveToSparePartsModal} centered={true}>
                <div
                    className="modal-header text-center border-bottom-0 p-0 d-inline-block">
                    <h5 className="modal-title text-center"
                        id="movetoSparePartsModalLabel">{isCompositeComponentSelected ? 'Move assembly to' : 'Move component to'}</h5>
                </div>
                {

                    <div className="modal-body text-center ">
                        <p>To proceed with a replacement, please select<br />
                            accordingly and confirm</p>
                        <div className={"my-4 mx-auto w-160"}>
                            <div className="form-check mb-2">
                                <input className="form-check-input me-3"
                                    type="radio" name="flexRadioDefault1"
                                    id="flexRadioDefault1" checked={radioServiceableSelected}
                                    onClick={() => {
                                        setRadioServiceableSelected(true);
                                        setRadioBinSelected(false);
                                    }} />
                                <label
                                    className="form-check-label mt-1 float-start"
                                    for="flexRadioDefault1">
                                    Serviceable
                                </label>
                            </div>
                            <div className="form-check mb-2">
                                <input className="form-check-input me-3"
                                    type="radio" name="flexRadioDefault2"
                                    id="flexRadioDefault2"
                                    checked={!radioServiceableSelected && !radioBinSelected}
                                    onClick={() => {
                                        setRadioServiceableSelected(false);
                                        setRadioBinSelected(false);
                                    }} />
                                <label
                                    className="form-check-label mt-1 float-start"
                                    for="flexRadioDefault2">
                                    Unserviceable
                                </label>
                            </div>
                            <div className="form-check mb-3">
                                <input className="form-check-input me-3"
                                    type="radio" name="flexRadioDefault3"
                                    id="flexRadioDefault3"
                                    checked={!radioServiceableSelected && radioBinSelected}
                                    onClick={() => {
                                        setRadioServiceableSelected(false);
                                        setRadioBinSelected(true);
                                    }} />
                                <label
                                    className="form-check-label mt-1 float-start"
                                    for="flexRadioDefault3">
                                    Bin
                                </label>
                            </div>
                            <FormGroup>
                                <Input type="select" name="selectMulti"
                                    id="exampleSelectMulti" onChange={(e) => {
                                        setSelectedDropdownItem(e.target.value);
                                    }}>
                                    <option
                                        value={"default"}>{
                                            componentsAvailable.length <= 0 ?
                                            "Nothing to select" :
                                            (isCompositeComponentSelected ? "Select a assembly" : "Select a component")
                                        }
                                    </option>
                                    {map(componentsAvailable, (item, index) => (
                                        isCompositeComponentSelected ?
                                            <option
                                                key={index.assemblyId}
                                                value={item.assemblyId}
                                            >
                                            {
                                                item.assemblyId
                                            }
                                            </option>
                                            :
                                            <option
                                                key={index.customID}
                                                value={item.requiredNumberOfSubComponents > 0 ?
                                                    item.subComponents[0].serialNumber :
                                                    item.id}
                                            >
                                            {
                                                item.requiredNumberOfSubComponents > 0 ?
                                                item.subComponents[0].customID :
                                                item.customID
                                            }
                                            </option>
                                    ))}
                                </Input>
                            </FormGroup>
                        </div>
                    </div>
                }
                <div className="modal-footer border-top-0 mx-auto p-0">
                    <button type="button"
                        className="btn btn-outline-danger me-3 py-3 px-5"
                        onClick={toggleMoveToSparePartsModal}
                        data-bs-dismiss="modal">Cancel
                    </button>
                    <button type="button"
                        className="btn btn-danger py-3 px-5"
                        onClick={handleMoveConfirm}>
                            Confirm
                    </button>
                </div>
            </Modal>

            <Modal isOpen={performInspectionModal}
                toggle={togglePerformInspectionModal} centered={true}
                className={"mw-425"}>
                <div
                    className="modal-header text-center border-bottom-0 p-0 d-inline-block">
                    <div className={"mb-2"}><span
                        className={"text-center"}><Icon path={mdiAlertCircleOutline}
                            color={"#FF1637"}
                            size={"49"} /></span></div>
                    <h5 className="modal-title text-center"
                        id="performInspectionModalLabel">{messages.inspectionConfirmTitle}
                    </h5>
                </div>
                <div className="modal-body text-center ">
                    <p>{messages.inspectionConfirmDescription}</p>
                    <div>
                        <p>Default Inspection Count</p>
                        <input type="text" value={
                            values.defaultInspectionCount}
                            onChange={handleOnChangeDefaultInspectionCount} />
                    </div>
                </div>
                <div className="modal-footer border-top-0 mx-auto p-0">
                    <button type="button"
                        className="btn btn-outline-danger me-3 py-3 px-5"
                        onClick={togglePerformInspectionModal}
                        data-bs-dismiss="modal">Cancel
                    </button>
                    <button type="button"
                        className="btn btn-danger py-3 px-5"
                        onClick={handleInspection}>Confirm
                    </button>
                </div>
            </Modal>

            <Modal isOpen={serialModal}
                toggle={toggleSerialModal} centered={true}
                className={"mw-425"}>
                <div
                    className="modal-header text-center border-bottom-0 p-0 d-inline-block">
                    <div className={"mb-2"}><span
                        className={"text-center"}><Icon path={mdiInformationOutline}
                            color={"#FF1637"}
                            size={"49"} /></span></div>
                    <h5 className="modal-title text-center"
                        id="performInspectionModalLabel">Serial Number</h5>
                </div>
                <div className="modal-body text-center ">
                    <p>{selectedComponent?.serialNumber}</p>
                </div>
                <div className="modal-footer border-top-0 mx-auto p-0">
                    <button type="button"
                        className="btn btn-danger py-3 px-5"
                        onClick={toggleSerialModal}>Ok
                    </button>
                </div>
            </Modal>

            <Modal isOpen={selectComponentModal}
                toggle={toggleSelectComponentModal} centered={true}
                className={"mw-425"}>
                <div
                    className="modal-header text-center border-bottom-0 p-0 d-inline-block">
                    <div className={"mb-2"}><span
                        className={"text-center"}>
                        <Icon path={mdiAlertCircleOutline}
                            color={"#FF1637"}
                            size={"49"} /></span></div>
                    <h5 className="modal-title text-center"
                        id="performInspectionModalLabel">Select Component</h5>
                </div>
                <div
                    className="modal-body text-center ">
                    <p>Add a component to this item</p>
                    <div
                        className="form-floating my-3 justify-content-center d-flex">
                        <FormGroup>
                            <Input type="select" name="selectMulti"
                                id="exampleSelectMulti"
                                onChange={handleSelectedPendingComponent}>
                                <option
                                    value={"default"}>{componentsAvailable.length <= 0 ?
                                        "Nothing to select" :
                                        "Select a component"}</option>
                                {map(componentsAvailable, (item, index) => (
                                    <option
                                        key={index.customID}
                                        value={item.id}
                                    >{item.customID}</option>
                                ))}
                            </Input>
                        </FormGroup>
                    </div>
                </div>
                <div className="modal-footer border-top-0 mx-auto p-0">
                    <button type="button"
                        className="btn btn-outline-danger me-3 py-3 px-5"
                        onClick={toggleSelectComponentModal}
                        data-bs-dismiss="modal">Cancel
                    </button>
                    <button type="button"
                        className="btn btn-danger py-3 px-5"
                        onClick={handleSubmit}
                        disabled={!selectedComponentType}>Submit
                    </button>
                </div>
            </Modal>
        </div>
    );
};

export default CompositeParachuteDetail;

