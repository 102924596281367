import "./styles/rigRackCardStyles.scss";
import tandemRigImage from "../assets/png/equipment/SigmaMain.jpeg";
import pilotRigImage from "../assets/png/equipment/strong_311.jpeg";
import harnessRigImage from "../assets/png/equipment/upt_sigmaharness_2.jpeg";
import floatationRigImage from "../assets/png/equipment/personalFlotation.png";
import logo from "../assets/svg/parachute-logo.svg";
import { BsArrowRightShort } from "react-icons/bs";
import { AiFillExclamationCircle } from "react-icons/ai";
import React, { useEffect, useState } from "react";

const RigRackCard = ({
	                     title,
	                     serviceLevel = "SAFE",
	                     numberOfAffectedParachutes = 1,
	                     moveToBinAlert,
	                     onClick,
	                     objectName,
                     }) => {

	const [image, setImage] = useState(logo);

	useEffect(() => {
		switch (objectName) {
			case "SIGMA_TANDEM_RIG": {
				setImage(tandemRigImage);
				break;
			}
			case "PILOT_RIG": {
				setImage(pilotRigImage);
				break;
			}
			case "TANDEM_PASSENGER_HARNESS": {
				setImage(harnessRigImage);
				break;
			}
			case "FLOATATION_DEVICE": {
				setImage(floatationRigImage);
				break;
			}
			default: {
				setImage(logo);
				break;
			}
		}
	}, [objectName]);

	let serviceLevelClass = serviceLevel.toLowerCase();

	return (
		<div className="float-start w-auto me-4" onClick={ onClick }>
			<div className="item-card card float-start">
				<div className={ "card-body" }>
					<div
						className={ "item-img text-center" }>
						<img
							className={ "text-center" }
							src={ image }
							alt={ "rig" }/>
						{ moveToBinAlert && <span
							className={ "exclamation" }><AiFillExclamationCircle
							color={ "#FF1637" }
							size={ "35" }/></span> }
					</div>
				</div>
				<div
					className={ `card-footer ${ serviceLevelClass }` }>
					<label>{ title } <span><BsArrowRightShort
						color={ "#F74A63" }
						size={ "24" }/></span>
					</label>
					<div
						className={ "clearfix" }></div>
					<span
						className={ "count float-end" }>{ `${ numberOfAffectedParachutes }` }</span>
				</div>
			</div>
		</div>
	);
};

export default RigRackCard;
