import React from 'react';
import { get } from 'lodash';
import { useFormik } from "formik";
import * as yup from "yup";
import "./styles/addCompanyStyles.scss";
import importImage from "../assets/svg/importImage.svg";
import logo from "../assets/svg/parachute-logo.svg";
import { AiFillSave, AiFillCloseCircle } from 'react-icons/ai';



const AddCompanyCard = ({
    onSave,
    onCancel,
    company,
    isEdit
}) => {

    const imageRef = React.useRef();

    const validationSchema = yup.object().shape({
        name: yup.string().required("Name is required"),
        email: yup.string().email().required("Valid email address is required"),
        phone: yup.string().matches(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/, "Valid Mobile Number required")
            .min(10, "to short")
            .max(12, "to long"),
        address: yup.string().required("Address is required"),
        description: yup.string().required("Description is required"),
        imageUrl: yup.string(),
        adminFullName: yup.string().required("Name is required"),
        adminEmail: yup.string().email().required("Valid email address is required"),
        adminProfilePictureURL: yup.string()
    });

    const formik = useFormik({
        initialValues: {
            name: get(company, 'name', ''),
            email: get(company, 'email', ''),
            phone: get(company, 'phone', ''),
            address: get(company, 'address', ''),
            description: get(company, 'description', ''),
            imageUrl: get(company, 'imageUrl', ''),
            imageChanged: false,
            adminFullName: get(company, 'admin.fullName', ''),
            adminEmail: get(company, 'admin.email', ''),
            adminProfilePictureURL: get(company, 'admin.profilePictureURL', ''),
            adminProfilePictureChanged: false,
            tenatId: get(company, 'identifier', '')
        },
        validationSchema,
        validateOnMount: true,
    });

    const handleOnChangeProfilePicture = (e) => {
        if (e.target.files[0]) {
            formik.setValues({
                ...formik.values,
                adminProfilePictureURL: e.target.files[0],
                adminProfilePictureChanged: true
            })
        }
    }

    const handleOnChangeCompanyImage = (e) => {
        if (e.target.files[0]) {
            formik.setValues({
                ...formik.values,
                imageUrl: e.target.files[0],
                imageChanged: true
            })
        }
    }

    const getAdminProfilePicture = () => {
        if (formik.values.adminProfilePictureChanged) {
            return URL.createObjectURL(formik.values.adminProfilePictureURL)
        } else if (formik.values.adminProfilePictureURL) {
            return formik.values.adminProfilePictureURL;
        } else {
            return logo;
        }
    }

    const getCompanyPicture = () => {
        if (formik.values.imageChanged) {
            return URL.createObjectURL(formik.values.imageUrl)
        } else if (formik.values.imageUrl) {
            return formik.values.imageUrl;
        } else {
            return logo;
        }
    }

    const handleOnClickImage = () => {
        imageRef.current.click()
    }

    const handleOnSave = () => onSave(formik.values, isEdit)

    const handleOnCancel = () => onCancel()

    return (
        <div className="add-company-container ">
            <h3 className="mb-3">Add Company</h3>
            <input id="fileButton" type="file" accept=".jpg,.gif,.png" ref={imageRef} hidden onChange={handleOnChangeCompanyImage} />
            <form>
                <div className="company-card">
                    <div>
                        <h5 className="title-text">Company Information</h5>
                    </div>

                    <div className="row row-deco">
                        <div className="col">
                            <label className="title-text" for="companyName">Company Name</label>
                            <input id="companyName" type="text" className="form-control" placeholder="Input Name" onChange={formik.handleChange("name")} value={formik.values.name}/>
                            <small id="companyNameHelp" className="form-text text-danger text-lowercase">{formik.errors.name}</small>
                        </div>
                        <div className="col">
                            <label className="title-text" for="companyNumber">Company Phone Number</label>
                            <input id="companyNumber" type="text" className="form-control" placeholder="Enter Phone Number" onChange={formik.handleChange("phone")} value={formik.values.phone}/>
                            <small id="companyNameHelp" className="form-text text-danger text-lowercase">{formik.errors.phone}</small>
                        </div>
                    </div>
                    <div className="row row-deco">
                        <div className="col">
                            <label className="title-text" for="companyName">Address</label>
                            <input id="companyName" type="text" className="form-control" placeholder="Input Address" onChange={formik.handleChange("address")} value={formik.values.address}/>
                            <small id="companyNameHelp" className="form-text text-danger text-lowercase">{formik.errors.address}</small>
                        </div>
                        <div className="col">
                            <label className="title-text" for="companyEmail">Email</label>
                            <input id="companyEmail" type="email" className="form-control" placeholder="Input Email" onChange={formik.handleChange("email")} value={formik.values.email} />
                            <small id="companyNameHelp" className="form-text text-danger text-lowercase">{formik.errors.email}</small>
                        </div>
                    </div>
                </div>
                <div className="company-discriprion-wrapper">
                    <div className="company-card import-discription-wrapper">
                        <div>
                            <h5 className="title-text">Company Description</h5>
                        </div>
                        <div className="row row-deco">
                            <div className="col">
                                <textarea id="discription" className="form-control" rows="4" onChange={formik.handleChange("description")} value={formik.values.description}/>
                                <small id="companyNameHelp" className="form-text text-danger text-lowercase">{formik.errors.description}</small>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-end">
                        <button type="button" className="border-0" onClick={handleOnClickImage}>
                            <div className="company-card import-image-wrapper" >
                                <img src={getCompanyPicture()} className="import-image" />
                            </div>
                        </button>
                    </div>
                </div>

                <div className="company-card">
                    <div>
                        <h5 className="title-text">Admin Details</h5>
                    </div>
                    <div className="row row-deco">
                        <div className="col">
                            <label className="title-text" for="adminName">Full Name</label>
                            <input id="adminName" type="text" className="form-control" placeholder="Input Name" onChange={formik.handleChange("adminFullName")} value={formik.values.adminFullName}/>
                            <small id="companyNameHelp" className="form-text text-danger text-lowercase">{formik.errors.adminFullName}</small>
                        </div>
                        <div className="col">
                            <label className="title-text" for="adminEmail">Email</label>
                            <input id="adminEmail" type="email" className="form-control" placeholder="Input Email" onChange={formik.handleChange("adminEmail")} value={formik.values.adminEmail}/>
                            <small id="companyNameHelp" className="form-text text-danger text-lowercase">{formik.errors.adminEmail}</small>
                        </div>
                    </div>
                    <div className="row row-deco">
                        <div className="col">
                            <label className="title-text" for="adminImage">Profile Picture</label>
                            <input id="adminImage" type="file" className="form-control" accept=".jpg,.gif,.png" onChange={handleOnChangeProfilePicture} />
                        </div>
                        <div className="col">
                            <img className="admin-profile-picture" src={getAdminProfilePicture()} />
                        </div>
                    </div>
                </div>

                <div className="company-card">
                    <div className="row flex-row">
                        <div className="col d-flex justify-content-center p-2">
                            <button type="button" className="btn btn-danger me-1 buttons" disabled={!formik.isValid} onClick={handleOnSave}>
                                <span className={"me-3"}><AiFillSave /></span> Save
                            </button>
                            <button type="button" className="btn btn-outline-danger buttons" onClick={handleOnCancel}>
                                <span className={"me-3"}><AiFillCloseCircle /></span> Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default AddCompanyCard;