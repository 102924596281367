import { get, post, put } from "../../helpers/baseAPI";
import popupHelper from "../../helpers/popupHelper";
import { errors } from "../../config/errors";
import moment from "moment";
import { isEmpty } from "lodash";
import { messages } from "../../config/messages";
import exp from "constants";

export const SET_DATE_RANGE = "SET_DATE_RANGE";
export const SET_LOADING = "SET_LOADING";
export const SET_PACKS_LIST = "SET_PACKS_LIST";
export const SET_PARACHUTE_LIST = "SET_PARACHUTE_LIST";

const setDateRange = ({ dateRange }) => (dispatch) => {
	dispatch({
		type: SET_DATE_RANGE,
		payload: dateRange,
	});
};

const setLoading = (isLoading) => (dispatch) => {
	dispatch({
		type: SET_LOADING,
		payload: isLoading,
	});
};

const setPacksList = (packsList) => (dispatch) => {
	dispatch({
		type: SET_PACKS_LIST,
		payload: packsList,
	});
};

const setReserveParachuteList = (packsList) => (dispatch) => {
	dispatch({
		type: SET_PARACHUTE_LIST,
		payload: packsList,
	});
};


const loadNumberOfPacksByUser = () => async (dispatch, getState) => {
	const { selectedUser } = getState().userReducer;
	const { startDate, endDate } = getState().reportReducer;

	if (isEmpty(selectedUser)) {
		popupHelper({ dispatch, error: true, message: errors.selectValidParams });
		return;
	}

	dispatch(setLoading(true));

	try {
		const endPoint = `scan?from=${moment(startDate).format('DD.MM.yyyy')}-00:00:00&to=${moment(endDate).format('DD.MM.yyyy')}-23:59:59&email=${selectedUser.email}`;
		const response = await get(endPoint);
		if (response.error) throw new Error(errors.fetch_get);
		const packsList = await response.json();
		dispatch(setPacksList(packsList));
	} catch (e) {
		popupHelper({ dispatch, error: true, message: errors.connectionError });
	}
	dispatch(setLoading(false));
}

const loadReserveParachuteExpiryDates = () => async (dispatch, getState) => {
	const { startDate, endDate } = getState().reportReducer;

	dispatch(setLoading(true));

	try {
		const endPoint = `component/reserve-parachutes?from=${moment(startDate).format('DD.MM.yyyy')}-00:00:00&to=${moment(endDate).format('DD.MM.yyyy')}-23:59:59`;
		const response = await get(endPoint);
		if (response.error) throw new Error(errors.fetch_get);
		const parachuteList = await response.json();
		dispatch(setReserveParachuteList(parachuteList));
	} catch (e) {
		popupHelper({ dispatch, error: true, message: errors.connectionError });
	}
	dispatch(setLoading(false));
}

export { setDateRange, loadNumberOfPacksByUser, loadReserveParachuteExpiryDates };
