import {
	SET_ACTIVE,
	SET_PARACHUTE_TYPES,
	SET_MESSAGE,
	SET_PARACHUTE_TYPE,
	SET_WARNING_COUNT,
} from "../actions/parachuteActions";

const initialState = {
	parachuteTypes: {},
	parachuteType: [],
	active: false,
	message: "",
	warningCount: 0,
};

function parachuteReducer(state = initialState, action) {
	switch (action.type) {
		case SET_ACTIVE:
			return {
				...state,
				active: action.payload,
			};
		case SET_PARACHUTE_TYPES:
			return {
				...state,
				parachuteTypes: action.payload,
			};
		case SET_MESSAGE:
			return {
				...state,
				message: action.payload,
			};
		case SET_PARACHUTE_TYPE:
			return {
				...state,
				parachuteType: action.payload,
			};
		case SET_WARNING_COUNT:
			return {
				...state,
				warningCount: action.payload,
			};
		default:
			return state;
	}
}

export default parachuteReducer;