import { get } from "../../helpers/baseAPI";
import { reorderParachutes } from "../../helpers/util";
import { errors } from "../../config/errors";

export const SET_PARACHUTE_TYPES = "SET_PARACHUTE_TYPES";
export const SET_PARACHUTE_TYPE = "SET_PARACHUTE_TYPE";
export const SET_ACTIVE = "SET_ACTIVE";
export const SET_MESSAGE = "SET_MESSAGE";
export const SET_WARNING_COUNT = "SET_WARNING_COUNT";

const setWarningCount = ({ warningCount }) => (dispatch) => {
	dispatch({
		type: SET_WARNING_COUNT,
		payload: warningCount,
	});
};

const setActivityOn = () => (dispatch) => {
	dispatch({
		type: SET_ACTIVE,
		payload: true,
	});
};

const setActivityOff = () => (dispatch) => {
	dispatch({
		type: SET_ACTIVE,
		payload: false,
	});
};

const setParachuteTypes = ({ parachuteTypes }) => (dispatch) => {
	dispatch({
		type: SET_PARACHUTE_TYPES,
		payload: parachuteTypes,
	});
};

const setParachuteType = ({ parachuteType }) => (dispatch) => {
	dispatch({
		type: SET_PARACHUTE_TYPE,
		payload: parachuteType,
	});
};

const setMessage = ({ message }) => (dispatch) => {
	dispatch({
		type: SET_MESSAGE,
		payload: message,
	});
};

const loadParachuteTypes = () => async (dispatch) => {
	dispatch(setActivityOn());
	const endpoint = "parachute/types";
	try {
		const response = await get(endpoint);
		const data = await response.json();
		dispatch(setParachuteTypes({ parachuteTypes: data }));
		dispatch(setMessage({ message: "" }));
	} catch (e) {
		dispatch(setMessage({ message: errors.connectionError }));
	}
	dispatch(setActivityOff());
};

const loadParachuteType = ({ type }) => async (dispatch) => {
	dispatch(setActivityOn());
	const endpoint = `parachute/type/${ type }`;
	try {
		const response = await get(endpoint);
		const data = await response.json();
		let reorderedData = data;
		if (Array.isArray(data)) {
			reorderedData = data.filter(parachute => !parachute.movedToBin);
		}
		dispatch(setParachuteType({ parachuteType: reorderedData }));
		dispatch(setMessage({ message: "" }));
	} catch (e) {
		dispatch(setMessage({ message: errors.connectionError }));
	}
	dispatch(setActivityOff());
};

export {
	loadParachuteTypes,
	loadParachuteType,
	setWarningCount,
	setParachuteType,
	setActivityOn,
};
