import popupHelper from "../../helpers/popupHelper";
import { get, put } from '../../helpers/baseAPI';
import { errors } from "../../config/errors";

export const SET_LOADING = 'SET_LOADING'
export const SET_CONFIGS = 'SET_CONFIGS'
export const UPDATE_CONFIGS = 'UPDATE_CONFIGS'

export const setLoading = (payload) => (dispatch) => {
	dispatch({
		type: SET_LOADING,
		payload,
	});
};

export const setConfigs = (payload) => (dispatch) => {
	dispatch({
		type: SET_CONFIGS,
		payload,
	});
};

export const updateConfigs = (data, path) => (dispatch) => {
    dispatch({
		type: UPDATE_CONFIGS,
		data,
        path
	});
}

export const loadSettings = () => async (dispatch) => {
	dispatch(setLoading(true));
	const endPoint = `configs/all`;
	try {
		 const response = await get(endPoint);
		 if (response.error) throw new Error(errors.fetch_get);
		 const configs = await response.json();
		 dispatch(setConfigs(configs));
	} catch (e) {
		popupHelper({ dispatch, error: true, message: errors.connectionError });
	}
	dispatch(setLoading(false));
};

const saveParachuteConfig = async (dispatch, url, body) => {
	try {
		 const response = await put({
			END_POINT: url,
			body
		 });
		 if (response.error) throw new Error(errors.fetch_get);
	} catch (e) {
		popupHelper({ dispatch, error: true, message: errors.connectionError });
	}
}

export const saveSettings = () => async (dispatch, getState) => {
	dispatch(setLoading(true));

	getState().settingsReducer.configs.forEach(e => {
		saveParachuteConfig(dispatch, `configs/parachutes/${e.parachuteType}`, e)
	});
	dispatch(setLoading(false));
};