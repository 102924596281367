import { get } from "../../helpers/baseAPI";
import { errors } from "../../config/errors";
import popupHelper from "../../helpers/popupHelper";

export const SET_ACTIVE = "SET_ACTIVE";
export const SET_ANALYTICS = "SET_ANALYTICS";
export const SET_PAGE_SIZE = "SET_PAGE_SIZE";
export const SET_CURRENT_PAGE = "SET_CURRENT_PAGE";
export const SET_TOTAL_PAGES = "SET_TOTAL_PAGES";

const setActive = () => (dispatch) => {
	dispatch({
		type: SET_ACTIVE,
		payload: true,
	});
};

const setInactive = () => (dispatch) => {
	dispatch({
		type: SET_ACTIVE,
		payload: false,
	});
};

const setAnalytics = ({ analytics }) => (dispatch) => {
	dispatch({
		type: SET_ANALYTICS,
		payload: analytics,
	});
};

const setPageSize = ({ pageSize }) => (dispatch) => {
	dispatch({
		type: SET_PAGE_SIZE,
		payload: pageSize,
	});
};

const setCurrentPage = ({ currentPage }) => (dispatch) => {
	dispatch({
		type: SET_CURRENT_PAGE,
		payload: currentPage,
	});
};

const setTotalPages = ({ totalPages }) => (dispatch) => {
	dispatch({
		type: SET_TOTAL_PAGES,
		payload: totalPages,
	});
};

const loadAnalytics = ({ userId, page = 0, isAscending = false }) => async (dispatch) => {
	dispatch(setActive());
	const endPoint = `audit?userId=${ userId }&isAscending=${ isAscending }&page=${ page }`;
	try {
		const response = await get(endPoint);
		if (response.error) throw new Error(errors.fetch_get);
		const auditResponse = await response.json();
		dispatch(setAnalytics({ analytics: auditResponse.results }));
		dispatch(setPageSize({ pageSize: auditResponse.pageSize }));
		dispatch(setCurrentPage({ currentPage: auditResponse.currentPage }));
		dispatch(setTotalPages({ totalPages: auditResponse.totalPages }));
	} catch (e) {
		popupHelper({ dispatch, error: true, message: errors.connectionError });
	}
	dispatch(setInactive());
};

export { loadAnalytics };