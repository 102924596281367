import {
	SET_AUTH_SET_USER,
	SET_AUTH_CLEAR_USER,
	SET_AUTH_AUTHENTICATED,
	SET_AUTH_AUTHENTICATING,
	SET_AUTH_FAILED,
	SET_AUTH_MESSAGE,
} from "../actions/authActions";

const initialState = {
	authenticated: false,
	authenticating: false,
	failed: false,
	message: "",
	id: 0,
	email: "",
	fullName: "",
	profilePictureURL: null,
	roles: [],
	isSuperUserLogged: false,
};

function authReducer(state = initialState, action) {
	switch (action.type) {
		case SET_AUTH_SET_USER:
			return {
				...state,
				id: action.payload.id,
				email: action.payload.email,
				fullName: action.payload.fullName,
				roles: action.payload.roles,
				profilePictureURL: action.payload.url,
				isSuperUserLogged: action.payload.isSuperUserLogged
			};
		case SET_AUTH_CLEAR_USER:
			return initialState;
		case SET_AUTH_AUTHENTICATED:
			return {
				...state,
				authenticated: true,
				authenticating: false,
				failed: false,
			};
		case SET_AUTH_AUTHENTICATING:
			return {
				...state,
				authenticated: false,
				authenticating: true,
			};
		case SET_AUTH_FAILED:
			return {
				...state,
				authenticated: false,
				authenticating: false,
				failed: true,
			};
		case SET_AUTH_MESSAGE:
			return {
				...state,
				message: action.payload,
			};
		default:
			return state;
	}
}

export default authReducer;