import SparePartsServiceable from "./SparePartsServiceable";
import SparePartsUnserviceable from "./SparePartsUnserviceable";
import { useLocation } from "react-router-dom";

export const SparePartsDetailContainer = () => {
	const location = useLocation();

	const { item } = location.state;

	return (
		<div className={ "rig-nav-tabs" }>
			<nav>
				<div className="nav nav-tabs" id="nav-tab" role="tablist">
					<button className="nav-link active" id="nav-home-tab"
					        data-bs-toggle="tab" data-bs-target="#nav-home"
					        type="button" role="tab" aria-controls="nav-home"
					        aria-selected="true">Serviceable
					</button>
					<button className="nav-link" id="nav-profile-tab"
					        data-bs-toggle="tab" data-bs-target="#nav-profile"
					        type="button" role="tab" aria-controls="nav-profile"
					        aria-selected="false">Unserviceable
					</button>
				</div>
			</nav>
			<div className="tab-content card" id="nav-tabContent">
				<div className="tab-pane fade show active" id="nav-home"
				     role="tabpanel" aria-labelledby="nav-home-tab">
					<SparePartsServiceable title={ item.displayType } typeID={ item.typeID }/>
				</div>
				<div className="tab-pane fade" id="nav-profile" role="tabpanel"
				     aria-labelledby="nav-profile-tab">
					<SparePartsUnserviceable typeID={ item.typeID } title={ item.displayType }/>
				</div>
			</div>
		</div>
	);
};

export const CompositeSparePartsDetailContainer = () => {
	const location = useLocation();

	const { item } = location.state;

	return (
		<div className={ "rig-nav-tabs" }>
			<nav>
				<div className="nav nav-tabs" id="nav-tab" role="tablist">
					<button className="nav-link active" id="nav-home-tab"
					        data-bs-toggle="tab" data-bs-target="#nav-home"
					        type="button" role="tab" aria-controls="nav-home"
					        aria-selected="true">Serviceable
					</button>
					<button className="nav-link" id="nav-profile-tab"
					        data-bs-toggle="tab" data-bs-target="#nav-profile"
					        type="button" role="tab" aria-controls="nav-profile"
					        aria-selected="false">Unserviceable
					</button>
				</div>
			</nav>
			<div className="tab-content card" id="nav-tabContent">
				<div className="tab-pane fade show active" id="nav-home"
				     role="tabpanel" aria-labelledby="nav-home-tab">
					<SparePartsServiceable title={ item.displayType } typeID={ item.typeID } isAssembly/>
				</div>
				<div className="tab-pane fade" id="nav-profile" role="tabpanel"
				     aria-labelledby="nav-profile-tab">
					<SparePartsUnserviceable typeID={ item.typeID } title={ item.displayType } isAssembly/>
				</div>
			</div>
		</div>
	);
};
