import React, { useEffect, useState } from "react";
import moment from "moment";
import { Modal, ModalBody, ModalHeader, Button, ModalFooter } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { loadUsers, setSelectedUser } from "../redux/actions/userActions";
import "../styles/reportsStyles.scss"
import DropDown from "../components/DropDown";
import { AiFillCaretDown } from 'react-icons/ai'
import { setDateRange, loadNumberOfPacksByUser } from "../redux/actions/reportActions";
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import LoadingComponent from "../components/LoadingComponent";
import ListEmptyComponent from "../components/ListEmptyComponent";
import { isEmpty } from "lodash";
import XLSX from 'sheetjs-style'
import {saveAs} from 'file-saver'

export default function ReportsNumberOfPacks() {

	const [modal, setModal] = useState(false);
	const toggle = () => setModal(!modal);

	const { users, selectedUser } = useSelector(state => state.userReducer);
	const { startDate, endDate, packsList, loading } = useSelector(state => state.reportReducer);
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(loadUsers({ mandatoryFieldsOnly: true }))
	}, [dispatch])

	const handleDropDownClick = (user) => {
		dispatch(setSelectedUser({ user }))
	}

	const handleOnClickOpenModal = () => toggle()

	const handleOnChangeDates = (item) => {
		dispatch(setDateRange({ dateRange: item.selection }))
	}

	const handleOnClickUpdate = () => {
		dispatch(loadNumberOfPacksByUser())
	}

	const exportToExcel = async () => {
		const worksheet = XLSX.utils.json_to_sheet(packsList.map(pack=>({
			date: moment(pack.scannedAt).format("DD MMM yyyy"),
			time: moment(pack.scannedAt).format("LT"),
			"Rig Custom ID": pack.parachuteCustomId,
			"Serial No.": pack.parachuteSerial
		})));
		const workbook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(workbook, worksheet, "Number of Packs By User");
		XLSX.writeFile(workbook, `number-of-packs-by-user.xlsx`)
	}

	return (
		<div>
			<div class="header-row">
				<div className="header-container dropdown-container">
					<label ><strong> User: </strong></label>
					<div class="dropdown">
						<DropDown
							defaultSelect="Select User"
							items={users.map(u => ({
								...u,
								name: u.fullName,
							}))}
							itemClick={handleDropDownClick}
							selectedItem={selectedUser}
						/>
					</div>
				</div>
				<div className="header-container date-range-container">
					<label ><strong> DateRange: </strong></label>
					<div className="date-text left">
						{moment(startDate).format("DD MMM yyyy")}
					</div>
					<div className="date-text right">
						{moment(endDate).format("DD MMM yyyy")}
					</div>
					<button className="down-arrow" onClick={handleOnClickOpenModal}>
						<AiFillCaretDown size={25} />
					</button>
				</div>
				<div className="header-container">
					<Button color="danger" onClick={handleOnClickUpdate}>
						Update
					</Button>
				</div>
				<div className="header-container">
					<Button color="danger" onClick={exportToExcel} disabled={packsList.length === 0}>
						Export
					</Button>
				</div>
			</div>
			{loading && <LoadingComponent />}
			{!loading && isEmpty(packsList) && <ListEmptyComponent />}
			{!loading && !isEmpty(packsList) &&
				<div className="data-table">
					<table class="table table-striped" borderless>
						<thead>
							<tr>
								<th scope="col">
									Date
								</th>
								<th scope="col">
									Time
								</th>
								<th scope="col">
									Rig Custom ID
								</th>
								<th scope="col">
									Serial No.
								</th>
							</tr>
						</thead>
						<tbody>
							{
								packsList.map(pack => (
									<tr>
										<td>
											{moment(pack.scannedAt).format("DD MMM yyyy")}
										</td>
										<td>
											{moment(pack.scannedAt).format("LT")}
										</td>
										<td>
											{pack.parachuteCustomId}
										</td>
										<td>
											{pack.parachuteSerial}
										</td>
									</tr>
								))
							}
						</tbody>
					</table>
				</div>
			}

			<Modal isOpen={modal} toggle={toggle} size="lg">
				<ModalHeader toggle={toggle}>Select Date Range</ModalHeader>
				<ModalBody style={{
					display: 'flex',
					justifyContent: 'center'
				}}>

					<DateRangePicker
						onChange={handleOnChangeDates}
						showSelectionPreview={true}
						moveRangeOnFirstSelection={false}
						months={1}
						ranges={[{
							startDate,
							endDate,
							key: 'selection'
						}]}
						direction="horizontal"
						scroll={{ enabled: true }}
					/>
				</ModalBody>
				<ModalFooter>
					<Button onClick={toggle}>Done</Button>
				</ModalFooter>
			</Modal>
		</div >
	);
}
