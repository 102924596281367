import { SET_DATE_RANGE, SET_LOADING, SET_PACKS_LIST, SET_PARACHUTE_LIST } from "../actions/reportActions";

const initialState = {
    startDate: new Date(),
    endDate: new Date(),
    loading: false,
    packsList: [],
    reserveParachuteList: []
};

export default function reportReducer(state = initialState, action) {
    switch (action.type) {
        case SET_DATE_RANGE:
            return {
                ...state,
                startDate: action.payload.startDate,
                endDate: action.payload.endDate,
            };
        case SET_LOADING:
            return {
                ...state,
                loading: action.payload
            }
        case SET_PACKS_LIST:
            return {
                ...state,
                packsList: action.payload
            }
        case SET_PARACHUTE_LIST: 
            return {
                ...state,
                reserveParachuteList: action.payload
            }
        default:
            return state;
    }
}