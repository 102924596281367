import { appSettings } from "../../config";
import { cloudinaryPost } from "../../helpers/baseAPI";
import popupHelper from "../../helpers/popupHelper";
import { errors } from "../../config/errors";

export const SET_UPLOADING = "SET_UPLOADING";
export const SET_IMAGE = "SET_IMAGE";

const startUploading = ({ imageFile }) => (dispatch) => {
	dispatch({
		type: SET_UPLOADING,
		payload: true,
	});
	dispatch(uploadImage(imageFile));
};

const uploadComplete = () => (dispatch) => {
	dispatch({
		type: SET_UPLOADING,
		payload: false,
	});
};

const setImage = ({ imageFile }) => (dispatch) => {
	dispatch({
		type: SET_IMAGE,
		payload: imageFile,
	});
};

const uploadImage = (image) => async (dispatch) => {
	const data = new FormData();
	data.append("file", image);
	data.append("upload_preset", appSettings.upload_preset);
	data.append("cloud_name", appSettings.cloud_name);

	const response = await cloudinaryPost(data);
	if (response.error) {
		popupHelper({ dispatch, message: errors.cloudinaryError, error: true });
		dispatch(uploadComplete());
		dispatch(setImage({ imageFile: null }));
	} else {
		dispatch(uploadComplete());
		dispatch(setImage({ imageFile: response }));
	}
};

export { startUploading, setImage };