const tokenKey = "@ACCESS_TOKENS";

const client_A = {
	cloudinary_URL: "https://api.cloudinary.com/v1_1/riglog/image/upload",
	API_URL: "https://api.riglog.techilasoftware.com/v1",
	upload_preset: "riglog_open",
	cloud_name: "riglog",
	momentFormat: "DD MMM, yyyy - h:mm:ss A",
	momentDateOnlyFormat: "DD-MMM-yyyy",
};

const appSettings = client_A;

export { tokenKey, appSettings };