export const SET_STATUS = "SET_STATUS";

const setStatus = ({ statusText }) => (dispatch) => {
	dispatch({
		type: SET_STATUS,
		payload: statusText,
	});
};

const clearStatus = () => (dispatch) => {
	dispatch({
		type: SET_STATUS,
		payload: "",
	});
};

export { setStatus, clearStatus };