import React, { useEffect } from "react";
import "../styles/rigRackStyles.scss";
import logo from "../assets/svg/parachute-logo.svg";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { checkLogin } from "../redux/actions/authActions";

const Splash = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const { authenticated, failed, isSuperUserLogged } = useSelector(
		state => state.authReducer);

	useEffect(() => {
		dispatch(checkLogin());
	}, [dispatch]);

	useEffect(() => {
		if (authenticated) {
			history.push({
				pathname: isSuperUserLogged ? "/companies" : "/rigRack",
				state: {
					pageTitle: "Rig Rack",
					activeTab: 1,
					infoPaneEnabled: true,
					searchEnabled: false,
				},
			});
		}
	}, [authenticated, isSuperUserLogged, history]);

	useEffect(() => {
		if (failed) {
			history.push({
				pathname: "/login",
				/*                state: {
									pageTitle: "Rig Rack",
									activeTab: 1,
									infoPaneEnabled: true,
									searchEnabled: false,
								},*/
			});
		}
	}, [failed, history]);

	return (
		<div className={ "splash-page" }>
			<div className="text-center d-flex justify-content-center content">
				<div>
					<div className={ "position-absolute top-50 start-50 translate-middle" }>
						<div className={ "logo text-center" }><img src={ logo } alt={ "rig-log-logo" }/>
						</div>
					</div>
				</div>
				<div className={ "footer-text" }>
					<h3 className={ "text-center" }>from</h3>
					<h2 className={ "text-center" }>TECHILA SOFTWARE</h2>
				</div>
			</div>
		</div>
	);
};

export default Splash;