import React, { useEffect, useState } from "react";
import ParaTypeCard from "../components/ParaTypeCard";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loadParachuteType, setActivityOn } from "../redux/actions/parachuteActions";
import { map } from "lodash/collection";
import { setStatus } from "../redux/actions/statusActions";
import { startCase, upperFirst } from "lodash";
import ListEmptyComponent from "../components/ListEmptyComponent";
import { setSelectedItem } from "../redux/actions/componentActions";
import LoadingComponent from "../components/LoadingComponent";

const ParachuteType = () => {
	const history = useHistory();
	const location = useLocation();
	const dispatch = useDispatch();
	const { parachuteType, active } = useSelector(
		state => state.parachuteReducer);
	const { searchText } = useSelector(state => state.searchReducer);

	const [displayParachutes, setDisplayParachutes] = useState([]);

	const {
		pageTitle: parachuteTypeTitle,
		parachuteTypeID,
	} = location.state;

	useEffect(() => {
		dispatch(setActivityOn())
	}, [dispatch]);

	useEffect(() => {
		parachuteTypeID &&
		dispatch(loadParachuteType({ type: parachuteTypeID }));
	}, [dispatch, parachuteTypeID]);

	useEffect(() => {
		dispatch(setStatus({
			statusText: `Displaying ${ parachuteType && Object.keys(
				parachuteType).length } ${ parachuteType && Object.keys(
				parachuteType).length > 1 ? "Items" : "Item" }`,
		}));
		if (parachuteType) setDisplayParachutes(parachuteType);
	}, [parachuteType, dispatch]);

	const handleClick = ({ item }) => {
		dispatch(setSelectedItem({ item }));
		history.push("/paraDetail", {
			pageTitle: upperFirst(
				startCase(item.customID.toLowerCase())),
			searchEnabled: false,
			selectedItem: item,
			activeTab: 1,
			breadCrumbSet: [
				{
					title: "Rig Rack",
					to: "/rigRack",
				},
				{
					title: parachuteTypeTitle,
					to: "back",
				},
				{
					title: upperFirst(
						startCase(item.customID.toLowerCase())),
				},
			],
		});
	};

	useEffect(() => {
		const searchResult = parachuteType.filter((item) => {
			return item.customID.includes(searchText);
		});
		if (searchResult) setDisplayParachutes(searchResult);
		else setDisplayParachutes([]);
	}, [searchText, parachuteType]);

	const displayItem = (item, key) => {
		return (
			<ParaTypeCard key={ key } title={ upperFirst(
				startCase(item.customID.toLowerCase())) }
			              imageURL={ item.imageUrl }
			              disabled={ item.missingComponents.length > 0 }
			              numberOfAffectedParachutes={ item.affectedComponents.numberOfAffectedComponents }
			              serviceLevel={ item.affectedComponents.moveToServiceLevel }
			              moveToBinAlert={ item.affectedComponents.moveToBinAlert }
			              onClick={ () => handleClick({ item }) }
			/>
		);
	};

	return (
		<div className="row g-4 w-auto p-5">
			{ active && <LoadingComponent/> }
			{ !active && displayParachutes.length > 0 && map(displayParachutes, displayItem) }
			{ !active && displayParachutes.length <= 0 && <ListEmptyComponent/> }
		</div>
	);
};

export default ParachuteType;