import React from "react";
import ListEmptyComponent from "../ListEmptyComponent";
import DetailCard from "./DetailCard";
import DetailRow from "./DetailRow";

const ConfigPanel = ({ config, onUpdate, shouldDisableSaveButton }) => {

    if (config == null) {
        return <ListEmptyComponent />
    }

    const handleOnSave = (d, v) => {
        onUpdate(d, v)
    }

    const handleOnDisableSaveButton = (v) => shouldDisableSaveButton(v)

    return (
        <div>
            <DetailRow name={config.name} expanded />
            <DetailCard details={config}
                componentHeirarchy={`${config.name}`}
                onSave={handleOnSave}
                shouldDisableSaveButton={handleOnDisableSaveButton} />
            {
                config.componentTypes && config.componentTypes.map(componenetconfig =>
                    <div className="main-component-wrapper">
                        <DetailRow name={componenetconfig.name} expanded />
                        {   !componenetconfig.subComponents &&
                            <DetailCard details={componenetconfig} componentHeirarchy={`${config.name},${componenetconfig.name}`} onSave={handleOnSave} shouldDisableSaveButton={handleOnDisableSaveButton} />
                        }
                        {
                            componenetconfig.subComponents && componenetconfig.subComponents.map(subComponenetconfig =>
                                <div className="sub-component-wrapper">
                                    <DetailRow name={subComponenetconfig.name} expanded />
                                    <DetailCard details={subComponenetconfig} componentHeirarchy={`${config.name},${componenetconfig.name},${subComponenetconfig.name}`} onSave={handleOnSave} shouldDisableSaveButton={handleOnDisableSaveButton} />

                                </div>)
                        }
                    </div>)
            }
        </div>
    )
}

export default ConfigPanel;