import { cloneDeep } from "lodash";
import {
    IS_LOADING_COMPANIES,
    SET_COMPANIES,
    CHANGE_ENABILITY,
    SET_EDITABLE_WINDOW_SHOW
} from "../actions/companyActions";

const initialState = {
    companies: [],
    isLoadingCompanies: false,
    showEditableWindow: false
};

export default function companyReducer(state = initialState, action) {
    switch (action.type) {
        case SET_COMPANIES:
            return {
                ...state,
                companies: action.payload
            }
        case IS_LOADING_COMPANIES:
            return {
                ...state,
                isLoadingCompanies: action.payload
            }
        case CHANGE_ENABILITY: {
            const companies = cloneDeep(state.companies).map(company => {
                if(company.identifier === action.id){
                    return{
                        ...company,
                        isEnabled: action.payload
                    }
                }
                return company;
            })
            return {
                ...state,
                companies
            }
        }
        case SET_EDITABLE_WINDOW_SHOW:
            return {
                ...state,
                showEditableWindow: action.payload
            }
        default:
            return state;
    }
}