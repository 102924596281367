import { map } from "lodash/collection";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import React, { useEffect, useState } from "react";
import "../styles/rigRackStyles.scss";
import { useLocation } from "react-router-dom";
import * as binActions from "../redux/actions/binActions";
import { useDispatch, useSelector } from "react-redux";
import ListEmptyComponent from "../components/ListEmptyComponent";
import LoadingComponent from "../components/LoadingComponent";

const BinDetail = () => {
	const location = useLocation();
	const dispatch = useDispatch();
	const { binComponents, totalPages, currentPage, active } = useSelector(state => state.binReducer);
	const [pages, setPages] = useState(null);
	const [visible, setVisible] = useState(false);

	useEffect(() => {
		if (totalPages <= 0) setVisible(false);
		else {
			setVisible(true);
			let innerArr = Array(totalPages).fill("");
			setPages(innerArr);
		}
	}, [totalPages]);

	const { item } = location.state;

	useEffect(() => {
		if (item) dispatch(binActions.loadBinComponents({ componentType: item.typeID, isParachute: item.isParachute, isComposite: item.isComposite }));
	}, [item, dispatch]);

	const renderItem = (sItem, key) => {
		const resolveSubText = (item) => {
			if (item) {
				if (item.totalNumberOfDays !== null) {
					return `${item.totalNumberOfDays} (days)`;
				} else if (item.totalNumberOfJumps !== null) {
					return `${item.totalNumberOfJumps} (jumps)`;
				} else {
					return "N/A";
				}
			} else {
				return "N/A";
			}
		};

		return (
			<div className={"row mx-0 spare-parts-list"} key={key}>
				<div className={" serial-number"}>
					<span className={"float-start d-inline-flex"}><img
						src={sItem.requiredNumberOfSubComponents > 0 ?
							sItem.subComponents[0].imageUrl :
							sItem.imageUrl} alt="sparePart"
						className="src" /></span>
					<h4>{sItem.requiredNumberOfSubComponents > 0 ? sItem.subComponents[0].customID : sItem.customID}</h4>
				</div>
				<div className={"spare-parts-time"}>
					<h4> {resolveSubText(sItem.requiredNumberOfSubComponents > 0 ?
						sItem.subComponents[0].alertInfo :
						sItem.alertInfo)}</h4>
				</div>
			</div>
		);
	};

	return (
		<div className={"rig-nav-tabs"}>
			<div className={"tab-content card"}>
				<div className={"spare-parts-serviceable-page"}>
					<div className={"d-none"}>
						{item.displayType}
					</div>
					<div>
						<div className={"spare-parts-table"}>
							<div className={"table-header-top"}>
								<div className={"float-start"}>
									<h2>{`${item.displayType} Archived Spare Parts List`}</h2>
								</div>
								<div className={"float-end ms-4 ps-3"}><h4>Items<span
									className={"ms-1"}>{binComponents.length}</span>
								</h4></div>
								{visible && <div className={"float-end"}><h4>Page <span
									className={"ms-1"}>{currentPage + 1}</span></h4>
								</div>}
							</div>
							{!active && binComponents.length > 0 && <>
								<div className={"table-header row mx-0"}>
									<div className={"serial-number"}><h4>
										{item.isComposite ? "Component Assembly Id" : "Component Custom Id Number"}
									</h4></div>
									<div className={"spare-parts-time"}>
										<h4>Days / Jumps</h4>
									</div>
								</div>
								<div className={"table-body"}>
									{map(binComponents, renderItem)}
								</div>
							</>}
							{!active && binComponents.length <= 0 && <ListEmptyComponent />}
							{active && <LoadingComponent />}
							{visible && <div className={"table-pagination"}>
								<div className={"float-start"}>
									{totalPages > 1 && <>
										<span>
											<BsArrowLeft color={"#687C97"} size={16} />
										</span>
										<label>Prev</label>
									</>}
								</div>
								<div className={"float-end"}>
									{totalPages > 1 && <>
										<label>Next</label>
										<span>
											<BsArrowRight color={"#687C97"} size={16} />
										</span>
									</>}
								</div>
								<div className={"clearfix"} />
								<div className={"d-flex justify-content-center"}>
									{
										map(pages, (item, index) => (
											<span className={index === currentPage ?
												"active" :
												""}>{index + 1}</span>
										))
									}
								</div>
							</div>}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default BinDetail;
