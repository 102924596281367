import {
	SET_ACTIVE,
	SET_PAGE_SIZE,
	SET_CURRENT_PAGE,
	SET_TOTAL_PAGES,
	SET_ANALYTICS,
} from "../actions/analyticsActions";

const initialState = {
	analytics: [],
	pageSize: 0,
	currentPage: 0,
	totalPages: 0,
	active: false,
};

export default function analyticsReducer(state = initialState, action) {
	switch (action.type) {
		case SET_ACTIVE:
			return {
				...state,
				active: action.payload,
			};
		case SET_ANALYTICS:
			return {
				...state,
				analytics: action.payload,
			};
		case SET_PAGE_SIZE:
			return {
				...state,
				pageSize: action.payload,
			};
		case SET_CURRENT_PAGE:
			return {
				...state,
				currentPage: action.payload,
			};
		case SET_TOTAL_PAGES:
			return {
				...state,
				totalPages: action.payload,
			};
		default:
			return state;
	}
}