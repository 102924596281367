import { get } from "../../helpers/baseAPI";
import popupHelper from "../../helpers/popupHelper";
import { errors } from "../../config/errors";
import imageResolver from "../../helpers/imageResolver";

export const SET_BIN_TYPES = "SET_BIN_TYPES";
export const SET_ACTIVE = "SET_ACTIVE";
export const SET_BIN_COMPONENTS = "SET_BIN_COMPONENTS";
export const SET_COMPONENT_TYPE = "SET_COMPONENT_TYPE";
export const SET_TOTAL_PAGES = "SET_TOTAL_PAGES";
export const SET_CURRENT_PAGE = "SET_CURRENT_PAGE";

const setActive = () => (dispatch) => {
	dispatch({
		type: SET_ACTIVE,
		payload: true,
	});
};

const setInactive = () => (dispatch) => {
	dispatch({
		type: SET_ACTIVE,
		payload: false,
	});
};

const setBinTypes = ({ binTypes, parachuteBinTypes, compositeComponentresponse }) => (dispatch) => {

	const obj = {};

	Object.keys(binTypes).forEach(binTypeKey => {
		obj[binTypeKey] = {
			displayName: binTypes[binTypeKey],
			isParachute: false,
			isComposite: false
		}
	})

	Object.keys(parachuteBinTypes).forEach(binTypeKey => {
		obj[binTypeKey] = {
			displayName: parachuteBinTypes[binTypeKey],
			isParachute: true,
			isComposite: false
		}
	})

	Object.keys(compositeComponentresponse).forEach(binTypeKey => {
		obj[binTypeKey] = {
			displayName: compositeComponentresponse[binTypeKey],
			isParachute: false,
			isComposite: true
		}
	})

	dispatch({
		type: SET_BIN_TYPES,
		payload: obj,
	});
};

const setBinComponents = ({ binComponents }) => (dispatch) => {
	dispatch({
		type: SET_BIN_COMPONENTS,
		payload: binComponents,
	});
};

const setComponentType = ({ componentType }) => (dispatch) => {
	dispatch({
		type: SET_COMPONENT_TYPE,
		payload: componentType,
	});
};

const setTotalPages = ({ totalPages }) => (dispatch) => {
	dispatch({
		type: SET_TOTAL_PAGES,
		payload: totalPages,
	});
};

const setCurrentPage = ({ currentPage }) => (dispatch) => {
	dispatch({
		type: SET_CURRENT_PAGE,
		payload: currentPage,
	});
};

const loadBinComponents = ({ componentType, isParachute, isComposite }) => async (dispatch) => {
	dispatch(setActive());
	let endPoint = ""
	if (isParachute)
		endPoint = `parachute/bin/${componentType}`
	else if (isComposite)
		endPoint = `component/composite/bin/${componentType}`;
	else
		endPoint = `component/bin/${componentType}`;
	try {
		const response = await get(endPoint);
		const binComponents = await response.json();

		if (isComposite) {
			dispatch(setBinComponents({
				binComponents: binComponents.results.map(binComponent => ({
					componentType: binComponent.compositeComponentTypeEnum,
					requiredNumberOfSubComponents: 0,
					imageUrl: imageResolver({ componentType: 'ASSEMBLY' }),
					customID: binComponent.assemblyId,
					isComposite: true
				}))
			}));
		} else {
			dispatch(setBinComponents({ binComponents: binComponents.results }));
		}
		dispatch(setTotalPages({ totalPages: binComponents.totalPages }));
		dispatch(setCurrentPage({ currentPage: binComponents.currentPage }));
	} catch (e) {
		popupHelper({ dispatch, error: true, message: errors.connectionError });
	}
	dispatch(setInactive());
};

const loadComponentBinTypes = async (dispatch) => {
	try {
		const response = await get(`component/bin/types`);
		const binTypes = await response.json();
		return binTypes;
	} catch (e) {
		popupHelper({ dispatch, error: true, message: errors.connectionError });
		return {}
	}
}

const loadParachuteBinTypes = async (dispatch) => {
	try {
		const response = await get(`parachute/bin/types`);
		const binTypes = await response.json();
		return binTypes;
	} catch (e) {
		popupHelper({ dispatch, error: true, message: errors.connectionError });
		return {}
	}
}

const loadAssemblyBinTypes = async (dispatch) => {
	try {
		const response = await get(`component/composite/bin/types`);
		const binTypes = await response.json();
		return binTypes;
	} catch (e) {
		popupHelper({ dispatch, error: true, message: errors.connectionError });
		return {}
	}
}


const loadBinTypes = () => async (dispatch) => {
	dispatch(setActive());
	const binTypes = await loadComponentBinTypes(dispatch);
	const parachuteBinTypes = await loadParachuteBinTypes(dispatch);
	const compositeComponentresponse = await loadAssemblyBinTypes(dispatch)
	dispatch(setBinTypes({ binTypes, parachuteBinTypes, compositeComponentresponse }));
	dispatch(setInactive());
};

export { loadBinTypes, loadBinComponents, setComponentType };
