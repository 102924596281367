import React from "react";
import { useHistory } from "react-router-dom";
import "../styles/reportsStyles.scss"
import { AiFillRightCircle } from "react-icons/ai";

export default function Reports() {

	const history = useHistory()

	const ReportTab = ({ name, onClick }) => {
		return (
			<div className="report-type-box" onClick={onClick}>
				<div>
					{name}
				</div>
				<AiFillRightCircle className="" />
			</div>
		)
	}

	const handleOnNumberOfPacksByUserClick = () => {
		history.push("/reports/number-of-packs",
			{
				pageTitle: "Reports > Number of Packs By User",
				searchEnabled: false,
				panelBackgroundHidden: false,
				breadCrumbSet: [],
			})
	}

	const handleOnReserveParachuteExpiryDatesClick = () => {
		history.push("/reports/reserve-parachute-expiry-dates",
			{
				pageTitle: "Reports > Reserve Parachute Expiry Dates",
				searchEnabled: false,
				panelBackgroundHidden: false,
				breadCrumbSet: [],
			})
	}

	return (
		<div>
			<ReportTab name="Number of Packs By User" onClick={handleOnNumberOfPacksByUserClick} />
			<ReportTab name="Reserve Parachute Expiry Dates" onClick={handleOnReserveParachuteExpiryDatesClick} />
		</div>
	);
}
