import { get, put } from "../../helpers/baseAPI";
import popupHelper from "../../helpers/popupHelper";
import { errors } from "../../config/errors";
import { messages } from "../../config/messages";

export const SET_SPARE_TYPES = "SET_SPARE_TYPES";
export const SET_ACTIVE = "SET_ACTIVE";
export const SET_SERVICEABLE = "SET_SERVICEABLE";
export const SET_UNSERVICEABLE = "SET_UNSERVICEABLE";
export const SET_CURRENT_PAGE = "SET_CURRENT_PAGE";
export const SET_TOTAL_PAGES = "SET_TOTAL_PAGES";
export const SET_UNSERVICEABLE_CURRENT_PAGE = "SET_UNSERVICEABLE_CURRENT_PAGE";
export const SET_UNSERVICEABLE_TOTAL_PAGES = "SET_UNSERVICEABLE_TOTAL_PAGES";

const setSparePartTypes = ({ sparePartTypes }) => (dispatch) => {
	dispatch({
		type: SET_SPARE_TYPES,
		payload: sparePartTypes,
	});
};

const setActive = () => (dispatch) => {
	dispatch({
		type: SET_ACTIVE,
		payload: true,
	});
};

const setInactive = () => (dispatch) => {
	dispatch({
		type: SET_ACTIVE,
		payload: false,
	});
};

const setServiceable = ({ serviceable }) => (dispatch) => {
	dispatch({
		type: SET_SERVICEABLE,
		payload: serviceable,
	});
};

const setUnserviceable = ({ unserviceable }) => (dispatch) => {
	dispatch({
		type: SET_UNSERVICEABLE,
		payload: unserviceable,
	});
};

const setTotalPages = ({ totalPages }) => (dispatch) => {
	dispatch({
		type: SET_TOTAL_PAGES,
		payload: totalPages,
	});
};

const setCurrentPage = ({ currentPage }) => (dispatch) => {
	dispatch({
		type: SET_CURRENT_PAGE,
		payload: currentPage,
	});
};

const setUnserviceableTotalPages = ({ totalPages }) => (dispatch) => {
	dispatch({
		type: SET_UNSERVICEABLE_TOTAL_PAGES,
		payload: totalPages,
	});
};

const setUnserviceableCurrentPage = ({ currentPage }) => (dispatch) => {
	dispatch({
		type: SET_UNSERVICEABLE_CURRENT_PAGE,
		payload: currentPage,
	});
};

const loadSparePartTypes = () => async (dispatch) => {
	dispatch(setActive());
	const endPoint = `component/available/types`;
	try {
		const response = await get(endPoint);
		const sparePartTypes = await response.json();
		dispatch(setSparePartTypes({ sparePartTypes }));
	} catch (e) {
		popupHelper({ dispatch, error: true, message: errors.connectionError });
	}
	dispatch(setInactive());
};

const loadCompositeSparePartTypes = () => async (dispatch) => {
	dispatch(setActive());
	const endPoint = `component/available/composite/types`;
	try {
		const response = await get(endPoint);
		const data = await response.json();
		const sparePartTypes = {}

		data.forEach(item=>{
			sparePartTypes[item.componentTypeEnum] = item.name
		})

		dispatch(setSparePartTypes({ sparePartTypes }));
	} catch (e) {
		popupHelper({ dispatch, error: true, message: errors.connectionError });
	}
	dispatch(setInactive());
};

const loadServiceableSpares = ({ componentType }) => async (dispatch) => {
	dispatch(setActive());
	const endPoint = `component/serviceable/${ componentType }`;
	try {
		const response = await get(endPoint);
		const serviceable = await response.json();
		dispatch(setServiceable({ serviceable: serviceable.results }));
		dispatch(setTotalPages({ totalPages: serviceable.totalPages }));
		dispatch(setCurrentPage({ currentPage: serviceable.currentPage }));
	} catch (e) {
		popupHelper({ dispatch, error: true, message: errors.connectionError });
	}
	dispatch(setInactive());
};

const loadCompositeServiceableSpares = ({ componentType }) => async (dispatch) => {
	dispatch(setActive());
	const endPoint = `component/composite/serviceable/${ componentType }`;
	try {
		const response = await get(endPoint);
		const serviceable = await response.json();
		dispatch(setServiceable({ serviceable: serviceable.results }));
		dispatch(setTotalPages({ totalPages: serviceable.totalPages }));
		dispatch(setCurrentPage({ currentPage: serviceable.currentPage }));
	} catch (e) {
		popupHelper({ dispatch, error: true, message: errors.connectionError });
	}
	dispatch(setInactive());
};

const loadUnserviceableSpares = ({ componentType }) => async (dispatch) => {
	dispatch(setActive());
	const endPoint = `component/unserviceable/${ componentType }`;
	try {
		const response = await get(endPoint);
		const unserviceable = await response.json();
		dispatch(setUnserviceable({ unserviceable: unserviceable.results }));
		dispatch(setUnserviceableTotalPages({ totalPages: unserviceable.totalPages }));
		dispatch(setUnserviceableCurrentPage({ currentPage: unserviceable.currentPage }));
	} catch (e) {
		popupHelper({ dispatch, error: true, message: errors.connectionError });
	}
	dispatch(setInactive());
};

const loadCompositeUnserviceableSpares = ({ componentType }) => async (dispatch) => {
	dispatch(setActive());
	const endPoint = `component/composite/unserviceable/${ componentType }`;
	try {
		const response = await get(endPoint);
		const unserviceable = await response.json();
		dispatch(setUnserviceable({ unserviceable: unserviceable.results }));
		dispatch(setUnserviceableTotalPages({ totalPages: unserviceable.totalPages }));
		dispatch(setUnserviceableCurrentPage({ currentPage: unserviceable.currentPage }));
	} catch (e) {
		popupHelper({ dispatch, error: true, message: errors.connectionError });
	}
	dispatch(setInactive());
};

const moveToServiceableNormal = ({ componentType, componentID }) => async (dispatch) => {
	dispatch(setActive());
	const endPoint = `component/service/${ componentID }`;
	try {
		const response = await put({ END_POINT: endPoint });
		if (response.error) throw new Error(errors.moveToServiceableError);
		popupHelper({ dispatch, error: false, message: messages.movedToServiceable });
		dispatch(loadUnserviceableSpares({ componentType }));
		dispatch(loadServiceableSpares({ componentType }));
	} catch (e) {
		popupHelper({ dispatch, error: true, message: e.message });
	}
	dispatch(setInactive());
};

const moveToServiceableGroup = ({ componentType, assemblyId }) => async (dispatch) => {
	dispatch(setActive());
	const endPoint = `component/service/composite/${ assemblyId }`;
	try {
		const response = await put({ END_POINT: endPoint });
		if (response.error) throw new Error(errors.moveToServiceableError);
		popupHelper({ dispatch, error: false, message: messages.movedToServiceable });
		dispatch(loadUnserviceableSpares({ componentType }));
		dispatch(loadServiceableSpares({ componentType }));
	} catch (e) {
		popupHelper({ dispatch, error: true, message: e.message });
	}
	dispatch(setInactive());
};

export {
	loadSparePartTypes,
	loadServiceableSpares,
	loadUnserviceableSpares,
	moveToServiceableNormal,
	moveToServiceableGroup,
	setActive,
	loadCompositeSparePartTypes,
	loadCompositeServiceableSpares,
	loadCompositeUnserviceableSpares
};
