import { SET_UPLOADING, SET_IMAGE } from "../actions/imageActions";

const initialState = {
	imageUploading: false,
	imageFile: null,
};

function imageReducer(state = initialState, action) {
	switch (action.type) {
		case SET_UPLOADING :
			return {
				imageUploading: action.payload,
			};
		case SET_IMAGE:
			return {
				...state,
				imageFile: action.payload,
			};
		default:
			return state;
	}
}

export default imageReducer;