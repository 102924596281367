export default function imageResolver({ componentType }) {
	switch (componentType) {
		case "MAIN_CUTAWAY_HANDLE":
			return "https://res.cloudinary.com/riglog/image/upload/t_riglog_app/v1630438033/Rig%20Log/Components/cut_away_handle_wlpwq0.jpg";
		case "RECOIL_RIP_CORD":
			return "https://res.cloudinary.com/riglog/image/upload/t_riglog_app/v1630438048/Rig%20Log/Components/Recoil_Ripcord_gwcz9z.jpg";
		case "DROGUE":
			return "https://res.cloudinary.com/riglog/image/upload/t_riglog_app/v1630438043/Rig%20Log/Components/Drogue_Assembly_v2nfkm.png";
		case "LOWER_BRIDLE":
			return "https://res.cloudinary.com/riglog/image/upload/t_riglog_app/v1630438041/Rig%20Log/Components/Lower_Drogue_Bridle_u2ge3h.jpg";
		case "SPLIT_RSL":
			return "https://res.cloudinary.com/riglog/image/upload/t_riglog_app/v1630438040/Rig%20Log/Components/RSL__xasrvd.jpg";
		case "TANDEM_HARNESS":
			return "https://res.cloudinary.com/riglog/image/upload/t_riglog_app/v1630438038/Rig%20Log/Components/Passenger_Harness_z7slcp.png";
		case "MAIN_RISERS":
			return "https://res.cloudinary.com/riglog/image/upload/t_riglog_app/v1630438034/Rig%20Log/Components/Main_Risers_ol3rsa.jpg";
		case "SIGMA_DISK":
			return "https://res.cloudinary.com/riglog/image/upload/t_riglog_app/v1630438033/Rig%20Log/Components/Sigma_Disc_vcbx6k.jpg";
		case "DROGUE_CENTRE_LINE":
			return "https://res.cloudinary.com/riglog/image/upload/t_riglog_app/v1630438033/Rig%20Log/Components/cut_away_handle_wlpwq0.jpg";
		case "MAIN_DEPLOYMENT_BAG" :
			return "https://res.cloudinary.com/riglog/image/upload/t_riglog_app/v1630438030/Rig%20Log/Components/Main_Deplyment_bag_uvcxbb.jpg";
		case "DISK_COVER" :
			return "https://res.cloudinary.com/riglog/image/upload/t_riglog_app/v1630438028/Rig%20Log/Components/Sigma_Disc_cover_kupcbb.jpg";
		case "SPECTRA_RESERVE_RIPCORD":
			return "https://res.cloudinary.com/riglog/image/upload/t_riglog_app/v1630438025/Rig%20Log/Components/Reserve_Ripcord_zllthp.png";
		case "MAIN_PARACHUTE_LINE_SET":
			return "https://res.cloudinary.com/riglog/image/upload/t_riglog_app/v1630438022/Rig%20Log/Components/Main_Line_Set_zhc0va.png";
		case "MAIN_CANOPY" :
			return "https://res.cloudinary.com/riglog/image/upload/t_riglog_app/v1630438022/Rig%20Log/Components/Main_Canopy_Assembly_aelehs.png";
		case "AUTOMATIC_ACTIVATION_DEVICE" :
			return "https://res.cloudinary.com/riglog/image/upload/v1630438026/Rig%20Log/Components/AAD_gswexc.jpg";
		case "PASSENGER_HARNESS":
			return "https://res.cloudinary.com/riglog/image/upload/v1630438038/Rig%20Log/Components/Passenger_Harness_z7slcp.png";
		case "ASSEMBLY":
			return "https://res.cloudinary.com/riglog/image/upload/t_riglog_app/v1630438022/Rig%20Log/Components/Main_Canopy_Assembly_aelehs.png";
		case "RESERVE_PARACHUTE":
			return "https://res.cloudinary.com/riglog/image/upload/v1711905584/Rig%20Log/Components/Reserve_Parachute_exugvc.png";
		case "MAIN_CLOSING_LOOP":
			return "https://res.cloudinary.com/riglog/image/upload/v1711905584/Rig%20Log/Components/Main_Closing_Loop_hsom6h.jpg";
		default:
			return undefined;
	}
}
