import { get, post, put } from "../../helpers/baseAPI";
import popupHelper from "../../helpers/popupHelper";
import { errors } from "../../config/errors";
import { cloudinaryPost } from "../../helpers/baseAPI";
import { appSettings } from "../../config";

export const SET_COMPANIES = 'SET_COMPANIES';
export const IS_LOADING_COMPANIES = 'IS_LOADING_COMPANIES';
export const CHANGE_ENABILITY = 'CHANGE_ENABILITY';
export const SET_EDITABLE_WINDOW_SHOW = 'SET_EDITABLE_WINDOW_SHOW';


const createCloudinaryPayload = (image) => {
	const data = new FormData();
	data.append("file", image);
	data.append("upload_preset", appSettings.upload_preset);
	data.append("cloud_name", appSettings.cloud_name);
	return data;
}


export const setCompanies = (payload) => (dispatch) => {
    dispatch({
		type: SET_COMPANIES,
		payload,
	});
}

export const setEditableWindowShow = (payload) => (dispatch) => {
    dispatch({
		type: SET_EDITABLE_WINDOW_SHOW,
		payload,
	});
}

export const setIsLoadingCompanies = (payload) => (dispatch) => {
    dispatch({
		type: IS_LOADING_COMPANIES,
		payload,
	});
}

export const setEnability = (id,payload) => (dispatch) => {
    dispatch({
		type: CHANGE_ENABILITY,
		payload,
		id
	});
}

export const fetchCompanies = () => async (dispatch) => {
    dispatch(setIsLoadingCompanies(true))
    const endPoint = `tenant?size=500`;
	try {
		const response = await get(endPoint);
		const companies = await response.json();
		dispatch(setCompanies(companies.results));
	} catch (e) {
		popupHelper({ dispatch, error: true, message: errors.connectionError });
	}
    dispatch(setIsLoadingCompanies(false))
}

export const changeEnability = (id,state) => async (dispatch) => {
    const endPoint = `tenant/${id}/${state ? 'enable' : 'disable'}`;
	try {
		await post({
			END_POINT: endPoint,
			authRequired: true
		});
		dispatch(setEnability(id,state));
	} catch (e) {
		popupHelper({ dispatch, error: true, message: errors.enabilityError });
	}
}

export const saveCompany = ({
	name : companyName,
	email,
	phone,
	address,
	description,
	imageUrl,
	imageChanged,
	adminFullName,
	adminEmail,
	adminProfilePictureURL,
	adminProfilePictureChanged,
	tenatId
}, isEdit) => async (dispatch) => {

	let body = {
		name: companyName,
		email,
		phone,
		address,
		description,
		imageUrl,
		admin: {
			fullName: adminFullName,
			email: adminEmail,
			profilePictureURL: adminProfilePictureURL
		}
	}

	dispatch(setIsLoadingCompanies(true))

	let response = null;

	if(imageChanged){
		response = await cloudinaryPost(createCloudinaryPayload(imageUrl));
		if (response.error){
			popupHelper({dispatch, error:true, message: 'Image Uploading Error'})
			dispatch(setIsLoadingCompanies(false))
			return;
		}else{
			body = {
				...body,
				imageUrl: response.secure_url
			}
		}
	}

	if(adminProfilePictureChanged){
		response = await cloudinaryPost(createCloudinaryPayload(adminProfilePictureURL));
		if (response.error){
			popupHelper({dispatch, error:true, message: 'Image Uploading Error'})
			dispatch(setIsLoadingCompanies(false))
			return;
		}else{
			body = {
				...body,
				admin: {
					...body.admin,
					profilePictureURL: response.secure_url
				}
			}
		}
	}
	try {
		if(!isEdit){
			await post({
				END_POINT: 'tenant',
				authRequired: true,
				body
			});
		}else{
			await put({
				END_POINT: `tenant/${tenatId}`,
				body
			});
		}
		
	} catch (e) {
		popupHelper({ dispatch, error: true, message: errors.companySaveFailed });
		dispatch(setIsLoadingCompanies(false))
		return;
	}

	popupHelper({ dispatch, error: false, message: 'Company Successfully Saved' });
	dispatch(fetchCompanies());
	dispatch(setEditableWindowShow(false))
}