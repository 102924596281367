import { get, post, put } from "../../helpers/baseAPI";
import popupHelper from "../../helpers/popupHelper";
import { errors } from "../../config/errors";

export const SET_ACTIVE = "SET_ACTIVE";
export const SET_USERS = "SET_USERS";
export const SET_SEARCH_KEY = "SET_SEARCH_KEY";
export const SET_SELECTED_USER = "SET_SELECTED_USER";

const setActive = () => (dispatch) => {
	dispatch({
		type: SET_ACTIVE,
		payload: true,
	});
};

const setInactive = () => (dispatch) => {
	dispatch({
		type: SET_ACTIVE,
		payload: false,
	});
};

const setUsers = ({ users }) => (dispatch) => {
	dispatch({
		type: SET_USERS,
		payload: users,
	});
};

const setUserSearchKey = ({ key }) => (dispatch) => {
	dispatch({
		type: SET_SEARCH_KEY,
		payload: key,
	});
};

const setSelectedUser = ({ user }) => (dispatch) => {
	dispatch({
		type: SET_SELECTED_USER,
		payload: user,
	});
};

const loadUsers = ( params ) => async (dispatch) => {
	dispatch(setActive());
	const endPoint = `users${ params?.mandatoryFieldsOnly ? '?mandatoryFieldsOnly=True' : ''}`;
	try {
		const response = await get(endPoint);
		if (response.error) throw new Error(errors.fetch_get);
		const users = await response.json();
		dispatch(setUsers({ users }));
	} catch (e) {
		popupHelper({ dispatch, error: true, message: errors.connectionError });
	}
	dispatch(setInactive());
};

const loadUsersSilent = () => async (dispatch) => {
	const endPoint = `users`;
	try {
		const response = await get(endPoint);
		if (response.error) throw new Error(errors.fetch_get);
		const users = await response.json();
		dispatch(setUsers({ users }));
	} catch (e) {
		popupHelper({ dispatch, error: true, message: errors.connectionError });
	}
};

const blockUser = ({ email, userID }) => async (dispatch) => {
	const endPoint = `users/${ userID }`;
	const body = {
		email,
		isLocked: true,
	};
	try {
		const response = await put({ END_POINT: endPoint, body });
		if (response.error) throw new Error(errors.fetch_put);
		dispatch(loadUsersSilent());
	} catch (e) {
		popupHelper({ dispatch, error: true, message: errors.connectionError });
	}
};

const unblockUser = ({ email, userID }) => async (dispatch) => {
	const endPoint = `users/${ userID }`;
	const body = {
		email,
		isLocked: false,
	};
	try {
		const response = await put({ END_POINT: endPoint, body });
		if (response.error) throw new Error(errors.fetch_put);
		dispatch(loadUsersSilent());
	} catch (e) {
		popupHelper({ dispatch, error: true, message: errors.connectionError });
	}
};

const addUser = ({ fullName, email, profilePictureURL, roleId }) => async (dispatch) => {
	const endPoint = `users/register`;
	const body = {
		fullName,
		email,
		profilePictureURL,
		roleId: roleId ? roleId : 2,
	};
	try {
		const response = await post({ END_POINT: endPoint, body, authRequired: true });
		if (response.error) throw new Error(errors.fetch_post);
		dispatch(loadUsersSilent());
	} catch (e) {
		popupHelper({ dispatch, error: true, message: errors.connectionError });
	}
};

export { loadUsers, blockUser, unblockUser, setUserSearchKey, addUser, setSelectedUser };
