import {
	SET_SPARE_TYPES,
	SET_ACTIVE,
	SET_SERVICEABLE,
	SET_UNSERVICEABLE,
	SET_CURRENT_PAGE,
	SET_TOTAL_PAGES,
	SET_UNSERVICEABLE_TOTAL_PAGES,
	SET_UNSERVICEABLE_CURRENT_PAGE,
} from "../actions/sparesActions";

const initialState = {
	active: false,
	sparePartTypes: null,
	serviceable: [],
	unserviceable: [],
	totalPages: 1,
	currentPage: 0,
	totalUnserviceablePages: 1,
	currentUnserviceablePage: 0,
};

export default function sparesReducer(state = initialState, action) {
	switch (action.type) {
		case SET_ACTIVE :
			return {
				...state,
				active: action.payload,
			};
		case SET_SPARE_TYPES:
			return {
				...state,
				sparePartTypes: action.payload,
			};
		case SET_SERVICEABLE:
			return {
				...state,
				serviceable: action.payload,
			};
		case SET_UNSERVICEABLE:
			return {
				...state,
				unserviceable: action.payload,
			};
		case SET_TOTAL_PAGES:
			return {
				...state,
				totalPages: action.payload,
			};
		case SET_CURRENT_PAGE:
			return {
				...state,
				currentPage: action.payload,
			};
		case SET_UNSERVICEABLE_TOTAL_PAGES:
			return {
				...state,
				totalUnserviceablePages: action.payload,
			};
		case SET_UNSERVICEABLE_CURRENT_PAGE:
			return {
				...state,
				currentUnserviceablePage: action.payload,
			};
		default:
			return state;
	}
}