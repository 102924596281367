import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { GoPlus } from "react-icons/go";
import LoadingComponent from "../components/LoadingComponent";
import { fetchCompanies, changeEnability, setEditableWindowShow, saveCompany } from '../redux/actions/companyActions';
import CompanyCard from "../components/CompanyCard";
import ListEmptyComponent from '../components/ListEmptyComponent';
import CompanyGridHeader from "../components/CompanyGridHeader";
import AddCompanyCard from "../components/AddCompanyCard";

const Companies = () => {

	const dispatch = useDispatch();
	const { isLoadingCompanies, companies, showEditableWindow } = useSelector(state => state.companyReducer)
	const { authenticated } = useSelector(state => state.authReducer)
	const [selectedCompany, setSelectedCompany] = useState(null);
	const [isEdit, setIsEdit] = useState(false);
	
	useEffect(() => {
		authenticated && dispatch(fetchCompanies())
	}, [dispatch, authenticated]);

	const handleChange = (id, state) => dispatch(changeEnability(id, !state))

	const handleOnEdit = (company) => {
		setSelectedCompany(company)
		setIsEdit(true)
		if(company){
			dispatch(setEditableWindowShow(true))
		}
	}

	const renderCompanyList = () => {
		if (companies && companies.length > 0) {
			return companies.map(
				(company, index) =>
					<CompanyCard
						onChange={handleChange}
						onEdit={handleOnEdit}
						company={company}
						key={index}
					/>)
		} else {
			return <ListEmptyComponent />
		}
	}

	const handleOnClickAdd = (value) => {
		setIsEdit(false)
		setSelectedCompany(null)
		dispatch(setEditableWindowShow(value))
	}

	const renderActionBar = () => (
		<div className="row flex-row">
			<div className="col d-flex justify-content-end p-2">
				<button type="button" className="btn btn-danger" onClick={()=>handleOnClickAdd(true)}>
					<span className={"me-3"}><GoPlus /></span> Add
				</button>
			</div>
		</div>
	)

	const handleOnSave = (data, isEdit) => {
		dispatch(saveCompany(data, isEdit));
	}

	return (
		<div className="row w-auto mt-3">
			{isLoadingCompanies && <LoadingComponent />}
			{!isLoadingCompanies && !showEditableWindow && renderActionBar()}
			{!isLoadingCompanies && !showEditableWindow && <CompanyGridHeader />}
			{!isLoadingCompanies && !showEditableWindow && renderCompanyList()}
			{!isLoadingCompanies && showEditableWindow && <AddCompanyCard company={selectedCompany} onSave={handleOnSave} onCancel={()=>handleOnClickAdd(false)} isEdit={isEdit}/>}

		</div>
	);
};

export default Companies;