import logo from "../assets/svg/parachute-logo.svg";
import { BsArrowRightShort } from "react-icons/bs";
import "./styles/paraTypeCardStyles.scss";

const SparePartsCard = ({ handleClick, title, thumbnail = logo }) => {
	return (
		<div onClick={ handleClick }
		     className="float-start w-auto me-4 mb-4">
			<div className="item-card card float-start">
				<div className={ "card-body" }>
					<div
						className={ "item-img text-center" }>
						<img
							className={ "text-center" }
							src={ thumbnail }
							alt={ "rig" }/>
					</div>
				</div>
				<div
					className={ `card-footer d-flex` }>
					<label>{ title }</label>
					<div
						className={ "d-flex align-items-end flex-column bd-highlight" }>
								<span
									className={ "mt-auto bd-highlight" }><BsArrowRightShort
									color={ "#F74A63" }
									size={ "24" }/></span></div>
				</div>
			</div>
		</div>
	);
};

export default SparePartsCard;