import React, { useEffect, useState } from "react";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ListEmptyComponent from "../components/ListEmptyComponent";
import LoadingComponent from "../components/LoadingComponent";
import { blockUser, loadUsers, setUserSearchKey, unblockUser } from "../redux/actions/userActions";
import { map } from "lodash/collection";

const Users = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const [displayUsers, setDisplayUsers] = useState([]);

	const { users, active, searchKey } = useSelector(state => state.userReducer);

	const visible = false;

	useEffect(() => {
		dispatch(loadUsers());
		dispatch(setUserSearchKey({ key: "" }));
	}, [dispatch]);

	useEffect(() => {
		setDisplayUsers(users);
	}, [users]);

	useEffect(() => {
		const searchResult = users.filter((item) => {
			if (item.fullName.toLowerCase().includes(searchKey.toLowerCase()) ||
				item.email.toLowerCase().includes(searchKey.toLowerCase()))
				return true;
			else return false;
		});
		if (searchResult) setDisplayUsers(searchResult);
		else setDisplayUsers([]);
	}, [searchKey, users]);

	const handleUserClick = (item) => {
		history.push("/user-history", {
			pageTitle: item.fullName,
			userID: item.id,
			searchEnabled: false,
			activeTab: 4,
			infoPaneEnabled: false,
			breadCrumbSet: [
				{
					title: "Users",
					to: "back",
				},
				{
					title: item.fullName,
				},
			],
		});
	};

	const resolveRole = (roles) => {
		let role = "";
		if (roles.id === 1) {
			role = "Super Admin"
		}else if (roles.id === 2) {
			role = "Admin"
		} else if (roles.id === 4) {
			role = "Service"
		} else {
			role = "Packer";
		}
		return role;
	}

	const handleToggle = (event, item) => {
		if (item.isLocked) {
			dispatch(unblockUser({ email: item.email, userID: item.id }));
		} else {
			dispatch(blockUser({ email: item.email, userID: item.id }));
		}
	};

	if (!active && users.length <= 0) return <ListEmptyComponent/>;
	if (active) return <LoadingComponent/>;
	return (
		<div className={ "user-page" }>
			<div className={ "user-table" }>
				<div className={ "table-header-top" }>
					<div className={ "float-start" }><h2>User List</h2></div>
					<div className={ "float-end ms-4 ps-3" }><h4>Users <span
						className={ "ms-1" }>{ users.length }</span>
					</h4></div>
					{ visible && <div className={ "float-end" }><h4>Page <span
						className={ "ms-1" }>01</span></h4>
					</div> }
				</div>
				<div className={ "table-header row mx-0" }>
					<div className={ "uno" }><h4>U.No</h4></div>
					<div className={ "user-name" }><h4>Username</h4></div>
					<div className={ "email" }><h4>Email</h4></div>
					<div className={ "role" }><h4>Role</h4></div>
					<div className={ "action" }><h4>Block Status</h4>
					</div>
				</div>
				<div className={ "table-body" }>
					{ map(displayUsers, (item, key) => {
						return <div className={ "row mx-0 user-list" } key={ key }>
							<div className={ "uno" }><h4>{ item.id }</h4></div>
							<div className={ "user-name" }
							     onClick={ () => handleUserClick(item) }><span
								className={ "float-start d-inline-flex rounded-circle " }>
								<div style={ {
									display: "flex",
									flexDirection: "row",
									justifyContent: "center",
									alignItems: "center",
									width: "100%",
									height: 32,
									overflow: "hidden",
								} }>
								<img className={ "profile-picture" } src={ item.profilePictureURL }
								     alt={ "profile" }/>
								</div>
							</span>
								<h4>{ item.fullName }</h4>
							</div>
							<div className={ "email" }><h4>{ item.email }</h4></div>
							<div className={ "role" }><h4>{ resolveRole(item.role) }</h4></div>
							<div className={ "action" }>
								<div className="form-check form-switch">
									<label className="form-check-label"
									       htmlFor="userBlockUnblock"/>
									<input className="form-check-input" type="checkbox"
									       checked={ item.isLocked }
									       onChange={ (event) => handleToggle(event, item) }
									       id="userBlockUnblock"/>

								</div>
							</div>
						</div>;
					}) }
				</div>
				{ visible && <div className={ "table-pagination" }>
					<div className={ "float-start" }>
						<span><BsArrowLeft
							color={ "#687C97" }
							size={ 16 }/></span><label>Prev</label></div>
					<div className={ "float-end" }>
						<label>Next</label><span><BsArrowRight
						color={ "#687C97" }
						size={ 16 }/></span>
					</div>
					<div className={ "clearfix" }/>
					<div className={ "d-flex justify-content-center" }>
						<span
							className={ "active" }>1</span><span>2</span><span>3</span>
					</div>
				</div> }
			</div>
		</div>
	);
};

export default Users;
