import React from 'react';
import "./styles/companyGridHeaderStyles.scss";

const CompanyGridHeader = () => (
    <div className="flex-row justify-content-center">
        <div className="row pb-2 bg-white">
            <div className="col-md-2 d-flex justify-content-center company-grid-header-text max-width-70">
                #
            </div>
            <div className="col-md-2 d-flex justify-content-flex-start company-grid-header-text max-width-32">
                Company Name
            </div>
            <div className="col-md-2 d-flex justify-content-flex-start company-grid-header-text max-width-26">
                Admin Name
            </div>
            <div className="col-md-2 d-flex justify-content-flex-start company-grid-header-text max-width-15">
                Updated On
            </div>
            <div className="col-md-2 d-flex justify-content-center company-grid-header-text max-width-10">
                Disable
            </div>
            <div className="col-md-2 d-flex justify-content-center company-grid-header-text max-width-10">
                Actions
            </div>
        </div>
    </div>
)

export default CompanyGridHeader;