import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from 'react-redux';
import { loadSettings, updateConfigs, saveSettings } from '../redux/actions/settingsActions';
import LoadingComponent from "../components/LoadingComponent";
import "../styles/settingsStyles.scss"

import ConfigPanel from "../components/Settings/ConfigPanel";

export default function Settings() {

	const [activeTab , setActiveTab] = useState(0);
	const [isDisabledSaveButton , setDisabledSaveButton] = useState(false);
	const dispatch = useDispatch();

	const { isLoading, configs = [] } = useSelector(state => state.settingsReducer);
    
	useEffect(() => {
		dispatch(loadSettings());
	}, [dispatch]);

	const onHandleTabClick = (i) =>{
		setActiveTab(i)
	}

	if(isLoading){
		return <LoadingComponent/>
	}

	const handleOnUpdate = (data, idHeirarchy) =>{
		dispatch(updateConfigs(data, idHeirarchy))
	}

	const handleOnDisableSaveButton = (isDisabled) => {
		setDisabledSaveButton(isDisabled)
	}

	const handleOnSave = () =>{
		dispatch(saveSettings());
	}

	if (isLoading) return <LoadingComponent/>;
	return (
		<div>
			<div >
				<ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
					{
						configs.map((e,i) =>
							<li class="nav-item" role="presentation">
								<button 
									class={`nav-link`}
									className={`tab-button ${i==activeTab ? 'settings-tab-selected' : ''}`} 
									id={`${e.equipmentName}-tab`}
									type="button" 
									role="tab" 
									aria-controls="pills-home" 
									aria-selected="false"
									onClick={()=>onHandleTabClick(i)}
								>
									{e.name}
								</button>
							</li>
						)
					}
				</ul>
			</div>
			<div className="tab-pane-wrapper">
				<div class="tab-content" id="v-pills-tabContent">
					{
						configs.map((e,i) =>
							<div 
								class={`tab-pane fade ${i===activeTab ? 'show active':''}`} 
								id={`${e.equipmentName}-tab-pane`}
								role="tabpanel" 
								aria-labelledby={`${e.equipmentName}-tab`}
							>
								<ConfigPanel config={e} onUpdate={handleOnUpdate}  shouldDisableSaveButton={handleOnDisableSaveButton}/>
							</div>
						)
					}
				</div>
			</div>
			<div className="action-wrapper">
				<button disabled={isDisabledSaveButton} type="button" class="btn btn-danger" onClick={handleOnSave}>
					Save
				</button>
			</div>
		</div>
	);
}
