import React, { useState } from 'react';
import { isEmpty } from 'lodash';
import './styles/dropdown.scss';

const DropDown = ({ items, defaultSelect, itemClick, selectedItem}) => {
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(!open);
    };

    const handldItemclick = (data) => {
        setOpen(false);
        itemClick && itemClick(data)   
    }

    return (
        <div className="component-dropdown">
            <button className="btn btn-outline-secondary dropdown-toggle" id="btn-select" onClick={handleOpen}>{isEmpty(selectedItem) ? defaultSelect : selectedItem.name}</button>
            {open ? (
                <ul className="menu">
                    {
                        items?.map(item => (
                            <li className="menu-item">
                                <button key={item.name} onClick={() => handldItemclick(item)}>{item.name}</button>
                            </li>
                        ))
                    }
                </ul>
            ) : null}
        </div>
    );
};

export default DropDown;