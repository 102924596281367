import logo from "../assets/svg/parachute-logo.svg";
import store from "../assets/svg/shop.svg";
import shopping from "../assets/svg/shopping-cart.svg";
import trash from "../assets/svg/bin.svg";
import users from "../assets/svg/users.svg";
import gear from "../assets/svg/gear.svg";
import reports from "../assets/svg/reports.svg";
import { GiPowerButton } from "react-icons/gi";
import { AiOutlineClose, AiOutlineExclamationCircle, AiOutlineMenu } from "react-icons/ai";
import { RiCloseLine } from "react-icons/ri";
import { GoPlus } from "react-icons/go";
import { FiSearch } from "react-icons/fi";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { map } from "lodash/collection";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../redux/actions/authActions";
import { toast, ToastContainer } from "react-toastify";
import { hidePopup } from "../redux/actions/popupActions";
import { setSearchText } from "../redux/actions/searchActions";
import { Modal } from "reactstrap";
import { BsFillFolderFill } from "react-icons/bs";
import { addUser, setUserSearchKey } from "../redux/actions/userActions";
import * as yup from "yup";
import { useFormik } from "formik";
import { setImage, startUploading } from "../redux/actions/imageActions";
import {ROLES} from '../config/constants';

const Main = ({ children }) => {
	const dispatch = useDispatch();
	const { authenticated, isSuperUserLogged, roles } = useSelector(
		state => state.authReducer);
	const { warningCount } = useSelector(state => state.parachuteReducer);
	const { statusText } = useSelector(state => state.statusReducer);
	const { searchKey } = useSelector(state => state.userReducer);
	const location = useLocation();
	const history = useHistory();
	const [menuVisible, setMenuVisible] = useState(true);
	const [breadCrumbs, setBreadCrumbs] = useState([]);
	const { popupVisible, popupText, popupError } = useSelector(
		state => state.popupReducer);
	const [addUserModal, setAddUserModal] = useState(false);
	const { imageFile, imageUploading } = useSelector(state => state.imageReducer);
	const inputFile = useRef(null);
	const [selectedRole, setSelectedRole] = useState();
	const isTenantAdmin = roles.name === ROLES.TENANT_ADMIN_ROLE;

	const toggleAddUser = () => setAddUserModal(!addUserModal);

	if(!location.state){
		history.push("/login")
	}

	const {
		pageTitle,
		searchEnabled,
		activeTab,
		infoPaneEnabled,
		panelBackgroundHidden,
		breadCrumbSet,
	} = location.state;

	useEffect(() => {
		if (popupVisible && popupError) toast.error(popupText, {
			position: "top-right",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			onClose: dispatch(hidePopup()),
		});
		if (popupVisible && !popupError) toast.success(popupText, {
			position: "top-right",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			onClose: dispatch(hidePopup()),
		});
	}, [popupVisible, popupError, popupText, dispatch]);

	useEffect(() => {
		if (breadCrumbSet)
			setBreadCrumbs(breadCrumbSet);
		else setBreadCrumbs([]);
	}, [breadCrumbSet, pageTitle]);

	useEffect(() => {
		if (!authenticated)
			history.push("/");
	}, [authenticated, history]);

	const handleLogout = () => {
		dispatch(logout());
	};

	const handleRigRackClick = () => {
		history.push("/rigRack", {
			pageTitle: "Rig Rack",
			searchEnabled: false,
			activeTab: 1,
			infoPaneEnabled: true,
		});
	};

	const handleCompaniesClick = () => {
		history.push("/companies", {
			pageTitle: "Companies",
			searchEnabled: false,
			activeTab: 1,
			infoPaneEnabled: true,
		});
	};

	const handleSparePartsClick = () => {
		history.push("/spareParts", {
			pageTitle: "Spare Parts",
			searchEnabled: false,
			activeTab: 2,
			infoPaneEnabled: true,
		});
	};

	const handleBinClick = () => {
		history.push("/bin", {
			pageTitle: "Bin",
			searchEnabled: false,
			activeTab: 3,
			infoPaneEnabled: true,
		});
	};

	const handleUsersClick = () => {
		history.push("/users", {
			pageTitle: "Users",
			searchEnabled: false,
			activeTab: 4,
		});
	};

	const handleReportsClick = () => {
		history.push("/reports", {
			pageTitle: "Reports",
			searchEnabled: false,
			activeTab: 6,
		});
	};
	
	const handleSettingsClick = () => {
		history.push("/settings", {
			pageTitle: "Settings",
			searchEnabled: false,
			activeTab: 5,
		});
	};

	const handleMenuClick = () => {
		setMenuVisible(!menuVisible);
	};

	const handleBreadCrumbClick = (item) => {
		if (item.to === "/rigRack") {
			history.push({
				pathname: "/rigRack",
				state: {
					pageTitle: "Rig Rack",
					activeTab: 1,
					infoPaneEnabled: true,
					searchEnabled: false,
				},
			});
		}
		if (item.to === "/bin") {
			history.push({
				pathname: "/bin",
				state: {
					pageTitle: "Bin",
					searchEnabled: false,
					activeTab: 3,
					infoPaneEnabled: true,
				},
			});
		}
		if (item.to === "back") {
			history.goBack();
		}
	};

	const BreadCrumbs = () => {
		if (breadCrumbs.length <= 1) return null;
		else
			return (
				<>
					{ map(breadCrumbs, (item, index) => (
						<li key={ index }
						    className={ `breadcrumb-item` }
						    onClick={ () => handleBreadCrumbClick(item) }>
							{ item.title }
						</li>
					)) }
				</>
			);
	};

	const handleSearch = (e) => {
		dispatch(setSearchText({ text: e.target.value }));
	};

	function handleUserSearchKeyChange(e) {
		dispatch(setUserSearchKey({ key: e.target.value }));
	}

	const validationSchema = yup.object().shape({
		fullName: yup.string().required("Name is required"),
		email: yup.string().email().required("Valid email address is required"),
	});

	const formik = useFormik({
		initialValues: {
			fullName: "",
			email: "",
		},
		validationSchema,
		validateOnMount: true,
	});

	function handleImageBrowser() {
		inputFile.current.click();
	}

	function handleSelectImage(e) {
		if (e.target.files[0])
			dispatch(startUploading({ imageFile: e.target.files[0] }));
		e.target.value = null;
	}

	function handleAddUser() {

		const roleId = selectedRole;

		dispatch(addUser({
			fullName: formik.values.fullName,
			email: formik.values.email,
			profilePictureURL: imageFile.secure_url,
			roleId,
		}));
	}

	function handleRoleChange(e) {
		setSelectedRole(e.target.value);
	}

	return (
		<div className={ "dashboard-wrapper" }>
			<ToastContainer/>
			<input type="file" id="file" ref={ inputFile } style={ { display: "none" } }
			       accept={ "image/*" } onChange={ handleSelectImage }/>
			<div>
				<div
					className={ `dashboard-left bg-white p-4 float-start ${ !menuVisible &&
					"minimize" }` }>
					<div className={ "logo" }>
						<img className={ "float-start" } src={ logo }
						     alt={ "rig-log-logo" }/>
						<h4 className={ "float-start" }>Rig Log</h4>
					</div>
					<div className={ "dashboard-menu" }>
						<h3>App Management</h3>
						<div className={ "menu" }>
							{
								!isSuperUserLogged && <ul className={ "ps-0" }>
									<li onClick={ handleRigRackClick }
										className={ `mb-1 ${ activeTab === 1 ?
											"active" :
											"" }` }><span
										className={ "me-2" }><img
										className={ "float-start" }
										src={ store }
										alt={ "store" }/></span><label
										className={ "mb-0" }>Rig
										Rack</label>
									</li>
									<li onClick={ handleSparePartsClick }
										className={ `mb-1 ${ activeTab === 2 ?
											"active" :
											"" }` }><span
										className={ "me-2" }><img
										className={ "float-start" }
										src={ shopping }
										alt={ "shopping" }/></span>
										<label className={ "mb-0" }>Spare
											Parts</label>
									</li>
									<li onClick={ handleBinClick }
										className={ `mb-1 ${ activeTab === 3 ?
											"active" :
											"" }` }><span
										className={ "me-2" }><img
										className={ "float-start" }
										src={ trash }
										alt={ "trash" }/></span>
										<label className={ "mb-0" }>Bin</label>
									</li>
									<li onClick={ handleUsersClick }
										className={ `mb-1 ${ activeTab === 4 ?
											"active" :
											"" }` }><span
										className={ "me-2" }><img
										className={ "float-start" }
										src={ users }
										alt={ "users" }/></span>
										<label className={ "mb-0" }>Users</label>
									</li>
									{isTenantAdmin && 
									<li onClick={ handleSettingsClick }
										className={ `mb-1 ${ activeTab === 5 ?
											"active" :
											"" }` }><span
										className={ "me-2" }><img
										className={ "float-start" }
										src={ gear }
										alt={ "settings" }/></span>
										<label className={ "mb-0" }>Settings</label>
									</li>}
									<li onClick={ handleReportsClick }
										className={ `mb-1 ${ activeTab === 6 ?
											"active" :
											"" }` }><span
										className={ "me-2" }><img
										className={ "float-start" }
										src={ reports }
										alt={ "users" }/></span>
										<label className={ "mb-0" }>Reports</label>
									</li>
								</ul>
							}
							{
								isSuperUserLogged && <ul className={ "ps-0" }>
									<li onClick={ handleCompaniesClick }
										className={ `mb-1 ${ activeTab === 1 ?
											"active" :
											"" }` }><span
										className={ "me-2" }><img
										className={ "float-start" }
										src={ store }
										alt={ "store" }/></span><label
										className={ "mb-0" }>Companies</label>
									</li>
								</ul>
							}
							
						</div>
					</div>
					<div className={ "logout fixed-bottom" }
					     onClick={ handleLogout }>
						<label><span className={ "me-2" }><GiPowerButton
							size={ "18" }
							color={ "#687C97" }/></span>
							<label
								className={ "mb-0 logout-text" }>Logout</label></label>
					</div>
				</div>
				{
					isSuperUserLogged && 
					<div className={ `dashboard-right btn-error-show-hide` }>
						<div className={ "border-bottom rounded-0 pt-5 mb-4" }>
							<div onClick={ handleMenuClick }
								 className={ "nav float-start" }><AiOutlineMenu
								size={ "24" }
								color={ "#BDC9D3" }/>
							</div>
							<div className={ "section-title float-start" }>
								<h2>Companies</h2>
							</div>
							<div className={ "clearfix" }/>
							<nav aria-label="breadcrumb">
								<ol className="breadcrumb mt-2">
									<BreadCrumbs/>
								</ol>
							</nav>
						</div>
						<div className={ "card-body p-0 bg-height" }>
							<div className={ "" }>
								{ children }
							</div>
						</div>
					</div>
				}
				{
					!isSuperUserLogged && <div className={ `dashboard-right ${(pageTitle ===
						"Rig Rack" || pageTitle === "Users") && "btn-error-show-hide" }` }>
						<div className={ "border-bottom rounded-0 pt-5 mb-4" }>
							<div onClick={ handleMenuClick }
								 className={ "nav float-start" }><AiOutlineMenu
								size={ "24" }
								color={ "#BDC9D3" }/>
							</div>
							<div className={ "section-title float-start" }>
								<h2>{ pageTitle }</h2>
							</div>
							<div className={ "clearfix" }/>
							<nav aria-label="breadcrumb">
								<ol className="breadcrumb mt-2">
									<BreadCrumbs/>
								</ol>
							</nav>
						</div>
						{ pageTitle === "Rig Rack" &&
						<div
							className={ "my-4 w-100 d-inline-block" }>
							<div className={ "float-start" }>
								{ warningCount > 0 &&
								<div className={ "error-box" }>
									<span
										className={ "exclamation" }><AiOutlineExclamationCircle
										size={ "22" }
										color={ "white" }/></span>
									<label> { warningCount } { warningCount > 1 ?
										"Items" :
										"Item" } Need Your
										Attention</label>
									<span
										className={ "close" }><RiCloseLine
										size={ "20" } color={ "#EF314C" }/></span>
								</div> }
							</div>
						</div> }
						{ pageTitle === "Users" &&
						<div
							className={ "mt-4 mb-3 w-100 d-inline-block add-user-wrapper" }>
							<div className={ "float-start" }>
	
								<div className={ "error-box d-none" }>
									<span
										className={ "exclamation" }><AiOutlineExclamationCircle
										size={ "22" }
										color={ "white" }/></span>
									<label>04 Items Need Your
										Attention</label>
									<span
										className={ "close" }><RiCloseLine
										size={ "20" } color={ "#EF314C" }/></span>
								</div>
								<div className={ "search-box position-relative" }>
									<div className="float-start search-main">
										<input type="search"
											   value={ searchKey }
											   onChange={ handleUserSearchKeyChange }
											   className="form-control"
											   placeholder="Search..."
											   aria-label="Search..."/>
										<span><FiSearch color={ "#BDC9D3" }
														size={ "15" }/></span>
									</div>
									{/*<div className={ "float-start ms-4" }>
										<select className="form-select"
												aria-label="select">
											<option selected>All</option>
											<option value="1">One</option>
											<option value="2">Two</option>
											<option value="3">Three</option>
										</select>
									</div>*/ }
								</div>
							</div>
							<div className={ "float-end mt-3" }>
								<div>
									<button type="button"
											className="btn btn-danger"
											onClick={ () => {
												dispatch(setImage({ imageFile: null }));
												toggleAddUser();
											} }
											data-bs-toggle="modal"
											data-bs-target="#addUserModal">
										<span className={ "me-3" }><GoPlus/></span>Add
										Users
									</button>
								</div>
							</div>
						</div> }
						<div className={ "clearfix" }/>
						<div
							className={ `${ panelBackgroundHidden ?
								"" :
								"card bg-white" } dashboard-content-wrapper ${pageTitle === 'Users' ? 'height260' : 'height172'} ${pageTitle !== "Settings" ? 'dashbord-scroll': ''}` }>
							{ infoPaneEnabled && <div className={ "card-header" }>
								<label
									className={ "float-start" }>{ statusText }</label>
								{ searchEnabled &&
								<div
									className="float-end search-main position-relative">
									<input type="search" className="form-control"
										   placeholder="Search..."
										   aria-label="Search..."
										   onChange={ handleSearch }/>
									<span><FiSearch color={ "#BDC9D3" }
													size={ "15" }/></span>
								</div> }
							</div> }
							<div className={ "card-body p-0 bg-height" }>
								<div className={ "" }>
									{ children }
								</div>
							</div>
						</div>
					</div>
				}
			</div>

			<Modal isOpen={ addUserModal } toggle={ toggleAddUser }
			       centered={ true }
			       className={ "mw-725 add-user" }>
				<div className="modal-header text-center d-inline-block">
					<h5 className="modal-title text-center">Add New User</h5>
					<button type="button" className="btn-close"
					        data-bs-dismiss="modal"
					        onClick={ toggleAddUser } aria-label="Close">
						<AiOutlineClose color={ "#F44059" } size={ 16 }/>
					</button>
				</div>
				<div className="modal-body text-center">
					<div className="form-floating mb-5">
						<input type="Text" className="form-control ps-0" id="floatingFullName"
						       placeholder="Full Name" onChange={ formik.handleChange("fullName") }/>
						<label className={ "ps-0" } htmlFor="floatingFullName">Full Name <span
							className={ "replace-color" }>*</span></label>
					</div>
					<div className="form-floating mb-5 mt-3">
						<input type="email" className="form-control ps-0" id="floatingEmail"
						       placeholder="Email Address" onChange={ formik.handleChange("email") }/>
						<label className={ "ps-0" } htmlFor="floatingEmail">Email Address <span
							className={ "replace-color" }>*</span></label>
					</div>
					<div className="form-floating mb-5 mt-3">
						<select className="form-select ps-0 py-0" id="floatingSelect"
						        aria-label="Floating label select" onChange={ handleRoleChange }>
							<option selected value={ 2 }>Admin</option>
							<option value={ 3 }>Packer</option>
							<option value={ 4 }>Service</option>
						</select>
						<label className={ "ps-0" } htmlFor="floatingSelect">User Type <span
							className={ "replace-color" }>*</span></label>
					</div>
					<div className="text-start">
						<label className={ "ps-0 form-label mb-3" } htmlFor="formFileMultiple">User Image<span
							className={ "replace-color" }>*</span></label>
						<div className={ "dragdrop" } onClick={ handleImageBrowser }>
							{ !imageUploading && !imageFile && <>
								<span className={ "icon" }>
								<BsFillFolderFill size={ 30 } color={ "#F74A63" }/>
							</span>
								<span className={ "text" }>Select your files here</span>
							</> }
							{ !imageUploading && imageFile &&
							<img className={ "profile-image" } alt={ "profile" }
							     src={ imageFile.secure_url }/> }
							{ imageUploading &&
							<span style={ { marginTop: 40 } } className={ "text" }>Uploading...</span> }
						</div>
					</div>

				</div>
				<div className="modal-footer border-top-0 justify-content-center">
					<button type="button" className="btn btn-outline-danger me-3 py-3 px-5"
					        onClick={ toggleAddUser }
					        data-bs-dismiss="modal">Cancel
					</button>
					<button type="button" disabled={ !formik.isValid || !imageFile }
					        className="btn btn-danger py-2 px-5"
					        onClick={ () => {
						        handleAddUser();
						        toggleAddUser();
					        } }
					        data-bs-dismiss="modal">Save
					</button>
				</div>
			</Modal>
		</div>
	);
};

export default Main;
