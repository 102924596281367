import { tokenKey } from "../config";
import { errors } from "../config/errors";

const getData = () => {
	try {
		const jsonValue = localStorage.getItem(tokenKey);
		return jsonValue != null ? JSON.parse(jsonValue) : null;
	} catch (error) {
		return {
			error: true,
			message: errors.asyncStore_Read,
		};
	}
};

const storeData = (value) => {
	try {
		const jsonValue = JSON.stringify(value);
		localStorage.setItem(tokenKey, jsonValue);
		return true;
	} catch (error) {
		return {
			error: true,
			message: errors.asyncStore_Write,
		};
	}
};

export { getData, storeData };
