import "../styles/rigRackStyles.scss";
import React, { useEffect } from "react";
import RigRackCard from "../components/RigRackCard";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
	loadParachuteTypes, setParachuteType,
	setWarningCount,
} from "../redux/actions/parachuteActions";
import { forEach, map } from "lodash/collection";
import { setStatus } from "../redux/actions/statusActions";
import ListEmptyComponent from "../components/ListEmptyComponent";
import LoadingComponent from "../components/LoadingComponent";

const RigRack = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const { parachuteTypes, active } = useSelector(state => state.parachuteReducer);

	useEffect(() => {
		dispatch(loadParachuteTypes());
		dispatch(setParachuteType({ parachuteType: [] }));
	}, [dispatch]);

	useEffect(() => {
		let counter = 0;
		forEach(parachuteTypes, (item) => {
			if (item.serviceLevel !== "SAFE")
				counter += item.numberOfAffectedParachutes;
		});
		dispatch(setWarningCount({ warningCount: counter }));
		dispatch(setStatus({
			statusText: `Displaying ${ parachuteTypes && Object.keys(
				parachuteTypes).length } ${ parachuteTypes && Object.keys(
				parachuteTypes).length > 1 ? "Items" : "Item" }`,
		}));
	}, [parachuteTypes, dispatch]);

	const handleClick = ({ item, objectName }) => {
		history.push("/paraType", {
			pageTitle: item.displayParachuteType,
			parachuteTypeID: objectName,
			searchEnabled: true,
			activeTab: 1,
			infoPaneEnabled: true,
			breadCrumbSet: [
				{
					title: "Rig Rack",
					to: "/rigRack",
				},
				{
					title: item.displayParachuteType,
				},
			],
		});
	};

	const displayItem = (item, key) => {
		return (
			<RigRackCard key={ key } objectName={ key }
			             title={ item.displayParachuteType }
			             serviceLevel={ item.serviceLevel }
			             numberOfAffectedParachutes={ item.numberOfAffectedParachutes }
			             moveToBinAlert={ item.moveToBinAlert }
			             onClick={ () => handleClick(
				             { item, objectName: key }) }/>
		);
	};

	return (
		<div className="row g-4 w-auto p-5">
			{ ((!active && parachuteTypes) && Object.keys(parachuteTypes).length > 0) &&
			map(parachuteTypes, displayItem) }
			{ ((!active && !parachuteTypes) || Object.keys(parachuteTypes).length <= 0) &&
			<ListEmptyComponent/> }
			{ active && LoadingComponent() }
		</div>
	);
};

export default RigRack;