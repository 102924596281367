import "./styles/paraTypeCardStyles.scss";
import logo from "../assets/svg/parachute-logo.svg";
import { AiFillExclamationCircle } from "react-icons/ai";
import React from "react";

const RigRackCard = ({
	                     title,
	                     serviceLevel = "SAFE",
	                     numberOfAffectedParachutes = 1,
	                     moveToBinAlert,
	                     onClick,
	                     disabled,
	                     imageURL,
                     }) => {

	const titleArr = title.split(" ");

	let serviceLevelClass = serviceLevel.toLowerCase();

	return (
		<div className={ `float-start w-auto me-4 ${ disabled && "disabled" }` }
		     onClick={ () => {
			     onClick();
		     } }>
			<div className="item-card card float-start">
				<div className={ "card-body" }>
					<div
						className={ "item-img text-center" }>
						<img
							className={ "text-center" }
							src={ imageURL ? imageURL : logo }
							alt={ "rig" }/>
						{ moveToBinAlert && <span
							className={ "exclamation" }><AiFillExclamationCircle
							color={ "#FF1637" }
							size={ "35" }/></span> }
					</div>
				</div>
				<div
					className={ `card-footer ${ serviceLevelClass }` }>
					<label>{ `${ titleArr[0] } ${ titleArr[1] }` }</label>
					<label className={ "sigma-count" }>{ titleArr[2] }</label>
					<div
						className={ "clearfix" }></div>
					<span
						className={ "count float-end" }>{ `${ numberOfAffectedParachutes }` }</span>
				</div>
			</div>
		</div>
	);
};

export default RigRackCard;