import React, { useEffect, useState } from "react";
import SparePartsCard from "../components/SparePartsCard";
import { map } from "lodash/collection";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setStatus } from "../redux/actions/statusActions";
import * as sparesActions from "../redux/actions/sparesActions";
import ListEmptyComponent from "../components/ListEmptyComponent";
import imageResolver from "../helpers/imageResolver";
import LoadingComponent from "../components/LoadingComponent";

const SpareParts = () => {
	const history = useHistory();
	const dispatch = useDispatch();

	const { sparePartTypes, active } = useSelector(state => state.sparesReducer);
	const [displaySparesTypes, setDisplaySparesTypes] = useState([]);
	const [isComponentSelected, setComponentSelected] = useState(true)

	useEffect(() => {
		dispatch(setStatus({
			statusText: `Displaying ${ sparePartTypes && Object.keys(
				sparePartTypes).length } ${ sparePartTypes && Object.keys(
				sparePartTypes).length > 1 ? "Items" : "Item" }`,
		}));
		let innerData = [];
		if (sparePartTypes) {
			map(sparePartTypes, (item, key) => {
				innerData.push({ displayType: item, typeID: key });
			});
		}
		setDisplaySparesTypes(innerData);
	}, [sparePartTypes, dispatch]);

	useEffect(() => {
		dispatch(sparesActions.setActive());
		dispatch(sparesActions.loadSparePartTypes());
	}, [dispatch, history]);

	const handleClick = (item) => {
		if(!isComponentSelected){
			history.push("/compositeSparePartDetail", {
				pageTitle: "Spare Parts",
				searchEnabled: false,
				activeTab: 2,
				item,
				panelBackgroundHidden: true,
				breadCrumbSet: [
					{
						title: "Spare Parts",
						to: "back",
					},
					{
						title: item.displayType,
					},
				],
			});
		}else{
			history.push("/sparePartDetail", {
				pageTitle: "Spare Parts",
				searchEnabled: false,
				activeTab: 2,
				item,
				panelBackgroundHidden: true,
				breadCrumbSet: [
					{
						title: "Spare Parts",
						to: "back",
					},
					{
						title: item.displayType,
					},
				],
			});
		}
	};

	const getThumbnail = (item) => {
		const imageUrl = imageResolver({ componentType: item.typeID });
		return imageUrl;
	};

	const handleOnClickComponents = () => {
		setComponentSelected(true)
		dispatch(sparesActions.setActive());
		dispatch(sparesActions.loadSparePartTypes());
	}

	const handleOnClickAssembly = () => {
		setComponentSelected(false)
		dispatch(sparesActions.setActive());
		dispatch(sparesActions.loadCompositeSparePartTypes());
	}

	return (
		<div className={ "spare-parts-page p-5" }>
			<div className={ "row g-4 w-auto" }>
				<div className="d-flex justify-content-center">
					<button onClick={ handleOnClickComponents } type="button" className={`btn ${isComponentSelected ? 'btn-danger' : 'btn-outline-danger'} me-3 py-3 px-5`}>
						Components
					</button>
					<button onClick={ handleOnClickAssembly } type="button" className={`btn ${!isComponentSelected ? 'btn-danger' : 'btn-outline-danger'} me-3 py-3 px-5`}>
						Assembly
					</button>
				</div>
				{ !active && displaySparesTypes.length > 0 && map(displaySparesTypes, (item, key) => (
					<SparePartsCard key={ key } title={ item.displayType }
					                thumbnail={ getThumbnail(item) }
					                handleClick={ () => handleClick(item) }/>)) }
				{ !active && displaySparesTypes.length <= 0 && <ListEmptyComponent/> }
				{ active && <LoadingComponent/> }
			</div>
		</div>
	);
};

export default SpareParts;