import {
	SET_COMPONENT_LIST,
	SET_ACTIVITY,
	SET_SELECTED_COMPONENT,
	SET_SELECTED_PARACHUTE_ID,
	SET_SELECTED_ITEM
} from "../actions/componentActions";

const initialState = {
	activity: false,
	componentsAvailable: [],
	selectedComponentType: null,
	selectedParachuteID: "",
	item: null
};

export default function componentReducer(state = initialState, action) {
	switch (action.type) {
		case SET_COMPONENT_LIST:{
			return {
				...state,
				componentsAvailable: action.payload,
			};
		}
		case SET_ACTIVITY:
			return {
				...state,
				activity: action.payload,
			};
		case SET_SELECTED_COMPONENT:
			return {
				...state,
				selectedComponentType: action.payload,
			};
		case SET_SELECTED_PARACHUTE_ID:
			return {
				...state,
				selectedParachuteID: action.payload,
			};
		case SET_SELECTED_ITEM:
			return {
				...state,
				item: action.payload,
			}
		default:
			return state;
	}
}