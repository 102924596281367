const errors = {
	asyncStore_Read: "Async Storage Read Error",
	asyncStore_Write: "Async Storage Write Error",
	fetch_post: "POST Error",
	cloudinaryError: "POST to cloudinary failed",
	loginCheckFailed: "No user session found",
	logoutFailed: "Error: user session not deleted",
	fetch_get: "GET Error",
	stdAuthFailed: "Invalid email or password",
	fetch_put: "PUT Error",
	authHelperFailed: "Authentication failed at Auth Helper",
	nullTokens: "Tokens are not available, cannot proceed.",
	scanFailed: "Scan Failed",
	invalidQRScanned: "Invalid QR Code",
	connectionError: "Connection Error",
	subscribeComponentError: "Assign Failed",
	cannotLoadComponents: "Loading Failed",
	moveToInspectionError: "Inspection failed",
	moveToBinError: "Move to bin failed",
	moveToServiceableError: "Move to serviceable failed",
	moveToUnserviceableError: "Move to Unserviceable failed",
	enabilityError: "Company Enable Error",
	companySaveFailed: "Saving Company Failed",
	passwordForgotError: "Forgot Password Request Error",
	selectValidParams: "User or date range is not selected",
	reportError: "Report request failed",
};

export { errors };