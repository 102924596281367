import { SET_LOADING, SET_CONFIGS, UPDATE_CONFIGS } from "../actions/settingsActions";

const initialState = {
	isLoading: false,
	configs: []
};

export default function settingsReducer(state = initialState, action) {
	switch (action.type) {
		case SET_LOADING:
			return {
				...state,
				isLoading: action.payload
			}
		case SET_CONFIGS:
			return {
				...state,
				configs: action.payload
			}
		case UPDATE_CONFIGS:
			return updateConfigs(state, action);
		default:
			return state;
	}
}

const updateConfigs = (state, actions) => {

	const configs = state.configs;
	const path = actions.path.split(',');

	configs.forEach((c,i, arr) => {
		if(c.name == path[0]){
			if(path.length >1){
				c.componentTypes.forEach((c1, i1, arr1) => {
					if(c1.name == path[1]){
						if(path.length > 2){
							c1.subComponents.forEach((c2, i2, arr2) =>{
								if(c2.name == path[2]){
									arr2[i2] = {
										...c2,
										...actions.data
									}
								}
							})
						}else{
							arr1[i1] = {
								...c1,
								...actions.data
							}
						}
					}
				})
			}else{
				arr[i] = {
					...c,
					...actions.data
				}
			}
		}
	})
	return {
		...state,
		configs
	};
}