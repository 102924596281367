import { getData, storeData } from "./asyncStorageHelper";
import { errors } from "../config/errors";
import { appSettings } from "../config";

const revalidateAuth = async () => {
	let tokens = getData();
	if (!tokens) return ({
		error: true,
		message: errors.nullTokens,
	});
	if (tokens.error) {
		return ({
			error: true,
			message: tokens.message,
		});
	} else {
		const res = await fetch(
			`${ appSettings.API_URL }/token/refresh`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json"
				},
				body: JSON.stringify({
					refreshToken: tokens.refreshToken,
				}),
			});
		if (res.ok) {
			const accessToken = await res.json();
			tokens = {
				...tokens,
				accessToken: accessToken.accessToken,
			};
			const saveRes = await storeData(tokens);
			if (saveRes.error) return ({
				error: true,
				message: saveRes.message,
			});
			else return (tokens);
		} else {
			const saveRes = await storeData(null);
			if (saveRes.error) return ({
				error: true,
				message: saveRes.message,
			});
			return ({
				error: true,
				message: errors.authHelperFailed,
			});
		}
	}
};

export default revalidateAuth;
