import { appSettings } from "../config";
import revalidateAuth from "./authHelper";
import { getData } from "./asyncStorageHelper";
import { errors } from "../config/errors";

const get = async (
	END_POINT,
) => {
	let tokens = await getData();
	if (!tokens) {
		return {
			error: true,
			message: errors.nullTokens,
		};
	}
	if (tokens.error) {
		return tokens;
	}
	const res = await fetch(`${ appSettings.API_URL }/${ END_POINT }`, {
		method: "GET",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Authorization: `Bearer ${ tokens.accessToken }`
		},
	});
	if (res.ok) {
		return res;
	} else {
		if (res.status === 401) {
			const newTokens = await revalidateAuth();
			if (newTokens.error) {
				return newTokens;
			}

			const retryRes = await fetch(
				`${ appSettings.API_URL }/${ END_POINT }`, {
					method: "GET",
					headers: {
						Accept: "application/json",
						"Content-Type": "application/json",
						Authorization: `Bearer ${ newTokens.accessToken }`
					},
				});
			if (retryRes.ok) {
				return retryRes;
			} else {
				return {
					error: true,
					message: errors.stdAuthFailed,
				};
			}
		} else {
			let serverResponse;
			let serverError;
			try {
				serverResponse = await res.json();
				serverError = serverResponse.message;
			} catch (e) {
				serverError = errors.fetch_get;
			}
			return {
				error: true,
				message: errors.fetch_get,
				serverError,
			};
		}
	}
};

const post = async ({ END_POINT, body, authRequired }) => {
	let tokens;
	if (authRequired) {
		tokens = await getData();
		if (tokens?.error) {
			return tokens;
		}
	}
	const res = await fetch(`${ appSettings.API_URL }/${ END_POINT }`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Authorization: authRequired ?
				`Bearer ${ tokens.accessToken }` :
				null
		},
		body: body ? JSON.stringify(body) : null,
	});
	if (res.ok) {
		return res;
	} else {
		if (res.status === 401) {
			if (!authRequired) {
				return {
					error: true,
					message: errors.stdAuthFailed,
				};
			}
			const newTokens = await revalidateAuth();
			if (newTokens.error) {
				return newTokens;
			}
			const retryRes = await fetch(
				`${ appSettings.API_URL }/${ END_POINT }`, {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: authRequired ?
							`Bearer ${ newTokens.accessToken }` :
							null
					},
					body: body ? JSON.stringify(body) : null,
				});
			if (retryRes.ok) {
				return retryRes;
			} else {
				return {
					error: true,
					message: errors.fetch_post,
				};
			}
		} else {
			let serverResponse;
			let serverError;
			try {
				serverResponse = await res.json();
				serverError = serverResponse.message;
			} catch (e) {
				serverError = errors.fetch_post;
			}
			return {
				error: true,
				message: errors.fetch_post,
				serverError,
			};
		}
	}
};

const cloudinaryPost = async (body) => {
	const res = await fetch(`${ appSettings.cloudinary_URL }`, {
		method: "POST",
		body,
	});
	if (res.ok) {
		return await res.json();
	} else return {
		error: true,
		message: errors.cloudinaryError,
	};
};

const put = async ({ END_POINT, body }) => {
	let tokens = await getData();
	if (tokens.error) {
		return tokens;
	}
	const res = await fetch(`${ appSettings.API_URL }/${ END_POINT }`, {
		method: "PUT",
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${ tokens.accessToken }`,
		},
		body: body ? JSON.stringify(body) : null,
	});
	if (res.ok) {
		return res;
	} else {
		if (res.status === 401) {
			const newTokens = await revalidateAuth();
			if (newTokens.error) {
				return newTokens;
			}

			const retryRes = await fetch(
				`${ appSettings.API_URL }/${ END_POINT }`, {
					method: "PUT",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${ newTokens.accessToken }`,
					},
					body: JSON.stringify(body),
				});
			if (retryRes.ok) {
				return retryRes;
			} else {
				return {
					error: true,
					message: errors.stdAuthFailed,
				};
			}
		} else {
			return {
				error: true,
				message: errors.fetch_put,
			};
		}
	}
};

export { post, get, put, cloudinaryPost };
