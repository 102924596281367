import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import authReducer from "./reducers/authReducer";
import parachuteReducer from "./reducers/parachuteReducer";
import statusReducer from "./reducers/statusReducer";
import popupReducer from "./reducers/popupReducer";
import componentReducer from "./reducers/componentReducer";
import { searchReducer } from "./reducers/searchReducer";
import binReducer from "./reducers/binReducer";
import sparesReducer from "./reducers/sparesReducer";
import userReducer from "./reducers/userReducer";
import imageReducer from "./reducers/imageReducer";
import analyticsReducer from "./reducers/analyticsReducer";
import companyReducer from "./reducers/companyReducer";
import settingsReducer from "./reducers/settingsReducer";
import reportReducer from "./reducers/reportReducer";

const rootReducer = combineReducers({
	authReducer,
	parachuteReducer,
	statusReducer,
	popupReducer,
	componentReducer,
	searchReducer,
	binReducer,
	sparesReducer,
	userReducer,
	imageReducer,
	analyticsReducer,
	companyReducer,
	settingsReducer,
	reportReducer
});

export const Store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));
//export const Store = createStore(rootReducer, applyMiddleware(thunk));