import { SET_USERS, SET_ACTIVE, SET_SEARCH_KEY, SET_SELECTED_USER } from "../actions/userActions";

const initialState = {
	active: false,
	users: [],
	searchKey: "",
	selectedUser: ""
};

export default function userReducer(state = initialState, action) {
	switch (action.type) {
		case SET_ACTIVE:
			return {
				...state,
				active: action.payload,
			};
		case SET_USERS:
			return {
				...state,
				users: action.payload,
			};
		case SET_SEARCH_KEY:
			return {
				...state,
				searchKey: action.payload,
			};
		case SET_SELECTED_USER:
			return {
				...state,
				selectedUser: action.payload,
			};
		default:
			return state;
	}
}