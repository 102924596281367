import React, { useState, useEffect } from "react";
import "./styles.scss"

const DetailCard = ({ details, componentHeirarchy, onSave, shouldDisableSaveButton }) => {

    const yesno = ["Yes Required", "Not Required"]
    const numberOfJumpsOrDate = ["Number of Jumps", "Date"]

    const [inspectionValue, setInspectionValue] = useState('')
    const [eolValue, setEolValue] = useState('')

    useEffect(() => {
        setInspectionValue(getInspectionValue(details));
        setEolValue(getEOLValue(details))
    }, [])

    const getInspectionSelectedId = (d) => {
        if (d.jumpsBetweenServices)
            return 0
        else if (d.daysBetweenServices)
            return 1;
        else
            return -1
    }

    const getInspectionName = (d) => {
        if (d.daysBetweenServices)
            return "Time (Days) between inspections"
        else if (d.jumpsBetweenServices)
            return "Jumps Between Inspections"
        else
            return "Jumps Between Inspections"
    }

    const getEOLSelectedId = (d) => {
        if (d.endOfLifeJumpCount)
            return 0
        else if (d.endOfLifeDays)
            return 1
        else
            return -1
    }

    const getEOLName = (d) => {
        if (d.endOfLifeDays)
            return "End of Life Total Days"
        else if (d.endOfLifeJumpCount)
            return "End of Life Total Jumps"
        else
            return "End of Life Total Jumps"
    }

    const getEOLValue = (d) => {
        return d.endOfLifeDays || d.endOfLifeJumpCount
    }

    const getInspectionValue = (d) => {
        return d.daysBetweenServices || d.jumpsBetweenServices
    }

    const handleInspectionValueEdit = ({ target }) => {
        shouldDisableSaveButton(false)
        setInspectionValue(target.value)
    }

    const handleInspectionValueSave = ({ target }) => {
        shouldDisableSaveButton(target.value === '')
        const v = target.value === '' ? 0 : Number.parseInt(target.value)
        onSave({
            jumpsBetweenServices: getInspectionSelectedId(details) == 0 ? v : null,
            daysBetweenServices: getInspectionSelectedId(details) == 1 ? v : null
        }, componentHeirarchy)
    }

    const handleEOLValueEdit = ({ target }) => {
        setEolValue(target.value)
    }

    const handleEOLValueSave = ({ target }) => {
        shouldDisableSaveButton(target.value === '')
        const v = target.value === '' ? 0 : Number.parseInt(target.value)
        onSave({
            endOfLifeJumpCount: getEOLSelectedId(details) == 0 ? v : null,
            endOfLifeDays: getEOLSelectedId(details) == 1 ? v : null
        }, componentHeirarchy)
    }

    const handleManufacturerChange = ({ target }) => {
        shouldDisableSaveButton(false)
        onSave({
            manufacturerRequired: target.value == 0
        }, componentHeirarchy)
    }

    const handleManufacturerDateChange = ({ target }) => {
        shouldDisableSaveButton(false)
        onSave({
            manufactureDateRequired: target.value == 0
        }, componentHeirarchy)
    }

    const handlePartNumberChange = ({ target }) => {
        shouldDisableSaveButton(false)
        onSave({
            partNumberRequired: target.value == 0
        }, componentHeirarchy)
    }

    const handleSerialNumberChange = ({ target }) => {
        shouldDisableSaveButton(false)
        onSave({
            serialNumberRequired: target.value == 0
        }, componentHeirarchy)
    }

    const handleInspectionIntervalsChange = ({ target }) => {
        shouldDisableSaveButton(false)
        if (target.value == 1) {
            onSave({
                jumpsBetweenServices: null,
                daysBetweenServices: details.jumpsBetweenServices
            }, componentHeirarchy)
        } else {
            onSave({
                jumpsBetweenServices: details.daysBetweenServices,
                daysBetweenServices: null
            }, componentHeirarchy)
        }
    }

    const handleEOLChange = ({ target }) => {
        shouldDisableSaveButton(false)
        if (target.value == 1) {
            onSave({
                endOfLifeJumpCount: null,
                endOfLifeDays: details.endOfLifeJumpCount
            }, componentHeirarchy)
        } else {
            onSave({
                endOfLifeJumpCount: details.endOfLifeDays,
                endOfLifeDays: null
            }, componentHeirarchy)
        }
    }

    const getTextBox = (name, value, onChange, onBlur) => (
        <div className="row mb-3">
            <label for="manufacturer" class="col-sm-6 col-form-label">{name}</label>
            <div class="col-sm-6">
                <input onChange={onChange} onBlur={onBlur} type="email" class="form-control" id={`text-${name}`} value={value} disabled={!details.editable} />
            </div>
        </div>
    )

    const getComboBox = (name, options, selectedId, onChange) => (
        <div className="row mb-3">
            <label for="manufacturer" class="col-sm-6 col-form-label">{name}</label>
            <div class="col-sm-6">
                <select id={`comboBox-${name}`} class="form-select" autoComplete="off" disabled={!details.editable} onChange={onChange}>
                    {
                        options.map((o, i) => selectedId === i ? <option value={i} selected="selected">{o}</option> : <option value={i}>{o}</option>)
                    }
                </select>
            </div>
        </div>
    )

    return (
        <div className="detail-pane-wrapper">
            <form class="container-fluid">
                <div className="row mt-3">
                    <div class="col-md-6">
                        {getComboBox("Manufacturer:", yesno, details.manufacturerRequired ? 0 : 1, handleManufacturerChange)}
                    </div>
                    <div class="col-md-6">
                        {getComboBox("Date Of Manufacture:", yesno, details.manufactureDateRequired ? 0 : 1, handleManufacturerDateChange)}
                    </div>
                </div>
                <div className="row mt-3">
                    <div class="col-md-6">
                        {getComboBox("Part Number:", yesno, details.partNumberRequired ? 0 : 1, handlePartNumberChange)}
                    </div>
                    <div class="col-md-6">
                        {getComboBox("Serial Number:", yesno, details.serialNumberRequired ? 0 : 1, handleSerialNumberChange)}
                    </div>
                </div>
                {
                    getInspectionSelectedId(details) !== -1 &&
                    <div className="row mt-3">
                        <div class="col-md-6">
                            {getComboBox("Inspection intervals:", numberOfJumpsOrDate, getInspectionSelectedId(details), handleInspectionIntervalsChange)}
                        </div>
                        {getInspectionSelectedId(details) !== 1 &&
                            <div class="col-md-6">
                                {getTextBox(getInspectionName(details), inspectionValue, handleInspectionValueEdit, handleInspectionValueSave)}
                            </div>
                        }
                    </div>
                }
                {
                    getEOLSelectedId(details) !== -1 &&
                    <div className="row mt-3">
                        <div class="col-md-6">
                            {getComboBox("End of Life:", numberOfJumpsOrDate, getEOLSelectedId(details), handleEOLChange)}
                        </div>
                        {
                            getEOLSelectedId(details) !== 1 && <div class="col-md-6">
                                {getTextBox(getEOLName(details), eolValue, handleEOLValueEdit, handleEOLValueSave)}
                            </div>
                        }

                    </div>
                }
            </form>

        </div>
    )
}

export default DetailCard;