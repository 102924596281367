const colors = {
	primary: "#F4435C",
	pageHeader: "#1E1E1E",
	subText: "#808080",
	offButton: "#E6E6E6",
	white: "#fff",
	success: "#75A273",
	black: "#000",
	offWhite: "#F8F8F8",
	autoGenerateLabel: "#77838F",
	labelColor: "#6A6F7D",
	imageSelectorBg: "#F9F9F9",
	imageSelectorBorder: "#E4E4E4",
	imagePicker: "#D3D3D3",
	disabledPrimary: "#F79BA8",
	error: "#FF1637",
	warning: "#FFCC17",
	errorOpacity: "#FFEDEE",
	warningOpacity: "#FFFED6",
	searchColor: "#77838F",
	dots: "#D8D8D8",
	ok: "#00B929",
	chevronRight: "#333333",
	summaryIcon: "#F0324D",
	redDot: "#FF1637",
	yellowDot: "#37FF16",
};

export { colors };
