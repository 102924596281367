export const SET_SEARCH_TEXT = "SET_SEARCH_TEXT";

const setSearchText = ({ text }) => (dispatch) => {
	dispatch({
		type: SET_SEARCH_TEXT,
		payload: text,
	});
};

const clearSearch = () => (dispatch) => {
	dispatch(setSearchText({ text: "" }));
};

export { setSearchText, clearSearch };