import "../styles/rigRackStyles.scss";
import { map } from "lodash/collection";
import React, { useEffect, useState } from "react";
import { FiEdit } from "react-icons/fi";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import * as sparesActions from "../redux/actions/sparesActions";
import ListEmptyComponent from "../components/ListEmptyComponent";
import LoadingComponent from "../components/LoadingComponent";
import imageResolver from "../helpers/imageResolver";

const SparePartsUnserviceable = ({ title, typeID, isAssembly }) => {
	const dispatch = useDispatch();
	const [pages, setPages] = useState(null);
	const [visible, setVisible] = useState(true);

	const { totalUnserviceablePages, currentUnserviceablePage, unserviceable, active } = useSelector(
		state => state.sparesReducer);

	useEffect(() => {
		if (isAssembly)
			dispatch(sparesActions.loadCompositeUnserviceableSpares({ componentType: typeID }));
		else
			dispatch(sparesActions.loadUnserviceableSpares({ componentType: typeID }));
	}, [dispatch, typeID]);

	useEffect(() => {
		if (totalUnserviceablePages <= 0) setVisible(false);
		else {
			setVisible(true);
			let innerArr = Array(totalUnserviceablePages).fill("");
			setPages(innerArr);
		}
	}, [totalUnserviceablePages]);

	const handleClick = (item) => {
		if (isAssembly) {
			dispatch(sparesActions.moveToServiceableGroup({ componentType: typeID, assemblyId: item.assemblyId }));
		} else {
			dispatch(
				sparesActions.moveToServiceableNormal({ componentType: typeID, componentID: item.id }));
		}
	};

	const renderItem = (sItem, key) => {
		return isAssembly ? renderCompositeItem(sItem, key) : renderNonCompositeItem(sItem, key)
	};

	const renderNonCompositeItem = (sItem, key) => {
		const resolveSubText = (item) => {
			if (item) {
				if (item.totalNumberOfDays !== null) {
					return `${item.totalNumberOfDays} (days)`;
				} else if (item.totalNumberOfJumps !== null) {
					return `${item.totalNumberOfJumps} (jumps)`;
				} else {
					return "N/A";
				}
			} else {
				return "N/A";
			}
		};

		return (
			<div key={key} className={"row mx-0 spare-parts-list"}>
				<div className={" serial-number"}>
					<span className={"float-start d-inline-flex"}>
						<img src={sItem.requiredNumberOfSubComponents > 0 ?
							sItem.subComponents[0].imageUrl :
							sItem.imageUrl} alt="Spare-Part"
							className="src" /></span>
					<h4>{sItem.requiredNumberOfSubComponents > 0 ? sItem.subComponents[0].customID : sItem.customID}</h4>
				</div>
				<div className={"spare-parts-time"}>
					<h4> {resolveSubText(sItem.requiredNumberOfSubComponents > 0 ?
						sItem.subComponents[0].alertInfo :
						sItem.alertInfo)}</h4>
				</div>
				<div className={"spare-parts-action text-center mx-auto"}>
					<div className={"me-3"}>
						<span><FiEdit color={"#f44059"} size={"14"} /></span>
					</div>
					<button type="button" onClick={() => handleClick(sItem)}
						className="btn btn-outline-danger py-2 fw-light">Serviced
					</button>
				</div>
			</div>
		);
	};

	const renderCompositeItem = (sItem, key) => {

		return (
			<div key={key} className={"row mx-0 spare-parts-list"}>
				<div className={"assemblyId"}>
					<span className={"float-start d-inline-flex"}><img
						src={imageResolver({componentType:"ASSEMBLY"})} alt="sparePart"
						className="src" /></span>
					<h4>{sItem.assemblyId}</h4>
				</div>
				<div className={"assembly-spare-parts-action text-center mx-auto"}>
					<div className={"me-3"}>
						<span><FiEdit color={"#f44059"} size={"14"} /></span>
					</div>
					<button type="button" onClick={() => handleClick(sItem)}
						className="btn btn-outline-danger py-2 fw-light">Serviced
					</button>
				</div>
			</div>
		);
	};

	if (!active && unserviceable.length <= 0) return <ListEmptyComponent />;
	if (active) return <LoadingComponent />;
	else
		return (
			<div className={"spare-parts-unserviceable-page"}>
				<div className={"d-none"}>
					{title}
				</div>
				<div>
					<div className={"spare-parts-table"}>
						<div className={"table-header-top"}>
							<div className={"float-start"}>
								<h2>{`Pending ${title} Spare Parts List`}</h2>
							</div>
							<div className={"float-end ms-4 ps-3"}><h4>Items<span
								className={"ms-1"}>{unserviceable.length}</span>
							</h4></div>
							{visible && <div className={"float-end"}><h4>Page <span
								className={"ms-1"}>{currentUnserviceablePage + 1}</span></h4>
							</div>}
						</div>
						{
							isAssembly ?
								<div className={"table-header row mx-0"}>
									<div className={"assemblyId"}><h4>Spare Part Assembly ID</h4></div>
									<div className={"assembly-spare-parts-action"}>
										<h4>Actions</h4>
									</div>
								</div> :
								<div className={"table-header row mx-0"}>
									<div className={"serial-number"}><h4>Spare Part Custom Id
										Number</h4></div>
									<div className={"spare-parts-time"}><h4>Days / Jumps</h4>
									</div>
									<div className={"spare-parts-action"}><h4>Actions</h4>
									</div>
								</div>
						}
						<div className={"table-body"}>
							{map(unserviceable, renderItem)}
						</div>
						{visible && <div className={"table-pagination"}>
							<div className={"float-start"}>
								{totalUnserviceablePages > 1 && <>
									<span>
										<BsArrowLeft color={"#687C97"} size={16} />
									</span>
									<label>Prev</label>
								</>}
							</div>
							<div className={"float-end"}>
								{totalUnserviceablePages > 1 && <>
									<label>Next</label>
									<span>
										<BsArrowRight color={"#687C97"} size={16} />
									</span>
								</>}
							</div>
							<div className={"clearfix"} />
							<div className={"d-flex justify-content-center"}>
								{
									map(pages, (item, index) => (
										<span key={index}
											className={index === currentUnserviceablePage ?
												"active" :
												""}>{index + 1}</span>
									))
								}
							</div>
						</div>}
					</div>
				</div>
			</div>
		);
};

export default SparePartsUnserviceable;
