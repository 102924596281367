import * as popup from "../redux/actions/popupActions";

const popupHelper = ({ dispatch, error, message }) => {
	dispatch(popup.setPopupText(message));
	if (error) {
		dispatch(popup.popupIsError());
	} else {
		dispatch(popup.popupIsNotError());
	}
	dispatch(popup.showPopup());
};

export default popupHelper;