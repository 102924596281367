import React, { useEffect, useState } from "react";
import { map } from "lodash/collection";
import SparePartsCard from "../components/SparePartsCard";
import { useHistory } from "react-router-dom";
import * as binActions from "../redux/actions/binActions";
import { useDispatch, useSelector } from "react-redux";
import { setStatus } from "../redux/actions/statusActions";
import ListEmptyComponent from "../components/ListEmptyComponent";
import imageResolver from "../helpers/imageResolver";
import LoadingComponent from "../components/LoadingComponent";
import { filter } from "lodash";

const Bin = () => {
	const history = useHistory();
	const dispatch = useDispatch();

	const { binTypes, active } = useSelector(state => state.binReducer);
	const [displayBinTypes, setDisplayBinTypes] = useState([]);

	useEffect(() => {

		let innerData = [];
		if (binTypes) {
			map(binTypes, (item, key) => {
				innerData.push({ 
					displayType: item.displayName, 
					isParachute: item.isParachute, 
					isComposite: item.isComposite, 
					typeID: key
				});
			});
		}
		setDisplayBinTypes(innerData);
	}, [binTypes, dispatch]);

	useEffect(() => {
		dispatch(binActions.loadBinTypes());
	}, [dispatch, history]);

	const handleClick = (item) => {
		history.push("/binDetail", {
			pageTitle: "Bin",
			searchEnabled: false,
			activeTab: 3,
			item,
			panelBackgroundHidden: true,
			breadCrumbSet: [
				{
					title: "Bin",
					to: "/bin",
				},
				{
					title: item.displayType,
				},
			],
		});
	};

	const getThumbnail = (item) => {
		if(item.isComposite){
			return imageResolver({ componentType: "ASSEMBLY" });
		}
		const imageUrl = imageResolver({ componentType: item.typeID });
		return imageUrl;
	};

	const handleBinCount = (isParachute, isComposite) => {
		const componentsList = filter(displayBinTypes, (item, index) => {
			if ((isParachute && !isComposite && item.isParachute) || (!isParachute && isComposite && item.isComposite) || (!isParachute && !isComposite && !item.isParachute && !item.isComposite)) {
				return true
			}
		})
		dispatch(setStatus({
			statusText: `Displaying ${componentsList.length} ${componentsList.length > 1 ? "Items" : "Item"}`,
		}));
	}

	const loadComponent = (isParachute, isComposite) => {
		if (active) {
			dispatch(setStatus({
				statusText: ``,
			}));
			return <LoadingComponent />
		} else if (!active && displayBinTypes.length <= 0) {
			dispatch(setStatus({
				statusText: `Displaying 0 Items`,
			}));
			return <ListEmptyComponent />
		}
		else if (!active && displayBinTypes.length > 0) {
			handleBinCount(true, false)
			return <div className={"row g-4 w-auto"}>
				{map(displayBinTypes, (item, index) => {
					if ((isParachute && item.isParachute) || (isComposite && item.isComposite) || (!isParachute && !isComposite && !item.isParachute && !item.isComposite)) {
						console.log(item)
						return (
							<SparePartsCard key={index} title={item.displayType}
								thumbnail={getThumbnail(item)}
								handleClick={() => handleClick(item)} />
						);
					}
				},
				)}
			</div>
		} else {
			dispatch(setStatus({
				statusText: `Displaying 0 Items`,
			}));
			return <ListEmptyComponent />
		}
	}

	return (
		<div className={"bin-page p-5"}>

			<div className={"rig-nav-tabs"}>
				<nav>
					<div className="nav nav-tabs" id="nav-tab" role="tablist">
						<button onClick={() => handleBinCount(true, false)} className="nav-link active" id="nav-home-tab"
							data-bs-toggle="tab" data-bs-target="#nav-home"
							type="button" role="tab" aria-controls="nav-home"
							aria-selected="true">Equipment
						</button>
						<button onClick={ () => handleBinCount(false, false)} className="nav-link" id="nav-profile-tab"
							data-bs-toggle="tab" data-bs-target="#nav-profile"
							type="button" role="tab" aria-controls="nav-profile"
							aria-selected="false">Components
						</button>
						<button onClick={() => handleBinCount(false, true)} className="nav-link" id="nav-assembly-tab"
							data-bs-toggle="tab" data-bs-target="#nav-assembly"
							type="button" role="tab" aria-controls="nav-assembly"
							aria-selected="false">Assembly
						</button>
					</div>
				</nav>
				<div className="tab-content card" id="nav-tabContent">
					<div className="tab-pane fade show active" id="nav-home"
						role="tabpanel" aria-labelledby="nav-home-tab">
						{
							loadComponent(true, false)
						}
					</div>
					<div className="tab-pane fade" id="nav-profile" role="tabpanel"
						aria-labelledby="nav-profile-tab">
						{
							loadComponent(false, false)
						}
					</div>
					<div className="tab-pane fade" id="nav-assembly" role="tabpanel"
						aria-labelledby="nav-assembly-tab">
						{
							loadComponent(false, true)
						}
					</div>
				</div>
			</div>
		</div>
	);
};

export default Bin;