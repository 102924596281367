import { get, post, put } from "../../helpers/baseAPI";
import popupHelper from "../../helpers/popupHelper";
import { reorderParachutes } from "../../helpers/util";
import { errors } from "../../config/errors";
import { messages } from "../../config/messages";

export const SET_COMPONENT_LIST = "SET_COMPONENT_LIST";
export const SET_ACTIVITY = "SET_ACTIVITY";
export const SET_SELECTED_COMPONENT = "SET_SELECTED_COMPONENT";
export const SET_SELECTED_PARACHUTE_ID = "SET_SELECTED_PARACHUTE_ID";
export const SET_SELECTED_ITEM = "SET_SELECTED_ITEM";

const setActive = () => (dispatch) => {
	dispatch({
		type: SET_ACTIVITY,
		payload: true,
	});
};

const setInactive = () => (dispatch) => {
	dispatch({
		type: SET_ACTIVITY,
		payload: false,
	});
};

const setSelectedComponent = ({ selectedComponent }) => (dispatch) => {
	dispatch({
		type: SET_SELECTED_COMPONENT,
		payload: selectedComponent,
	});
};

const setSelectedParachuteID = ({ selectedParachuteID }) => (dispatch) => {
	dispatch({
		type: SET_SELECTED_PARACHUTE_ID,
		payload: selectedParachuteID,
	});
};

const setComponentList = ({ componentList }) => (dispatch) => {
	dispatch({
		type: SET_COMPONENT_LIST,
		payload: componentList,
	});
};

const setSelectedItem = ({ item }) => (dispatch) => {
	dispatch({
		type: SET_SELECTED_ITEM,
		payload: item,
	});
};

const subscribeComponent = () => async (dispatch, getState) => {
	const {
		selectedComponentType,
		selectedParachuteID,
	} = getState().componentReducer;
	console.log(selectedComponentType)
	dispatch(setActive());
	let endPoint = null;

	if(selectedComponentType.noComponent)
		endPoint = `parachute/${ selectedParachuteID }/component/${ selectedComponentType.id }`;
	else
		endPoint = `parachute/${ selectedParachuteID }/component/composite/${ selectedComponentType.assemblyId }`;

	try {
		const response = await post(
			{ END_POINT: endPoint, authRequired: true });
		if (response.error) throw new Error();
		popupHelper({
			dispatch,
			error: false,
			message: messages.componentAddedToParachute,
		});
		dispatch(loadItemDetails({ parachuteID: selectedParachuteID }));
	} catch (e) {
		popupHelper(
			{ dispatch, error: true, message: errors.subscribeComponentError });
	}
	dispatch(setInactive());
	dispatch(setSelectedComponent({ selectedComponent: null }));
};

const subscribeSubComponent = ({ componentID, targetSerial }) => async (dispatch, getState) => {
	const {
		selectedParachuteID,
	} = getState().componentReducer;
	dispatch(setActive());
	const endPoint = `component/composite/${ targetSerial }/add/${ componentID }`;
	try {
		const response = await put({ END_POINT: endPoint });
		if (response.error) throw new Error();
		popupHelper({
			dispatch,
			error: false,
			message: messages.componentAddedToParachute,
		});
		dispatch(loadItemDetails({ parachuteID: selectedParachuteID }));
	} catch (e) {
		popupHelper(
			{ dispatch, error: true, message: errors.subscribeComponentError });
	}
	dispatch(setInactive());
};

const loadAvailableComponents = ({ componentType }) => async (dispatch) => {
	dispatch(setActive());
	const endPoint = `component/serviceable/${ componentType }`;
	try {
		const response = await get(endPoint);
		if (response.error) throw new Error();
		const componentsAvailable = await response.json();

		const componentList = [];

		componentsAvailable.results?.forEach(d => {
			if(d?.subComponents.length > 0){
				d.subComponents.forEach(e => componentList.push(e))
			}else{
				componentList.push(d)
			}
		})

		dispatch(setComponentList({ componentList }));
	} catch (e) {
		popupHelper(
			{ dispatch, error: true, message: errors.cannotLoadComponents });
	}
	dispatch(setInactive());
};

const loadAvailableCompositeComponents = ({ componentType }) => async (dispatch) => {
	dispatch(setActive());
	const endPoint = `component/composite/serviceable/${ componentType }`;
	try {
		const response = await get(endPoint);
		if (response.error) throw new Error();
		const componentsAvailable = await response.json();

		dispatch(setComponentList({ componentList: componentsAvailable.results || [] }));
	} catch (e) {
		popupHelper(
			{ dispatch, error: true, message: errors.cannotLoadComponents });
	}
	dispatch(setInactive());
};

const loadItemDetails = ({ parachuteID }) => async (dispatch) => {
	dispatch(setActive());
	const endPoint = `parachute/${ parachuteID }`;
	const response = await get(endPoint);
	if (response.error) {
		popupHelper({ dispatch, error: true, message: response.message });
	} else {
		let item = await response.json();
		const reorderedData = reorderParachutes([item]);
		item = reorderedData[0];
		dispatch(setSelectedItem({ item }));
	}
	dispatch(setInactive());
};

const performInspectionNormal = ({ componentID, inspectionInterval }) => async (dispatch, getState) => {
	const { selectedParachuteID } = getState().componentReducer;
	dispatch(setActive());
	try {
		const endPoint = `component/inspect/${componentID}`;
		const response = await put({ END_POINT: endPoint, body: { inspectionInterval } });
		if (!response.error) {
			popupHelper({ dispatch, error: false, message: messages.sentToInspection });
			dispatch(loadItemDetails({ parachuteID: selectedParachuteID }));
		}
	} catch (e) {
		popupHelper({ dispatch, error: true, message: errors.moveToInspectionError });
	}
	dispatch(setInactive());
};

const performParachuteInspectionNormal = ({ parachuteId, inspectionInterval }) => async (dispatch, getState) => {
	dispatch(setActive());
	try {
		const endPoint = `parachute/inspect/${parachuteId}`;
		const response = await put({ END_POINT: endPoint, body: { inspectionInterval } });
		if (!response.error) {
			popupHelper({ dispatch, error: false, message: messages.sentToInspection });
			dispatch(loadItemDetails({ parachuteID: parachuteId }));
		}
	} catch (e) {
		popupHelper({ dispatch, error: true, message: errors.moveToInspectionError });
	}
	dispatch(setInactive());
};

const performInspectionGrouped = ({ serialNumber }) => async (dispatch, getState) => {
	const { selectedParachuteID } = getState().componentReducer;
	dispatch(setActive());
	try {
		const endPoint = `component/inspect/composite/${ serialNumber }`;
		const response = await put({ END_POINT: endPoint });
		if (!response.error) {
			popupHelper({ dispatch, error: false, message: messages.sentToInspection });
			dispatch(loadItemDetails({ parachuteID: selectedParachuteID }));
		}
	} catch (e) {
		popupHelper({ dispatch, error: true, message: errors.moveToInspectionError });
	}
	dispatch(setInactive());
};

const moveToBinNormalWithoutReplacement = ({ componentID, isCompositeComponentSelected }) => async (dispatch, getState) => {
	const { selectedParachuteID } = getState().componentReducer;
	dispatch(setActive());
	try {
		const endPoint = isCompositeComponentSelected ? `component/composite/${ componentID }/bin/in` :`component/${ componentID }/bin/in`;
		const response = await put({ END_POINT: endPoint });
		if (!response.error) {
			popupHelper({ dispatch, error: false, message: messages.movedToBin });
			dispatch(loadItemDetails({ parachuteID: selectedParachuteID }));
		}
	} catch (e) {
		popupHelper({ dispatch, error: true, message: errors.moveToBinError });
	}
	dispatch(setInactive());
};

const moveToBinNormalParachuteWithoutReplacement = ({ parachuteID }) => async (dispatch, getState) => {
	dispatch(setActive());
	try {
		const endPoint = `parachute/${ parachuteID }/bin/in`;
		const response = await put({ END_POINT: endPoint });
		if (!response.error) {
			popupHelper({ dispatch, error: false, message: messages.movedToBin });
			dispatch(loadItemDetails({ parachuteID }));
		}
	} catch (e) {
		popupHelper({ dispatch, error: true, message: errors.moveToBinError });
	}
	dispatch(setInactive());
};

const moveToBinNormalWithReplacement = ({ componentID, replacementID, isCompositeComponentSelected }) => async (
	dispatch, getState) => {
	const { selectedParachuteID } = getState().componentReducer;
	dispatch(setActive());
	try {
		const endPoint = isCompositeComponentSelected ? `component/composite/${ componentID }/bin/in/${ replacementID }` : `component/${ componentID }/bin/in/${ replacementID }`;
		const response = await put({ END_POINT: endPoint });
		if (!response.error) {
			popupHelper({ dispatch, error: false, message: messages.movedToBin });
			dispatch(loadItemDetails({ parachuteID: selectedParachuteID }));
		}
	} catch (e) {
		popupHelper({ dispatch, error: true, message: errors.moveToBinError });
	}
	dispatch(setInactive());
};

const moveToBinGroupedWithoutReplacement = ({ serialNumber }) => async (dispatch, getState) => {
	const { selectedParachuteID } = getState().componentReducer;
	dispatch(setActive());
	try {
		const endPoint = `component/composite/${ serialNumber }/bin/in`;
		const response = await put({ END_POINT: endPoint });
		if (!response.error) {
			popupHelper({ dispatch, error: false, message: messages.movedToBin });
			dispatch(loadItemDetails({ parachuteID: selectedParachuteID }));
		}
	} catch (e) {
		popupHelper({ dispatch, error: true, message: errors.moveToBinError });
	}
	dispatch(setInactive());
};

const moveToBinGroupedWithReplacement = ({ serialNumber, replacementIDSerial }) => async (
	dispatch, getState) => {
	const { selectedParachuteID } = getState().componentReducer;
	dispatch(setActive());
	try {
		const endPoint = `component/composite/${ serialNumber }/bin/in/${ replacementIDSerial }`;
		const response = await put({ END_POINT: endPoint });
		if (!response.error) {
			popupHelper({ dispatch, error: false, message: messages.movedToBin });
			dispatch(loadItemDetails({ parachuteID: selectedParachuteID }));
		}
	} catch (e) {
		popupHelper({ dispatch, error: true, message: errors.moveToBinError });
	}
	dispatch(setInactive());
};

const moveToServiceableNormalWithoutReplacement = ({ componentID, isCompositeComponentSelected }) => async (dispatch, getState) => {
	const { selectedParachuteID } = getState().componentReducer;
	dispatch(setActive());
	try {
		const endPoint = isCompositeComponentSelected ? `component/composite/${ componentID }/serviceable/in` : `component/${ componentID }/serviceable/in`;
		const response = await put({ END_POINT: endPoint });
		if (!response.error) {
			popupHelper({ dispatch, error: false, message: messages.movedToServiceable });
			dispatch(loadItemDetails({ parachuteID: selectedParachuteID }));
		}
	} catch (e) {
		popupHelper({ dispatch, error: true, message: errors.moveToServiceableError });
	}
	dispatch(setInactive());
};

const moveToServiceableNormalWithReplacement = ({ componentID, replacementID, isCompositeComponentSelected }) => async (
	dispatch, getState) => {
	const { selectedParachuteID } = getState().componentReducer;
	dispatch(setActive());
	try {
		const endPoint = isCompositeComponentSelected ? `component/composite/${ componentID }/serviceable/in/${ replacementID }` : `component/${ componentID }/serviceable/in/${ replacementID }`;
		const response = await put({ END_POINT: endPoint });
		if (!response.error) {
			popupHelper({ dispatch, error: false, message: messages.movedToServiceable });
			dispatch(loadItemDetails({ parachuteID: selectedParachuteID }));
		}
	} catch (e) {
		popupHelper({ dispatch, error: true, message: errors.moveToServiceableError });
	}
	dispatch(setInactive());
};

const moveToServiceableGroupedWithoutReplacement = ({ serialNumber }) => async (dispatch, getState) => {
	const { selectedParachuteID } = getState().componentReducer;
	dispatch(setActive());
	try {
		const endPoint = `component/composite/${ serialNumber }/serviceable/in`;
		const response = await put({ END_POINT: endPoint });
		if (!response.error) {
			popupHelper({ dispatch, error: false, message: messages.movedToServiceable });
			dispatch(loadItemDetails({ parachuteID: selectedParachuteID }));
		}
	} catch (e) {
		popupHelper({ dispatch, error: true, message: errors.moveToServiceableError });
	}
	dispatch(setInactive());
};

const moveToServiceableGroupedWithReplacement = ({
	                                                 serialNumber,
	                                                 replacementIDSerial,
                                                 }) => async (dispatch, getState) => {
	const { selectedParachuteID } = getState().componentReducer;
	dispatch(setActive());
	try {
		const endPoint = `component/composite/${ serialNumber }/serviceable/in/${ replacementIDSerial }`;
		const response = await put({ END_POINT: endPoint });
		if (!response.error) {
			popupHelper({ dispatch, error: false, message: messages.movedToServiceable });
			dispatch(loadItemDetails({ parachuteID: selectedParachuteID }));
		}
	} catch (e) {
		popupHelper({ dispatch, error: true, message: errors.moveToServiceableError });
	}
	dispatch(setInactive());
};

const moveToUnserviceableNormalWithoutReplacement = ({ componentID, isCompositeComponentSelected }) => async (dispatch, getState) => {
	const { selectedParachuteID } = getState().componentReducer;
	dispatch(setActive());
	try {
		const endPoint = isCompositeComponentSelected ? `component/composite/${ componentID }/inspection/in` : `component/${ componentID }/inspection/in`;
		const response = await put({ END_POINT: endPoint });
		if (!response.error) {
			popupHelper({ dispatch, error: false, message: messages.movedToUnserviceable });
			dispatch(loadItemDetails({ parachuteID: selectedParachuteID }));
		}
	} catch (e) {
		popupHelper({ dispatch, error: true, message: errors.moveToUnserviceableError });
	}
	dispatch(setInactive());
};

const moveToUnserviceableNormalWithReplacement = ({ componentID, replacementID }) => async (
	dispatch, getState) => {
	const { selectedParachuteID } = getState().componentReducer;
	dispatch(setActive());
	try {
		const endPoint = `component/${ componentID }/inspection/in/${ replacementID }`;
		const response = await put({ END_POINT: endPoint });
		if (!response.error) {
			popupHelper({ dispatch, error: false, message: messages.movedToUnserviceable });
			dispatch(loadItemDetails({ parachuteID: selectedParachuteID }));
		}
	} catch (e) {
		popupHelper({ dispatch, error: true, message: errors.moveToUnserviceableError });
	}
	dispatch(setInactive());
};

const moveToUnserviceableGroupedWithoutReplacement = ({ serialNumber }) => async (
	dispatch, getState) => {
	const { selectedParachuteID } = getState().componentReducer;
	dispatch(setActive());
	try {
		const endPoint = `component/composite/${ serialNumber }/inspection/in`;
		const response = await put({ END_POINT: endPoint });
		if (!response.error) {
			popupHelper({ dispatch, error: false, message: messages.movedToUnserviceable });
			dispatch(loadItemDetails({ parachuteID: selectedParachuteID }));
		}
	} catch (e) {
		popupHelper({ dispatch, error: true, message: errors.moveToUnserviceableError });
	}
	dispatch(setInactive());
};

const moveToUnserviceableGroupedWithReplacement = ({
	                                                   serialNumber,
	                                                   replacementIDSerial,
                                                   }) => async (dispatch, getState) => {
	const { selectedParachuteID } = getState().componentReducer;
	dispatch(setActive());
	try {
		const endPoint = `component/composite/${ serialNumber }/inspection/in/${ replacementIDSerial }`;
		const response = await put({ END_POINT: endPoint });
		if (!response.error) {
			popupHelper({ dispatch, error: false, message: messages.movedToUnserviceable });
			dispatch(loadItemDetails({ parachuteID: selectedParachuteID }));
		}
	} catch (e) {
		popupHelper({ dispatch, error: true, message: errors.moveToUnserviceableError });
	}
	dispatch(setInactive());
};

export {
	setActive,
	setInactive,
	loadAvailableComponents,
	setSelectedComponent,
	setSelectedParachuteID,
	subscribeComponent,
	subscribeSubComponent,
	setSelectedItem,
	performInspectionNormal,
	performInspectionGrouped,
	moveToBinNormalWithoutReplacement,
	moveToBinNormalWithReplacement,
	moveToBinGroupedWithoutReplacement,
	moveToBinGroupedWithReplacement,
	moveToServiceableNormalWithoutReplacement,
	moveToServiceableNormalWithReplacement,
	moveToServiceableGroupedWithoutReplacement,
	moveToServiceableGroupedWithReplacement,
	moveToUnserviceableNormalWithoutReplacement,
	moveToUnserviceableNormalWithReplacement,
	moveToUnserviceableGroupedWithoutReplacement,
	moveToUnserviceableGroupedWithReplacement,
	performParachuteInspectionNormal,
	moveToBinNormalParachuteWithoutReplacement,
	loadAvailableCompositeComponents
};
