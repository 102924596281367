import jwt from 'jsonwebtoken';
import popupHelper from "../../helpers/popupHelper";
import { storeData, getData } from "../../helpers/asyncStorageHelper";
import { post } from "../../helpers/baseAPI";
import { messages } from "../../config/messages";
import { errors } from "../../config/errors";

export const SET_AUTH_SET_USER = "SET_USER";
export const SET_AUTH_CLEAR_USER = "CLEAR_USER";
export const SET_AUTH_AUTHENTICATED = "SET_AUTH_AUTHENTICATED";
export const SET_AUTH_AUTHENTICATING = "SET_AUTH_AUTHENTICATING";
export const SET_AUTH_FAILED = "SET_AUTH_FAILED";
export const SET_AUTH_MESSAGE = "SET_AUTH_MESSAGE";

const authIsAuthenticating = () => (dispatch) => {
	dispatch({
		type: SET_AUTH_AUTHENTICATING,
	});
};

const authIsAuthenticated = () => (dispatch) => {
	dispatch({
		type: SET_AUTH_AUTHENTICATED,
	});
};

const authFailed = () => (dispatch) => {
	dispatch({
		type: SET_AUTH_FAILED,
	});
};

const setAuthMessage = (message) => (dispatch) => {
	dispatch({
		type: SET_AUTH_MESSAGE,
		payload: message,
	});
};

const setUser = (userData) => (dispatch) => {
	dispatch({
		type: SET_AUTH_SET_USER,
		payload: userData,
	});
};

const removeUser = () => (dispatch) => {
	storeData("");
	dispatch({
		type: SET_AUTH_CLEAR_USER,
	});
};

const loadDetails = (token) => async (dispatch) => {

	if(!token){
		dispatch(authFailed());
		return;
	}

	const {accessToken} = token;

	if(!accessToken){
		dispatch(authFailed());
		return;
	}

	const {
		sub,
		scope,
		user_full_name,
	} = jwt.decode(accessToken);

	const userData = {
		isLocked: false, // this one is not in the token deafult false
		fullName: user_full_name,
		email: sub,
		roles: {
			name: scope,
		},
		isSuperUserLogged: scope === 'super_admin_role'
	}

	dispatch(setUser(userData));
	dispatch(authIsAuthenticated());
};

const login = ({ email, password, rememberChecked }) => async (dispatch) => {
	dispatch(authIsAuthenticating());
	const endpoint = "token/generate";
	const body = {
		email,
		password,
	};
	const response = await post(
		{ END_POINT: endpoint, body, authRequired: false });
	if (response.error) {
		dispatch(setAuthMessage(response.message));
		dispatch(authFailed());
	} else {
		let tokens = await response.json();
		if (!rememberChecked) {
			tokens = {
				...tokens,
				refreshToken: null,
			};
		}
		const storeResponse = await storeData(tokens);
		if (storeResponse.error) {
			dispatch(setAuthMessage(response.message));
		} else {
			dispatch(loadDetails(tokens));
		}
	}
};

const forgetPassword = ({ email }) => async (dispatch) => {
	dispatch(authIsAuthenticating());
	const endpoint = "users/password/forgot";
	const body = {
		email,
		isLocked: false,
	};
	const response = await post(
		{ END_POINT: endpoint, body, authRequired: false });
	if (response.error) {
		popupHelper({ dispatch, error: true, message: errors.passwordForgotError });
	} else {
		popupHelper({ dispatch, error: false, message: messages.passwordForgotSuccess });
	}
};

const changeForgetPassword = ({ token, newPassword, history }) => async (dispatch) => {
	dispatch(authIsAuthenticating());
	const endpoint = "users/password/forgot/change";
	const body = {
		verificationToken: token,
		newPassword: newPassword
	  };
	const response = await post(
		{ END_POINT: endpoint, body, authRequired: false });
	if (response.error) {
		popupHelper({ dispatch, error: true, message: errors.passwordForgotError });
	} else {
		history.push('./login');
	}
};

const checkLogin = () => async (dispatch) => {
	const tokens = await getData();
	dispatch(loadDetails(tokens));
};

const logout = () => (dispatch) => {
	dispatch(removeUser());
};

export {
	login,
	logout,
	checkLogin,
	setAuthMessage,
	forgetPassword,
	changeForgetPassword,
};
