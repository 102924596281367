const moment = require("moment");
const { appSettings } = require('../config/index')

const reorderParachutes = (parachutes) => {
  let changedParachutes = [];
  parachutes
  .filter(parachute => !parachute.movedToBin)
  .forEach(parachute => {
    let newParachute = parachute;
    parachute.components.forEach((component, index) => {
      let newComponent = component;
      let newSubComponentStructure = component.subComponents;
      if (component.requiredNumberOfSubComponents > 0) {
        let groupSerialNum = component.serialNumber;
        let _subComponentIndex = null;
        let tempSubComponent = null;

        component.subComponents.forEach((subComp, index) => {
          if (subComp.serialNumber === groupSerialNum) {
            _subComponentIndex = index;
          }
        });

        if (_subComponentIndex !== 0) {
          tempSubComponent = newSubComponentStructure[_subComponentIndex];
          newSubComponentStructure[_subComponentIndex] = newSubComponentStructure[0];
          newSubComponentStructure[0] = tempSubComponent;
        }
      }
      newComponent.subComponents = newSubComponentStructure;
      newParachute.components[index] = newComponent;
    });

    changedParachutes.push(newParachute);
  });

  return changedParachutes;
};

const convertToReadableDate = (value) => moment(new Date(value)).format(appSettings.momentDateOnlyFormat)

module.exports = { reorderParachutes, convertToReadableDate };
